// Override http config
module.exports.api = {
    url: 'https://spec-dev-api-qa.cloud.tyson.com/v1/',
};

module.exports.okta = {
    clientId: '0oa11eoaehcCZdELK0h8',
    url: 'https://tyson.oktapreview.com/',
    issuer: 'https://tyson.oktapreview.com/oauth2/default',
    redirectUri: 'https://spec-dev-v2-qa.cloud.tyson.com/',
};

module.exports.environment = {
    environment: 'qa'
}


module.exports.version = { number: '1.0' }
