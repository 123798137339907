export const SET_ALL_PLANTS_FOR_HEADER_MATERIAL = "SET_ALL_PLANTS_FOR_HEADER_MATERIAL"
export const SET_OFFICIAL_PRODUCT = "SET_OFFICIAL_PRODUCT"
export const SET_DEVIATIONS_PENDING = "SET_DEVIATIONS_PENDING"
export const SET_FAVORITE_PLANTS_DEVIATIONS = "SET_FAVORITE_PLANTS_DEVIATIONS"
export const SET_DEVIATIONS_HISTORY = "SET_DEVIATIONS_HISTORY"
export const SET_ZCWP_DETAILS = "SET_ZCWP_DETAILS"
export const SET_USER_INFO = "SET_USER_INFO"
export const SET_LAST_SAVED_DEVIATIONS = "SET_LAST_SAVED_DEVIATIONS"
export const SET_FAVORITE_PLANTS = "SET_FAVORITE_PLANTS"
export const SET_PLANTS_FOR_USER = "SET_PLANTS_FOR_USER"
export const SET_SELECTED_DISTRIBUTION_LISTS = "SET_SELECTED_DISTRIBUTION_LISTS"
export const SET_UPDATE_DEVIATION = "SET_UPDATE_DEVIATION"
export const SET_PAGINATION_PARAMETERS_PENDING = "SET_PAGINATION_PARAMETERS_PENDING"
export const SET_PAGINATION_PARAMETERS_HISTORY = "SET_PAGINATION_PARAMETERS_HISTORY"
export const SET_ADDITIONAL_COMPONENTS = "SET_ADDITIONAL_COMPONENTS"
export const SET_LOADING_TEXT = "SET_LOADING_TEXT"