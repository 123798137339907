import React from 'react'
import { Row } from "react-bootstrap";
import RoundButton from "../../roundButton/RoundButton";

const ButtonRow = (props) => {
    const {
        onSubmit,
        onUpdate,
        onCancel,
        onClone,
        onReject,
        onApprove,
        devStatus,
        userInfo
        } = props

    let isAdmin = userInfo?.role === "CORP" || userInfo?.role === "ADMIN"
    let plants = userInfo?.plantCodes
    return (
        <>
            <Row
                style={{
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {(devStatus === "EDIT" || devStatus === "ACCEPT") && isAdmin ? <RoundButton
                    bgColor="#ffffff"
                    color="#65686b"
                    text="Cancel"
                    borderColor="#65686b"
                    borderStyle="solid"
                    borderWidth="0 0 1px 0"
                    onClick={onCancel}
                    style={{ margin: "0px 10px" }}
                /> : undefined }

                {devStatus === "EDIT" || devStatus === "ACCEPT" || devStatus === "EXPIRED" ? <RoundButton
                    bgColor="#65686b"
                    text="Clone it!"
                    onClick={onClone}
                /> : undefined}

                {(devStatus === "EDIT"  || devStatus === "PENDING" || devStatus === "REJECTED") && isAdmin || (devStatus === "EDIT"  || devStatus === "PENDING") && !isAdmin  ? <RoundButton
                    bgColor="#65686b"
                    text="Update it!"
                    onClick={onUpdate}
                /> : undefined}

                {devStatus === "EDIT" ? <RoundButton
                    bgColor="#65686b"
                    text="Submit it!"
                    onClick={onSubmit}
                /> : undefined}

                {devStatus === "PENDING" && isAdmin ?
                    <>
                        <RoundButton
                            bgColor="#007AFF"
                            text="Reject it!"
                            onClick={onReject}
                        />
                        <RoundButton
                            bgColor="#14C232"
                            text="Approve it!"
                            onClick={onApprove}
                        />
                    </> : undefined}
            </Row>
        </>
    )
}

export default ButtonRow;
