import React from 'react'
import Divider from '@mui/material/Divider';

const ModalTitle = props => {
    let statusColor = "#002554"
        switch (props.statusClicked) {
            case "Pending":
                statusColor = "#D32F2F"
                break;
            case "Accept":
                statusColor = "rgb(20, 194, 50)"
                break;
            case "Rejected":
            case "Cancel":
                statusColor = "rgb(0, 122, 255)"
                break;
            case "Expired":
                statusColor = "rgb(160, 160, 160)"
                break;
            case "Edit":
                statusColor = "rgb(158, 158, 158)"
                break;
            default:
                break;
        }
    return (
    <>
        <span className="chartModalTitle"><b style={{color: statusColor}}>{props.statusClicked.toUpperCase()}</b> <b style={{color: "#002554"}}>DEV</b></span>
        <span className="chartModalTitle chartDateRangeModal">{props.dateRange}</span>
        <Divider className="chartModalDivider" />
    </>
    )
}

export default ModalTitle