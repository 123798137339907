// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  color: #002554;
  font-weight: bold;
  font-family: 'proxima-nova';
  text-align: center;
  text-transform: uppercase;
  opacity: 1;
  font-size: 24px !important;

}
`, "",{"version":3,"sources":["webpack://./src/components/titleText/TitleText.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,iBAAiB;EACjB,2BAA2B;EAC3B,kBAAkB;EAClB,yBAAyB;EACzB,UAAU;EACV,0BAA0B;;AAE5B","sourcesContent":[".title {\n  color: #002554;\n  font-weight: bold;\n  font-family: 'proxima-nova';\n  text-align: center;\n  text-transform: uppercase;\n  opacity: 1;\n  font-size: 24px !important;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
