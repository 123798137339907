import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import FormControlLabel from '@mui/material/FormControlLabel';

import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import { Container, Row, Col } from 'react-bootstrap';

import { styled } from '@mui/material/styles';


const MyAccordionSummary = styled((props) => (
  <AccordionSummary
    expandIcon={<ArrowLeftIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
}));

const OriginalsAccordion = (props) => {
  const [selectedOriginals, setSelectedOriginals] = useState([]);

  const { canSelectMultiple, steps, setOriginalsSelected, disabled, isAdditionalComponents } = props;

  const handleRadioChange = (title, description, allergens, materialType, plant) => {
    if (isAdditionalComponents) {
      let originalsDuplicate = [...selectedOriginals]
      const tempOriginals = { plant, contents: [{ title, description, allergens, materialType }] };
      let arrIndex = selectedOriginals.findIndex(original => original.plant === plant)
      if (arrIndex === -1) {
        originalsDuplicate.push(tempOriginals)
      } else {
        originalsDuplicate[arrIndex] = tempOriginals
      }
      console.log("originalsDuplicate: ", originalsDuplicate)
      setSelectedOriginals(originalsDuplicate);
      console.log('tempOriginals: ', tempOriginals);
      setOriginalsSelected(originalsDuplicate);
    }
    if (!isAdditionalComponents) {
      const tempOriginals = [{ title, description, allergens, materialType }];
      setSelectedOriginals(tempOriginals);
      console.log('selected originals', tempOriginals);
      setOriginalsSelected(tempOriginals);
    }

  };
  const handleCheckBoxChange = (title, description, allergens, materialType, plant) => {
    if (isAdditionalComponents) {
      let originalsDuplicate = [...selectedOriginals]
      let arrIndex = originalsDuplicate.findIndex(original => original.plant === plant)
      const tempOriginals = { plant, contents: [{ title, description, allergens, materialType }] };
      if (arrIndex === -1) {
        originalsDuplicate.push(tempOriginals)
      } else {
        const index = originalsDuplicate[arrIndex].contents.findIndex(content => content.title === title);
        if (index > -1) {
          originalsDuplicate[arrIndex].contents.splice(index, 1);
        } else {
          originalsDuplicate[arrIndex].contents.push(tempOriginals.contents[0]);
        }
      }
      setSelectedOriginals(originalsDuplicate);
      console.log('originalsDuplicate: ', originalsDuplicate);
      setOriginalsSelected(originalsDuplicate);
    }

    if (!isAdditionalComponents) {
      const tempOriginals = [...selectedOriginals];
      const newOriginal = { title, description, allergens, materialType };
      const index = tempOriginals.map((item) => item.title).indexOf(title);
      if (index > -1) {
        tempOriginals.splice(index, 1);
      } else {
        tempOriginals.push(newOriginal);
      }
      setSelectedOriginals(tempOriginals);
      console.log('selected originals', tempOriginals);
      setOriginalsSelected(tempOriginals);
    }

  };

  const AccordionContents = steps.map((step, index) => {
    if (step !== undefined) {
      return (
        <Accordion sx={{ boxShadow: 'none' }}>
          <AccordionSummary
            sx={{ backgroundColor: '#e0e0e0', borderRadius: '10px', marginBottom: '.1px', border: '#dddddd  1px solid' }}
            expandIcon={<ArrowLeftIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <Typography >{step === undefined ? console.log("undefined") : step.title}</Typography>
          </AccordionSummary>
          {step.contents.map((content, index) => {
            console.log("content: ", content)
            return (
              <AccordionDetails key={index}
                sx={{ border: '#bdbdbd .5px solid', marginBottom: '1px', borderRadius: '5px' }}
              >
                <FormControl component="fieldset">
                  <FormControlLabel
                    value="end"
                    control={
                      canSelectMultiple ? (
                        <Checkbox
                          size="small"
                          color="default"
                          onChange={(e) =>
                            handleCheckBoxChange(
                              content.title === "" ? content.description : content.title,
                              content.description,
                              content.allergens,
                              content.materialType,
                              step.title
                            )
                          }
                          disabled={disabled}
                          value={content.title === "" ? content.description : content.title}
                          name={`checkbox-${content.title === "" ? content.description : content.title}-${index}`}
                          inputProps={{ 'aria-label': content.title === "" ? content.description : content.title }}
                        />
                      ) : (
                        <Radio
                          size="small"
                          color="default"
                          disabled={disabled}
                          checked={isAdditionalComponents ?
                            selectedOriginals.some(selOrig => {
                              let title = selOrig.contents?.length > 0 ? selOrig.contents[0]?.title : ""
                              return selOrig.plant + title === step.title + content.title
                            }) :
                            selectedOriginals.length > 0 && selectedOriginals[0].title === content.title
                          }
                          onChange={(e) =>
                            handleRadioChange(
                              content.title === "" ? content.description : content.title,
                              content.description,
                              content.allergens,
                              content.materialType,
                              step.title
                            )
                          }
                          value={content.title}
                          name={`radio-button-${step.title}-${index}`}
                          inputProps={{ 'aria-label': content.title === "" ? content.description : content.title }}
                        />
                      )
                    }

                    label={content.title === "" ? content.description : content.title}
                  />
                  <p style={{ marginBottom: '0px' }}>{content.description}</p>
                </FormControl>
              </AccordionDetails>
            );


          })}
        </Accordion>
      );
    }
  });

  return (
    <Col
      xs={12}
      sm={12}
      md={12}
      style={{ overflow: 'none', marginBottom: '10px' }}
    >
      {AccordionContents.length > 0 ? AccordionContents : undefined}
    </Col>
  );
};

export default OriginalsAccordion;
