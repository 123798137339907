import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import CheckCircleIcon from '@mui/icons-material//CheckCircle';
import swal from "sweetalert2";
import OutLineButton from '../outLineButton/OutLineButton';
import ChooseDeviationsModal from './chooseDeviationsModal/ChooseDeviationsModal';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import './CreateNewRequestMobile.css';

const PlantsRadioButtons = ({ setCurrentStage, handlePlantSelection, headerMaterialPlants, setDeviationDetails, devType }) => {
  const [plantType, setPlantType] = useState("")

  const onContinue = () => {
    setCurrentStage("COMPONENT_DEV")
    setDeviationDetails('');
  }

  const handlePlantSelect = (e) => {

    handlePlantSelection(false, e.target.value, e.target.checked, false)
    setPlantType(e.target.value)
  }

  const plants = headerMaterialPlants.map(plant => {
    return (
      <FormControlLabel
        key={plant.Plant + ' ' + plant.Descr}
        value={plant.Plant + ' ' + plant.Descr}
        label={plant.Plant + ' ' + plant.Descr}
        control={<Radio color="default" size="small" />}
        color="default"
      />
    );
  })

  return (
    <fieldset>
      <div style={{ textAlign: 'start', marginBottom: '20px' }}>
        <span className="sub-title" style={{ marginLeft: '10px', marginTop: '10px' }}>
          This is for plant(s):
        </span>
        <RadioGroup
          name="plantType"
          value={plantType}
          onChange={handlePlantSelect}
        >
          <Col sm={12} md={12}>
            {plants ? plants : undefined}
          </Col>
        </RadioGroup>
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <OutLineButton text="Continue >"
            backgroundColor="#65686B"
            onClick={() => onContinue()}
          ></OutLineButton>
        </div>
      </div>
    </fieldset>
  )
}

const PlantsCheckBoxes = ({ setCurrentStage, handlePlantSelection, headerMaterialPlants, setDeviationDetails }) => {

  const onContinue = () => {
    setCurrentStage("COMPONENT_DEV");
    setDeviationDetails('');
  }

  const handlePlantSelect = (e) => {
    handlePlantSelection(true, e.target.value, e.target.checked, false)
  }

  const plants = headerMaterialPlants.map(plant => {
    return (
      <FormControlLabel
        key={plant.Plant + ' ' + plant.Descr}
        value={plant.Plant + ' ' + plant.Descr}
        control={<Checkbox color="default" size="small" />}
        label={plant.Plant + ' ' + plant.Descr}
        onChange={(e) => handlePlantSelect(e)}
        style={{ fontFamily: 'proxima-nova', fontSize: '12px' }}
      />
    );
  })


  return (
    <fieldset>
      <div style={{ textAlign: 'start', marginBottom: '20px' }}>
        <span className="sub-title" style={{ marginLeft: '10px', marginTop: '10px' }}>
          This is for plant(s):
        </span>
        <Form.Group style={{ margin: '10px 20px' }}
          controlId="devType"
        >
          <Col sm={12} md={12}>
            {plants ? plants : undefined}
          </Col>
        </Form.Group>
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <OutLineButton text="Continue >"
            backgroundColor="#65686B"
            onClick={() => onContinue()}
          ></OutLineButton>
        </div>
      </div>
    </fieldset>

  )
}

const CreateNewRequestMobile = (props) => {
  const {
    setCurrentStage,
    setDevType,
    setIWantTo,
    devType,
    IWantTo,
    setDescribeType,
    describeType,
    setMaterialNumber,
    setDeviationDetails,
    materialNumber,
    handlePlantSelection,
    headerMaterialVerified,
    setHeaderMaterialVerified,
    headerMaterialPlants,
    onGetAllPlantsForHeaderMaterial,
    onGetOfficialProduct,
    onGetDeviationDetails,
    onValidateZcwpMaterial,
    deviationsHistory,
    deviationsPending,
    onSetOfficialProduct,
    onSetZcwpDetails,
    onSetLastSavedDeviations,
    officialProductDetails,
    userInfo,
    setLoading,
  } = props;

  useEffect(() => {
    onSetOfficialProduct(undefined)
    onSetZcwpDetails(undefined)
    onSetLastSavedDeviations([])
  }, [])

  const handleChange = (e) => {
    console.log('handleChange', e.target.value)
    setDevType(e.target.value)
  }
  const handleIWantTo = (e) => {
    setIWantTo(e.target.value)
  }

  const handleDevDescriptionChange = (e) => {
    console.log('describe type', e.target.value)
    handlePlantSelection(false, "", "", true)
    setDescribeType(e.target.value)
  }

  const handleMaterialChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setHeaderMaterialVerified(false)
      setMaterialNumber(e.target.value)
    }
  }

  const onFilterDeviation = () => {
    if (!deviationsHistory) return;
    let auxArray = deviationsHistory.filter(deviation => deviation.devType == devType);
    return auxArray;
  }


  const validateMaterial = () => {
    setLoading(true)
    onGetAllPlantsForHeaderMaterial(materialNumber).then(res => {
      console.log("res: ", res)
      console.log("typeof res: ", typeof res)
      if (typeof res === "string") {
        setLoading(false)
        swal.fire({
          icon: "warning",
          title: "No data returned from service",
          timer: 2000,
        })
        return
      }
      if (res.data && res.data.isSuccess === false) {
        setLoading(false)
        swal.fire({
          icon: "warning",
          title: "No plants found for material",
          timer: 2000,
        })
        return
      }
      onGetOfficialProduct(materialNumber).then(res => {

        console.log("res: ", res)
        console.log("typeof res: ", typeof res)
        if (typeof res === "string") {
          setLoading(false)
          swal.fire({
            icon: "warning",
            title: "No data returned from service",
            timer: 3000,
          })
          return
        }
        if (typeof res.data.data === "string") {
          console.log("material number not found on GSM: ", res.data.data)
          console.log("looking for material on SAP...")
          onValidateZcwpMaterial(materialNumber).then(res => {
            console.log("res zcwp: ", res)
            if (!res.data.data || res.data.data.length === 0) {
              setLoading(false)
              swal.fire({
                icon: "warning",
                title: `No data found for material number ${materialNumber}`,
              })
              return
            }
            setLoading(false);
            setHeaderMaterialVerified(true)
          })
        } else {
          setLoading(false)
          setHeaderMaterialVerified(true)
        }

      })
    })
  }

  const onDeviationsTableClose = () => {
    setIWantTo('brandNew');
  }

  const onClonedItemSelected = (deviation) => {

    onGetDeviationDetails(deviation.deviationId).then((result) => {
      setLoading(true);
      if (!result?.data?.isSuccess) {
        swal.fire({
          icon: "warning",
          title: "No data returned from service",
          timer: 3000,
        })
        return
      }
      setDeviationDetails(result.data.data)
    });

    onGetOfficialProduct(deviation.materialNumber).then((res) => {
      if (typeof res === 'string') {
        swal.fire({
          icon: 'warning',
          title: 'No data returned from service',
          timer: 3000,
        });
        setLoading(false);
        return;
      }
      console.log("res ", res);
      if (typeof res.data.data !== 'string') {
        setCurrentStage('COMPONENT_DEV');
        setLoading(false);
        return
      }

      console.log('material number not found on GSM: ', res.data.data);
      console.log('looking for material on SAP...');
      onValidateZcwpMaterial(deviation.materialNumber).then((res) => {
        if (!res.data.data || res.data.data.length === 0) {
          swal.fire({
            icon: 'warning',
            title: `No data found for material number ${deviation.materialNumber}`,
          });
          setLoading(false);
          return;
        }
        setCurrentStage('COMPONENT_DEV');
        setLoading(false);
      });
    });
  }

  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          margin: '10px',
          fontSize: '20px',
          fontFamily: 'proxima-nova',
          color: '#002554',
          textTransform: 'uppercase',
          fontWeight: 'bold',
        }}
      >
        <span>Create a new request</span>
      </div>
      <fieldset>
        <div style={{ textAlign: 'start' }}>
          <span
            className="sub-title"
            style={{ marginLeft: '10px', marginTop: '10px' }}
          >
            I am creating a
          </span>
          <RadioGroup
            name="devType"
            value={devType}
            onChange={handleChange}
            style={{
              marginLeft: '10px',
              fontFamily: 'proxima-nova',
              fontSize: '12px',
            }}
          >
            <FormControlLabel
              value="component"
              control={<Radio color="default" size="small" />}
              label="Component"
              color="default"
            />
            <FormControlLabel
              value="process"
              control={<Radio color="default" size="small" />}
              label="Process"
              style={{ margin: '0px', padding: '0px' }}
            />
            <FormControlLabel
              value="relabel"
              control={<Radio color="default" size="small" />}
              label="Relabel"
              style={{ margin: '0px', padding: '0px' }}
            />
          </RadioGroup>
        </div>

        {devType.length > 0 ? (
          <div style={{ textAlign: 'start' }}>
            <span
              className="sub-title"
              style={{ marginLeft: '10px', marginTop: '10px' }}
            >
              I want to
            </span>
            <RadioGroup
              name="IWantTo"
              value={IWantTo}
              onChange={handleIWantTo}
              style={{
                marginLeft: '10px',
                fontFamily: 'proxima-nova',
                fontSize: '12px',
              }}
            >
              <FormControlLabel
                value="brandNew"
                control={<Radio color="default" size="small" />}
                label="Start a brand new request"
                color="default"
              />
              <FormControlLabel
                value="cloneExisting"
                control={<Radio color="default" size="small" />}
                label="Clone from an existing deviation"
                style={{ margin: '0px', padding: '0px' }}
              />
            </RadioGroup>
          </div>
        ) : null}
      </fieldset>
      <div style={{ textAlign: 'start' }}>
        {devType.length > 0 ? (
          devType === 'component' ? (
            <div>
              <Col style={{ padding: '10px' }}>
                <div className="sub-title">
                  I have the header material number, which is:
                </div>
                <Row style={{ marginLeft: '13px' }}>
                  {headerMaterialVerified ? (
                    <CheckCircleIcon
                      style={{ color: 'green', marginRight: '3px' }}
                    />
                  ) : null}

                  <input
                    type="tel"
                    value={materialNumber}
                    maxLength="11"
                    onChange={(e) => {
                      handleMaterialChange(e);
                    }}
                  />
                </Row>
                <div style={{ fontFamily: 'proxima-nova', fontSize: '12px' }}>{`(Same header material as in the process order, 11 digits, numbers only)`}</div>

                {!headerMaterialVerified ? (
                  <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <OutLineButton
                      onClick={() => validateMaterial()}
                      text="Validate"
                      isClickable={materialNumber.length > 0 ? true : false}
                      backgroundColor="#65686B"
                    />
                  </div>
                ) : null}
              </Col>
            </div>
          ) : (
            <div>
              <Col style={{ padding: '10px' }}>
                <div className="sub-title">
                  I have the header material number, which is:
                </div>
                <Row style={{ marginLeft: '13px' }}>
                  {headerMaterialVerified ? (
                    <CheckCircleIcon
                      style={{ color: 'green', marginRight: '3px' }}
                    />
                  ) : null}

                  <input
                    type="tel"
                    value={materialNumber}
                    maxLength="11"
                    onChange={(e) => {
                      handleMaterialChange(e);
                    }}
                  />
                </Row>

                <div>{`(11 digits, numbers only)`}</div>
                {!headerMaterialVerified ? (
                  <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <OutLineButton
                      onClick={() => validateMaterial()}
                      text="Validate"
                      isClickable={materialNumber.length > 0 ? true : false}
                      backgroundColor="#65686B"
                    />
                  </div>
                ) : null}
              </Col>
            </div>
          )
        ) : null}
      </div>
      {headerMaterialVerified ? (
        <div>
          <fieldset>
            <div style={{ textAlign: 'start' }}>
              <span
                className="sub-title"
                style={{ marginLeft: '10px', marginTop: '10px' }}
              >
                Choose one that best describes this dev:
              </span>
              <RadioGroup
                name="describeType"
                value={describeType}
                onChange={handleDevDescriptionChange}
                style={{
                  marginLeft: '10px',
                  fontFamily: 'proxima-nova',
                  fontSize: '12px',
                }}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio color="default" size="small" />}
                  label="One original and one replacement"
                  color="default"
                />
                {devType !== 'relabel' ? <><FormControlLabel
                  value="2"
                  control={<Radio color="default" size="small" />}
                  label="One original and multiple replacements"
                  style={{ margin: '0px', padding: '0px' }}
                />
                  <FormControlLabel
                    value="3"
                    control={<Radio color="default" size="small" />}
                    label="Multiple originals and one replacement"
                    style={{ margin: '0px', padding: '0px' }}
                  /> </> : undefined}
              </RadioGroup>
            </div>
          </fieldset>
        </div>
      ) : null}
      {describeType.length > 0 && headerMaterialVerified ? (
        describeType === '1' && devType !== 'relabel' ? (
          <PlantsCheckBoxes
            setCurrentStage={setCurrentStage}
            handlePlantSelection={handlePlantSelection}
            headerMaterialPlants={headerMaterialPlants}
            setDeviationDetails={setDeviationDetails}
          />
        ) : describeType !== '1' || (describeType === '1' && devType === 'relabel') ? (
          <PlantsRadioButtons
            setCurrentStage={setCurrentStage}
            handlePlantSelection={handlePlantSelection}
            headerMaterialPlants={headerMaterialPlants}
            setDeviationDetails={setDeviationDetails}
            devType={devType}
          />
        ) : undefined
      ) : undefined}
      {IWantTo === 'cloneExisting' ? (
        <ChooseDeviationsModal
          deviations={onFilterDeviation()}
          onCloseModal={onDeviationsTableClose}
          onDeviationSelected={onClonedItemSelected}
        />
      ) : null}
    </div>
  );
};

export default CreateNewRequestMobile;
