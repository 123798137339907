import React from "react";
import { Col, Row, Modal } from 'react-bootstrap';
import RoundButton from "../../roundButton/RoundButton";

const SubmitDialog = (props) => {
    /**
     * Commented few fields which are not yet used.
     */

    const { show, setShowSubmitDialog, refreshData, deviationId, onCloseModal } = props;
    /**
     * You successfully approved the Component Dev (ID: 462) request on 03/24/2021.
     * The requester will be notified. Thank you!
     */

     const closeModal = () => {
        setShowSubmitDialog(false)
        onCloseModal()
        refreshData()
    }

    return (
        <Modal show={show} centered onHide={closeModal}>
            <Col
                style={{
                    alignItems: "center",
                    justifyContent: "center",
                    color: "65686B",
                    // padding: "0px 20px",
                    margin: "20px 0px",
                }}
            >
                <div style={{ textAlign: "center" }}>
                    <span>
                        {`You successfully`}
                        <span
                            style={{ color: "#14C232", fontWeight: "bold" }}
                        >{` submitted `}</span>
                        {`the`}
                    </span>
                    <span
                        style={{ color: "#65686B", fontWeight: "bold" }}
                    >{` deviation (ID: ${deviationId})`}</span>
                </div>

                <Row style={{ alignItems: "center", justifyContent: "center" }}>
                    <RoundButton
                        bgColor="#65686B"
                        text="Got it!"
                        onClick={closeModal}
                    />
                </Row>
            </Col>
        </Modal>
    );
};

export default SubmitDialog;
