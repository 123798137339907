import * as actionTypes from "../actions/actionTypes"

// Hardcoding user data we'll be grabbing from Okta
// Plant codes will be extracted from the AD role name as well as the code
const initialState = {
    userInfo: {}
}

export const UserInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_USER_INFO:
            return {
                ...state,
                userInfo: action.userInfo
            }
        default:
            return state;
    }
}
