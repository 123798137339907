import { getDeviations, 
    getAllPlantsForHeaderMaterial, 
    getOfficialProduct, 
    getDeviationDetails, 
    validateZcwpMaterial, 
    validateDeviationMaterial, 
    getDistributionLists, 
    setUserInfo, 
    saveDeviation, 
    statusChange, 
    setOfficialProduct, 
    setZcwpDetails, 
    setLastSavedDeviations, 
    markUnmarkFavoritePlants, 
    getFavoritePlants,
    onSetDeviationsPending,
    onSetDeviationsHistory, 
    updateDeviation,
    setPaginationParametersPending, 
    setPaginationParametersHistory,
    getFavoritePlantsDeviations,
    getPlantsForUser,
    getAdditionalComponents,
    setAdditionalComponents,
} from '../../store/actions'

const mapDispatchToProps = dispatch => {
    return {
        onGetDeviations: (role, plantCodes, user, businessUnit, dateLimiter, rowLimit, addDeviations) => dispatch(getDeviations(role, plantCodes, user, businessUnit, dateLimiter, rowLimit, addDeviations)),
        onGetFavoritePlantsDeviations: (user, dateLimiter, rowLimit, addDeviations) => dispatch(getFavoritePlantsDeviations(user, dateLimiter, rowLimit, addDeviations)),
        onSetPaginationParametersPending: paginationParameters => dispatch(setPaginationParametersPending(paginationParameters)),
        onSetPaginationParametersHistory: paginationParameters => dispatch(setPaginationParametersHistory(paginationParameters)),
        onGetAllPlantsForHeaderMaterial: (headerMaterial) => dispatch(getAllPlantsForHeaderMaterial(headerMaterial)),
        onGetOfficialProduct: (materialNumber) => dispatch(getOfficialProduct(materialNumber)),
        onGetDeviationDetails: (deviationId) => dispatch(getDeviationDetails(deviationId)),
        onValidateZcwpMaterial: (materialNumber) => dispatch(validateZcwpMaterial(materialNumber)),
        onValidateDeviationMaterial: (plants, replacementMaterialNumbers, originalMaterialNumbers, isZcwp) => dispatch(validateDeviationMaterial(plants, replacementMaterialNumbers, originalMaterialNumbers, isZcwp)),
        onGetDistributionLists: (plantCodes) => dispatch(getDistributionLists(plantCodes)),
        onSetUserInfo: (userInfo) => dispatch(setUserInfo(userInfo)),
        onSaveDeviation: (deviationData, showAdditionalComponents) => dispatch(saveDeviation(deviationData, showAdditionalComponents)),
        onStatusChange: (deviations) => dispatch(statusChange(deviations)),
        onSetOfficialProduct: (officialProduct) => dispatch(setOfficialProduct(officialProduct)),
        onSetZcwpDetails: (zcwpDetails) => dispatch(setZcwpDetails(zcwpDetails)),
        onSetLastSavedDeviations: (lastSavedDeviations) => dispatch(setLastSavedDeviations(lastSavedDeviations)),
        onGetFavoritePlants: () => dispatch(getFavoritePlants()),
        onGetPlantsForUser: () => dispatch(getPlantsForUser()),
        onMarkUnmarkFavoritePlants: (plantCode) => dispatch(markUnmarkFavoritePlants(plantCode)),
        onSetDeviationsPending_: (deviations) => dispatch(onSetDeviationsPending(deviations)),
        onSetDeviationsHistory_: (deviations) => dispatch(onSetDeviationsHistory(deviations)),
        onUpdateDeviation: (deviationData) => dispatch(updateDeviation(deviationData)),
        onGetAdditionalComponents: (materialNumber, plantCodes) => dispatch(getAdditionalComponents(materialNumber, plantCodes)),
        onSetAdditionalComponents: (additionalComponents) => dispatch(setAdditionalComponents(additionalComponents)) 
    }
}

export default mapDispatchToProps
