import React, { useState, useEffect } from 'react'
import { Container, Col, Row, FormGroup } from 'react-bootstrap';
import Button from '@mui/material/Button';
import swal from 'sweetalert2'
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import TextInputBox from '../../deviationModal/textInputBox/TextInputBox';
import BoldText from '../../boldText/BoldText';

const DevRequestContactInfo = props => {

    const {
        onGetDistributionLists,
        userInfo,
        deviationDetails,
        plantCodes,
        handleBottomSheetClose,
        lastSavedDeviations,
        onStatusChange,
        setLoading,
        ingredientStatement,
    } = props

    const [individualEmailsList, setIndividualEmailsList] = useState('')
    const [ccIndividualEmailsList, setccIndividualEmailsList] = useState('')
    const [approversSelected, setApproversSelected] = useState([])
    const [ccSelected, setCCSelected] = useState([])
    const [contactInfoVerified, setContactInfoVerified] = useState(false)
    const [distributionLists, setDistributionLists] = useState([])

    const handleDistributionListSelected = (e, type, index) => {
        // type 1 = approvers
        // type 2 = cc
        let tempDLSelected = type === 1 ? [...approversSelected] : [...ccSelected]
        tempDLSelected[index].selected = e.target.checked
        type === 1 ? setApproversSelected(tempDLSelected) : setCCSelected(tempDLSelected)
    }

    const validateEnteredEmailsList = (enteredEmails) => {
        var emails = enteredEmails.split(',');
        var valid = true;
        var regex =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        for (var i = 0; i < emails.length; i++) {
            if (!regex.test(emails[i].replace(/\s/g, ''))) {
                valid = false;
            }
        }
        return valid;
    };

    const isIndividualEmailsListValid = (individualsInfo) => {
        if (individualsInfo.trim().length === 0) {
            return true;
        }
        return validateEnteredEmailsList(individualsInfo)
    }

    const atLeastOneEmailSelected = (emailsList) => {
        console.log("emailsList: ", emailsList)
        return emailsList.find((email) => email.selected === true);
    }

    const cancelReview = () => {
        swal.fire({
            icon: "question",
            title: "Are you sure you want to cancel the review?",
            showCancelButton: true,
            confirmButtonText: "Continue reviewing",
        }).then(result => {
            if (!result.isConfirmed) {
                handleBottomSheetClose()
            }
        })
    }

    const submitHandler = () => {
        if (!contactInfoVerified) return
        let deviations = []
        let cc = ccSelected.filter(cc => cc.selected).map(cc => cc.name).join(",")
        let emailTo = approversSelected.filter(approver => approver.selected).map(app => app.name).join(",")
        lastSavedDeviations.forEach(deviation => deviations.push({ deviationId: deviation, statusChange: "PENDING", editUser: userInfo?.name, editReason: "Deviation Submitted", emailTo, cc, customEmailTo: individualEmailsList, customCC: ccIndividualEmailsList, ingredientStatement }))
        setLoading(true)
        onStatusChange(deviations, true).then(result => {
            setLoading(false)
            if (typeof result === "string") {
                swal.fire({
                    icon: "error",
                    title: "Error returned from service",
                    text: result
                })
                return
            }
            if (!result?.data?.isSuccess) {
                setLoading(false)
                swal.fire({
                    icon: "error",
                    title: "Could not submit deviation(s)",
                    text: result.data.data.error,
                })
                return
            }
            setLoading(false)
            let submittedDeviations = []
            let notSubmittedDeviations = []
            result.data.data.forEach(deviation => deviation.isSuccess ? submittedDeviations.push(deviation.data) : notSubmittedDeviations.push(deviation.data))
            let icon = notSubmittedDeviations.length > 0 && submittedDeviations.length > 0 ? "warning" : submittedDeviations.length === 0 ? "error" : "success"
            let message = ``
            submittedDeviations.forEach((deviation, index) => {
                if (index === 0) message += `The following deviation Id's were submitted: `
                message += index === submittedDeviations.length - 1 ? deviation : `${deviation}, `
                if (index === submittedDeviations.length) message += `</br>`
            })
            notSubmittedDeviations.forEach((deviation, index) => {
                if (index === 0) message += `The following deviation Id's were not submitted: `
                message += index === notSubmittedDeviations.length - 1 ? deviation : `${deviation}, `
            })
            swal.fire({
                icon,
                title: "Deviation(s) successfully submitted!",
                html: message
            })
            handleBottomSheetClose()
        })
    }

    useEffect(() => {
        let plantCodes_ = deviationDetails ? [deviationDetails.plantCode] : plantCodes
        if (!plantCodes_ || plantCodes_.length === 0) return
        onGetDistributionLists(plantCodes_).then(result => {
            if (!result?.data?.isSuccess) {
                swal.fire({
                    icon: "warning",
                    title: "No distribution lists found for plants!",
                    timer: 3000,
                })
                return
            }
            if (!result.data.data.length === 0) {
                swal.fire({
                    icon: "warning",
                    title: "No distribution lists found for plants!",
                    timer: 3000,
                })
                return
            }
            let distributionLists = result.data.data
            setDistributionLists(distributionLists)
            if (approversSelected.length === 0) {
                distributionLists.forEach(dl => {
                    approversSelected.push({ ...dl, selected: true })
                })
            }
            if (ccSelected.length === 0) {
                distributionLists.forEach(dl => {
                    ccSelected.push({ ...dl, selected: false })
                })
            }
        })
    }, [])

    useEffect(() => {
        if (!distributionLists) return
        const emailSelected = atLeastOneEmailSelected(approversSelected);
        const isIndividualsValid = isIndividualEmailsListValid(individualEmailsList)
        //Since cc is not mandatory we just validate it if it is filled
        const isCCIndividualsValid = ccIndividualEmailsList.length > 0 ? isIndividualEmailsListValid(ccIndividualEmailsList) : true

        console.log("emailSelected: ", emailSelected)
        console.log("isIndividualsValid: ", isIndividualsValid)
        console.log("isCCIndividualsValid: ", isCCIndividualsValid)

        let emailsVerified = false
        if (emailSelected !== undefined) {
            emailsVerified = emailSelected && isIndividualsValid && isCCIndividualsValid
            console.log("emailsVerified: ", emailsVerified)
        } else {
            emailsVerified = individualEmailsList.length > 0 && isIndividualsValid && isCCIndividualsValid
            console.log("emailsVerified: ", emailsVerified)
        }

        if (emailsVerified) {
            setContactInfoVerified(true)
        } else {
            setContactInfoVerified(false)
        }

    }, [distributionLists, individualEmailsList, ccIndividualEmailsList, approversSelected, ccIndividualEmailsList])


    return (
        <>
            <Container fluid>
                <Col>
                    <Row>
                        <Col xs={5} style={{ textAlign: 'start', paddingRight: '0' }}>
                            <BoldText text="2. Contact Info" />
                        </Col>
                        <Col xs={7} style={{ textAlign: 'start', paddingLeft: '0px' }}>

                            <FormLabel component="span" style={{ fontSize: "14px", color: "#a0a0a0" }}>
                                (
                                <span style={{ color: 'red' }}>*</span>
                                are required fields)
                            </FormLabel>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "10px" }}>
                        <TextInputBox readonly title="Requested by:" value={userInfo?.name} />
                    </Row>

                    <>
                        <Row style={{ marginTop: "10px" }}>
                            <Col xs={6} style={{ textAlign: "right" }}>
                                <span style={{ color: 'red' }}>*</span>
                                <FormLabel component="span" style={{ fontSize: "16px", color: "#65686b", paddingRight: "5px" }}>
                                    Send to Approver(s):
                                </FormLabel>
                            </Col>
                            <Col className="pl-0 pb-1 text-left" xs={6}>
                                <FormLabel component="span" style={{ fontSize: "16px", color: "#65686b" }}>
                                    Choose mailing list(s)
                                </FormLabel>
                            </Col>
                        </Row>
                        <Col xs={{ span: 7, offset: 5 }} style={{ marginRight: "0px" }}>
                            <Row>
                                <FormGroup>
                                    {approversSelected?.map((approver, index) => (
                                        <FormControlLabel
                                            style={{ fontSize: "12px !important" }}
                                            component="legend"
                                            control={<Checkbox color="default" />}
                                            label={approver.name.length > 19 ? approver.name.substring(0, 18) + "..." : approver.name}
                                            checked={approver.selected}
                                            onChange={(e) => handleDistributionListSelected(e, 1, index)}
                                        />
                                    ))}
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormLabel component="span" style={{ fontSize: "14px", color: "#a0a0a0" }}>
                                    Or, enter individual email(s)
                                </FormLabel>
                            </Row>
                            <Row>
                                <TextField
                                    multiline
                                    value={individualEmailsList}
                                    onChange={(e) => setIndividualEmailsList(e.target.value)}
                                    rows={2}
                                    inputProps={{
                                        maxLength: 1000,
                                    }}
                                    variant="outlined"
                                    className="inputStyle"
                                />
                            </Row>
                            <Row>
                                <span className="fontSizeMobile" style={{ fontSize: "14px", color: "#a0a0a0", textAlign: "justify" }}>
                                    (Separate multiple email addresses using a comma)
                                </span>
                            </Row>
                        </Col>
                    </>
                    <Row style={{ marginTop: "10px" }}>
                        <Col className="pl-0 pb-1" xs={6} style={{ textAlign: "right", paddingRight: "5px" }} >
                            <FormLabel component="span" style={{ fontSize: "16px", color: "#65686b" }}>
                                Result CCs:
                            </FormLabel>
                        </Col>
                        <Col className="pl-0 pb-1 text-left" xs={6}>
                            <FormLabel component="span" style={{ fontSize: "16px", color: "#65686b" }}>
                                Choose mailing list(s)
                            </FormLabel>
                        </Col>
                    </Row>
                    <Col xs={{ span: 7, offset: 5 }} style={{ marginRight: "0px" }}>
                        <Row>
                            <FormGroup>
                                {ccSelected?.map((cc, index) => (
                                    <FormControlLabel
                                        component="legend"
                                        control={<Checkbox color="default" />}
                                        label={cc.name.length > 19 ? cc.name.substring(0, 18) + "..." : cc.name}
                                        checked={cc.selected}
                                        onChange={(e) => handleDistributionListSelected(e, 2, index)}
                                    />
                                ))}
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormLabel component="span" style={{ fontSize: "14px", color: "#a0a0a0" }}>
                                Or, enter individual email(s)
                            </FormLabel>
                        </Row>
                        <Row>
                            <TextField
                                multiline
                                value={ccIndividualEmailsList}
                                onChange={(e) => setccIndividualEmailsList(e.target.value)}
                                rows={2}
                                inputProps={{
                                    maxLength: 1000,
                                }}
                                variant="outlined"
                                className="inputStyle"
                            />
                        </Row>
                        <Row>
                            <span className="fontSizeMobile" style={{ fontSize: "14px", color: "#a0a0a0", textAlign: "justify" }}>
                                (Separate multiple email addresses using a comma)
                            </span>
                        </Row>
                    </Col>
                    <Row className="mt-5">
                        <Col xs={6} style={{ textAlign: "center" }}>
                            <Button
                                size="small"
                                variant="outlined"
                                className={'lightGrayColor'}
                                onClick={cancelReview}
                            >
                                Cancel
                            </Button>
                        </Col>

                        <Col xs={6} style={{ textAlign: "center" }}>
                            <Button
                                size="small"
                                variant="outlined"
                                disabled={!contactInfoVerified}
                                className={contactInfoVerified ? 'darkGrayColor' : 'lightGrayColor'}
                                onClick={submitHandler}
                            >
                                Submit it!
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Container>
        </>
    )
}

export default DevRequestContactInfo
