// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leftMenu_mainText__hO7nh {
    text-align: right;
    font-weight: bold !important;
    font-size: 16px !important;
    color: #65686B;
}

.leftMenu_noTopBotPadding__CVfbd {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}

.leftMenu_favoritePlants__MeXm7 {
    text-align: right;
    font-size: 16px;
    color: #65686B;
    text-decoration: underline;
    cursor: pointer;
}

.leftMenu_otherText__9DeOW {
    text-align: right;
    font-size: 16px;
    color: #65686B;
}

.leftMenu_plantsTitle__tsKot {
    text-align: right;
    font-weight: bold !important;
    font-size: 16px;
    color: #002554;
}

.leftMenu_favoritePlantCount__BmWgn {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    text-align: right;
    font-size: 12px !important;
    color: #a0a0a0;
}

.leftMenu_divToBottom__aO0y\\+ {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.leftMenu_star__mt4Xb { 
    color: #FFB400;
    margin-right: 2px;
    margin-bottom: 2px;
    cursor: pointer;
}   `, "",{"version":3,"sources":["webpack://./src/components/leftMenu/leftMenu.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,4BAA4B;IAC5B,0BAA0B;IAC1B,cAAc;AAClB;;AAEA;IACI,8BAA8B;IAC9B,2BAA2B;AAC/B;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,cAAc;IACd,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,4BAA4B;IAC5B,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,8BAA8B;IAC9B,2BAA2B;IAC3B,iBAAiB;IACjB,0BAA0B;IAC1B,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;AACf;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".mainText {\n    text-align: right;\n    font-weight: bold !important;\n    font-size: 16px !important;\n    color: #65686B;\n}\n\n.noTopBotPadding {\n    padding-bottom: 0px !important;\n    padding-top: 0px !important;\n}\n\n.favoritePlants {\n    text-align: right;\n    font-size: 16px;\n    color: #65686B;\n    text-decoration: underline;\n    cursor: pointer;\n}\n\n.otherText {\n    text-align: right;\n    font-size: 16px;\n    color: #65686B;\n}\n\n.plantsTitle {\n    text-align: right;\n    font-weight: bold !important;\n    font-size: 16px;\n    color: #002554;\n}\n\n.favoritePlantCount {\n    padding-bottom: 0px !important;\n    padding-top: 0px !important;\n    text-align: right;\n    font-size: 12px !important;\n    color: #a0a0a0;\n}\n\n.divToBottom {\n    position: absolute;\n    bottom: 0;\n    width: 100%;\n}\n\n.star { \n    color: #FFB400;\n    margin-right: 2px;\n    margin-bottom: 2px;\n    cursor: pointer;\n}   "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainText": `leftMenu_mainText__hO7nh`,
	"noTopBotPadding": `leftMenu_noTopBotPadding__CVfbd`,
	"favoritePlants": `leftMenu_favoritePlants__MeXm7`,
	"otherText": `leftMenu_otherText__9DeOW`,
	"plantsTitle": `leftMenu_plantsTitle__tsKot`,
	"favoritePlantCount": `leftMenu_favoritePlantCount__BmWgn`,
	"divToBottom": `leftMenu_divToBottom__aO0y+`,
	"star": `leftMenu_star__mt4Xb`
};
export default ___CSS_LOADER_EXPORT___;
