import React, { useState, useEffect } from 'react';
import { Col, Container, Row, FormGroup } from 'react-bootstrap';
import { TextareaAutosize } from '@mui/base';
import { FormControlLabel } from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import swal from 'sweetalert2'
import TextInputBox from '../../deviationModal/textInputBox/TextInputBox';
import DisabledText from '../../disabledText/DisabledText';
import EditableText from '../../editableText/EditableText';
import OutLineButton from '../../outLineButton/OutLineButton';
import ChooseOriginals from '../../../screens/chooseOriginals/ChooseOriginals';
import MultipleReplacement from '../multipleReplacement/MultipleReplacement';
import ValidateReplacementModal from '../../validateReplacementModal/validateReplacementModal'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import './DevDetailsRequest.css';


const DevDetailsRequest = (props) => {
  const {
    describeType,
    plants,
    deviationDetails,
    plantCodes,
    materialNumber,
    setAreVerified,
    setCanShowSaveButtons,
    onValidateDeviationMaterial,
    userInfo,
    officialProductDetails,
    zwcpDetails,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    reasonOrDescription,
    setReasonOrDescription,
    devDetails,
    setDevDetails,
    specChgReq,
    setSpecChgReq,
    originals,
    setOriginals,
    multipleReplacements,
    setMultipleReplacements,
    singleReplacementText,
    setSingleReplacementText,
    npdNumber,
    setNpdNumber,
    deviationType,
    showAdditionalComponents,
    setShowAdditionalComponents,
    additionalComponents,
    onGetAdditionalComponents,
    onSetAdditionalComponents,
    onSaveAndReview,
    setLoading,
    setIngredientStatement
  } = props;
  /**
   * Header Material: Plant(s): *Original(s): *Replacement(s):
   */
  /**
   * Plants can be multiple, which will be received from the previous selection (through props)
   */

  const [isOriginalsChosen, setOriginalsChosen] = useState(false);
  const [replacementsValidated, setReplacementsValidated] = useState(false);
  const [replacementsValid, setReplacementsValid] = useState(false);
  const [showOriginals, setShowOriginals] = useState(false);
  const [formulaSteps, setFormulaSteps] = useState([]);
  const [includesAZCWP, setIncludesAZCWP] = useState(false)

  const [replacementsValidationResults, setReplacementsValidationResults] = useState(undefined)
  const [showValidationModal, setShowValidationModal] = useState(false)
  const [acceptedTerms, setAcceptedTerms] = useState(false)

  /**
   * Originals will be selected from the steps popup
   * We can either get one original or multiple orinals from the popup
   * We can use that data to show oringlas
   * But for now based on description type selected, I will use either one or multiple
   * originals. This should be changed later on.
   */

  /**
   * multipleReplacements is used to show multiple replacements, we have a component
   * called MultipleReplacements which will take care of crating multiple replacements
   * and sends that value back here
   */

  const [canValidateMultipleReplacements, setCanValidateMulitpleReplacements] = useState(false);
  let today = new Date();
  let days = deviationType === 'relabel' ? 365 : 90
  const maxDate = today.setDate(today.getDate() + days)

  useEffect(() => {
    let details;
    if (deviationDetails !== '') {
      console.log("deviationDetails line 108: ", deviationDetails)
      console.log("deviationType line 109: ", deviationType)
      console.log("officialProductDetails: ", officialProductDetails)
      //This is called when we got details from clone option
      const {
        materialNumber,
        sapMaterialNumber,
        replacementSapMaterialNumber,
        recipeStepName,
        deviationValue,
        plantName,
        description,
        plantCode,
        npdNumber,
        beginDate,
        endDate,
      } = deviationDetails;
      console.log('date', new Date(beginDate));
      details = {
        headerMaterial: materialNumber,
        plants: [plantCode + ' ' + plantName],
        originals: [{ title: deviationType === "component" || deviationType === "relabel" ? sapMaterialNumber : recipeStepName, description: '', allergens: [], mayContain: [], materialType: "" }],
        //  replacement: replacementSapMaterialNumber,
        //  beginDate: beginDate,
        //  endDate: endDate,
        //  npdNumber: npdNumber,
      };
      setOriginals([{ title: deviationType === "component" || deviationType === "relabel" ? sapMaterialNumber : recipeStepName, description: '', allergens: [], mayContain: [], materialType: '' }]);
      setOriginalsChosen(true);
      setSingleReplacementText(deviationType === "component" || deviationType === "relabel" ? replacementSapMaterialNumber : deviationValue);
      setReasonOrDescription(description);
      setNpdNumber(npdNumber);
      npdNumber ? setSpecChgReq(true) : setSpecChgReq(false)

      setStartDate(beginDate.replace(/[-]/g, '/'));
      setEndDate(endDate.replace(/[-]/g, '/'));
      setReplacementsValidated(true);
      setReplacementsValid(true);
      setDevDetails(details);
    } else {
      //We have recieved details starting from scratch
      details = {
        headerMaterial: materialNumber,
        plants: plants,
      };
      if (deviationType === 'relabel') {
        console.log("is relabel")
        console.log("materialNumber: ", materialNumber)
        setOriginals([{ title: materialNumber, description: '', allergens: [], mayContain: [], materialType: '' }])
        setOriginalsChosen(true)
      }
      setDevDetails(details);
    }
    // console.log('details',details,Object.entries(details).length>0)
    setReplacementsValidationResults(undefined);
  }, [deviationDetails])



  useEffect(() => {
    if (deviationDetails) {
      if (!originals || originals.length === 0) return
      console.log("deviationDetails.sapMaterialNumber: ", deviationDetails.sapMaterialNumber)
      console.log("originals[0].title: ", originals[0].title)
      if (deviationType === "component" || deviationType === "relabel") {
        if (deviationDetails.sapMaterialNumber !== originals[0].title) {
          setReplacementsValidated(false);
          setReplacementsValid(false);
        }
      }
    } else {
      setReplacementsValidated(false);
      setReplacementsValid(false);
    }
    setOriginalsChosen(true)
    if (!originals || originals.length === 0) {
      setOriginalsChosen(false)
    }
    console.log("originals: ", originals)
  }, [originals])

  const InputItems2 = (step, contents) => {
    step.InputItems?.InputDetail?.forEach(item => {
      const legacyProfile = item.CrossReferences?.LegacyProfile?.filter(legProf => legProf.SystemId === "SAP4MM")
      const sapMaterialNum = legacyProfile ? legacyProfile[0].Equivalent : ""
      const allergens = item.Allergens.string ? item.Allergens.string.length > 0 ? item.Allergens.string : [] : []
      const mayContain = [];
      contents.push({ title: sapMaterialNum, description: item.SpecName, allergens, mayContain, materialType: item.SapMaterialType })
      if (deviationDetails?.sapMaterialNumber === sapMaterialNum) {
        setOriginals([{ title: deviationDetails.sapMaterialNumber, description: item.SpecName, allergens, mayContain, materialType: item.SapMaterialType }]);
      }
    })
  }

  const InputItems3 = (step, contents) => {
    const legacyProfile = step.InputItems?.InputDetail?.CrossReferences?.LegacyProfile?.filter(legProf => legProf.SystemId === "SAP4MM")
    const sapMaterialNum = legacyProfile ? legacyProfile[0].Equivalent : ""
    const allergens = step.InputItems.InputDetail.Allergens.string ? step.InputItems.InputDetail.Allergens.string.length > 0 ? step.InputItems.InputDetail.Allergens.string : [] : []
    const mayContain = [];
    contents.push({ title: sapMaterialNum, description: step.InputItems.InputDetail.SpecName, allergens, mayContain, materialType: step.InputItems.InputDetail.SapMaterialType })
    if (deviationDetails?.sapMaterialNumber === sapMaterialNum) {
      setOriginals([{ title: deviationDetails.sapMaterialNumber, description: step.InputItems.InputDetail.SpecName, allergens, mayContain, materialType: step.InputItems.InputDetail.SapMaterialType }]);
    }
  }


  const FormulaSteps = (contents, auxFormulaSteps, getFormulaSteps) => {
    auxFormulaSteps?.forEach((step, index) => {
      console.log("step: ", step)
      if (deviationType !== "process") {
        contents = []
        if (step.InputItems?.InputDetail?.length > 0) {
          InputItems2(step, contents)
        } else {
          InputItems3(step, contents)
        }
        getFormulaSteps.push({ title: step.Name + " - " + step.Code, contents });
      } else {
        // deviationDetails.recipeStepName
        if (deviationDetails?.recipeStepName === step.Name) {
          setOriginals([{ title: deviationDetails.recipeStepName, description: step.Code, allergens: [], mayContain: [], materialType: "" }]);
        }
        console.log("contents.push: ", step.Name)
        const auxPackingMaterial = officialProductDetails.PackagingMaterials.PackagingMaterialDetail;
        contents.push({ title: step.Name, description: step.Code, allergens: [], mayContain: [], materialType: "" })
        if (index === auxFormulaSteps.length - 1) {
          if (auxPackingMaterial) contents.push({ title: "Packing", description: "", allergens: [], mayContain: [], materialType: "" })
          getFormulaSteps.push({ title: "Processes", contents })
        }
      }
    })
  }

  useEffect(() => {
    setLoading(true);
    console.log("Official product: ", officialProductDetails);
    console.log("zcwp details: ", zwcpDetails);
    if (officialProductDetails) {
      setIngredientStatement(officialProductDetails.IngredientStatement)
      let auxFormulaSteps = officialProductDetails.FormulaSteps.FormulaStep;
      // If it is a single element wrap it as an array
      if (Object.keys(officialProductDetails.FormulaSteps.FormulaStep || {}).includes("Code")) {
        auxFormulaSteps = [officialProductDetails.FormulaSteps.FormulaStep]
      }

      const auxPackingMaterial = officialProductDetails.PackagingMaterials.PackagingMaterialDetail;
      console.log("auxFormulaSteps: ", auxFormulaSteps)
      const getFormulaSteps = [];
      const contents = []

      const auxArray = [];
      if (deviationType !== "process") {
        console.log("auxPackingMaterial: ", auxPackingMaterial)
        if (auxPackingMaterial?.length > 0) {
          auxPackingMaterial?.forEach(el => {
            auxArray.push({ title: el.SapMaterialNumber, description: el.SpecName })
          })
        } else if (auxPackingMaterial) {
          auxArray.push({ title: auxPackingMaterial.SapMaterialNumber, description: auxPackingMaterial.SpecName })
        }

        if (auxPackingMaterial) getFormulaSteps.push({ title: "Packing", contents: auxArray });
      }
      console.log("getFormulaSteps: ", getFormulaSteps)
      const firstElement = getFormulaSteps.shift();
      getFormulaSteps.push(firstElement)
      setFormulaSteps(getFormulaSteps);
      FormulaSteps(contents, auxFormulaSteps, getFormulaSteps)
    } else if (zwcpDetails) {
      setIncludesAZCWP(true)
      const getFormulaSteps = [];
      const auxArray = [];
      zwcpDetails.forEach(el => {
        console.log("el.Allergen: ", el.Allergen)
        auxArray.push({ title: el.Component, description: el.Descr, allergens: el.Allergen ?? [], materialType: el.Mat_type });
      })
      getFormulaSteps.push({ title: zwcpDetails[0].Plant, contents: auxArray });
      const firstElement = getFormulaSteps.shift();
      getFormulaSteps.push(firstElement)
      setFormulaSteps(getFormulaSteps);
      console.log(getFormulaSteps);
    } else {
      swal.fire({
        icon: 'warning',
        title: 'No data found for material number',
        timer: 3000,
      });
    }
    setLoading(false);
  }, []);

  //Single Replacement text
  const handleReplaceInput = (value) => {
    if (deviationType === "component" || deviationType === "relabel") {
      const re = /^[0-9\b]+$/;
      if (value === '' || re.test(value)) {
        setSingleReplacementText(value);
      }
    }
    if (deviationType === "process") {
      if (value !== "") {
        setReplacementsValid(true)
        setReplacementsValidated(true)
      } else {
        setReplacementsValid(false)
        setReplacementsValidated(false)
      }
      setSingleReplacementText(value);
    }

  };
  const originalsChosen = () => {
    setOriginalsChosen(true);
  };

  const handleSpecChgChange = (e) => {
    if (e.target.checked === false) {
      setNpdNumber('');
    }
    setSpecChgReq(e.target.checked);
  };

  const handleNpdNumberChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setNpdNumber(e.target.value);
    }
  };

  const handleValidateReplacements = () => {
    /**
     * if the describe type is 2 then multiple replacements validation otherwise
     * single replacement validation. this needs to be taken care later.
     * But for now clicking on validate button I am changing replacementsValidated to true
     */
    console.log("describeType: ", describeType)
    console.log("originals: ", originals)
    setAcceptedTerms(false)
    if (!zwcpDetails) setIncludesAZCWP(false)
    // describeType 1 or 3 single replacement
    if (describeType !== "2") {
      console.log("zcwpDetails: ", zwcpDetails)
      setLoading(true)
      onValidateDeviationMaterial(plantCodes, [singleReplacementText], originals, zwcpDetails ? true : false).then(result => {
        console.log("I'm the result ", result);
        setLoading(false)
        setReplacementsValidated(false)
        setReplacementsValid(false)
        if (typeof result === "string") {
          console.log("validate deviation material error: ", result)
          swal.fire({
            icon: "warning",
            title: "No data returned from service!",
          })
          return
        }
        if (!result) {
          setLoading(false)
          setReplacementsValidated(false)
          setReplacementsValid(false)
          console.log("validate deviation material error: no result")
          swal.fire({
            icon: "warning",
            title: "No data returned from service!",
          })
          return
        }
        if (result?.data && result?.data?.isSuccess === false) {
          setLoading(false)
          setReplacementsValidated(false)
          setReplacementsValid(false)
          console.log("validate deviation material error: ", result.data)
          swal.fire({
            icon: "warning",
            title: "No data returned from service!",
          })
          return
        }
        let allReplacementsValid = true
        let tempValidationResults = []
        let validationResults = result.data.data
        validationResults.forEach(material => {
          if (typeof material.description !== "string") {
            material.description = Object.values(material.description)[0];
          }
          if (!material.validMaterial || !material.validPlant || !material.validAllergens || !material.compatibleMaterialTypes || !material.validMayContain) {
            allReplacementsValid = false
          }
          if (material?.originalMaterialType === "ZCWP" || material?.replacementMaterialType === "ZCWP") setIncludesAZCWP(true)
          tempValidationResults.push({
            materialNumber: showAdditionalComponents ? material.material + " - " + material.original : material.material,
            valid: material.validPlant && material.validMaterial && material.validAllergens && material.compatibleMaterialTypes && material.validMayContain,
            validPlant: material.validPlant,
            validMaterial: material.validMaterial,
            validAllergen: material.validAllergens,
            validMayContain: material.validMayContain,
            compatibleMaterialTypes: material.compatibleMaterialTypes,
            description: material.description
          })
        })

        setLoading(false)
        setReplacementsValidationResults(tempValidationResults)
        setReplacementsValidated(true)
        setReplacementsValid(allReplacementsValid)
        setShowValidationModal(true)
      })
    }
    // describeType 2 multiple replacements
    if (describeType === "2") {
      const tempMultipleReplacements = [...multipleReplacements];
      tempMultipleReplacements[
        tempMultipleReplacements.length - 1
      ].editable = false;
      setMultipleReplacements(tempMultipleReplacements);

      let allReplacementsValid = true
      let tempValidationResults = []
      setLoading(true)
      const replacementNumbers = multipleReplacements.map(rep => rep.text)
      onValidateDeviationMaterial(plantCodes, replacementNumbers, originals, zwcpDetails ? true : false).then(result => {
        if (typeof result === "string") {
          setLoading(false)
          setReplacementsValidated(false)
          setReplacementsValid(false)
          console.log("validate deviation material error: ", result)
          swal.fire({
            icon: "warning",
            title: "No data returned from service!",
          })
          return
        }

        if (!result) {
          setLoading(false)
          setReplacementsValidated(false)
          setReplacementsValid(false)
          console.log("validate deviation material error: no result")
          swal.fire({
            icon: "warning",
            title: "No data returned from service!",
          })
          return
        }

        if (result?.data && result?.data?.isSuccess === false) {
          setLoading(false)
          setReplacementsValidated(false)
          setReplacementsValid(false)
          console.log("validate deviation material error: ", result.data)
          swal.fire({
            icon: "warning",
            title: "No data returned from service!",
          })
          return
        }

        let validationResults = result.data.data
        validationResults.forEach(material => {
          if (!material.validMaterial || !material.validPlant || !material.validAllergens || !material.compatibleMaterialTypes) {
            allReplacementsValid = false
          }
          if (material?.originalMaterialType === "ZCWP" || material?.replacementMaterialType === "ZCWP") setIncludesAZCWP(true)
          console.log("material: ", material)
          console.log("showAdditionalComponents: ", showAdditionalComponents)
          tempValidationResults.push({
            materialNumber: showAdditionalComponents ? material.material + " - " + material.original : material.material,
            valid: material.validPlant && material.validMaterial && material.validAllergens && material.compatibleMaterialTypes,
            validPlant: material.validPlant,
            validMaterial: material.validMaterial,
            validAllergen: material.validAllergens,
            compatibleMaterialTypes: material.compatibleMaterialTypes,
            description: material.description
          })

        })
        setLoading(false)
        setReplacementsValidationResults(tempValidationResults)
        setReplacementsValidated(true)
        setReplacementsValid(allReplacementsValid)
        setShowValidationModal(true)

      })
    }
  };

  const onValidationClose = (editValid) => {
    setShowValidationModal(false)
    if (describeType === "2") {
      if (!replacementsValidationResults) return
      let editableReplacements = [...multipleReplacements]
      let tempValidationResults = [...replacementsValidationResults]
      editableReplacements.forEach(edRep => {
        tempValidationResults.forEach(rep => {
          console.log("rep: ", rep)
          if (edRep.text === rep.materialNumber) {
            if (rep.valid) {
              edRep.editable = editValid ? true : false
            } else {
              edRep.editable = true
            }
          }
        })
      })
      setMultipleReplacements(editableReplacements);
      if (editValid) {
        setReplacementsValidated(false)
        setReplacementsValid(false)
      }
    }
    if (describeType !== "2") {
      if (!replacementsValidationResults) return
      for (let i = 0; i < replacementsValidationResults.length; i++) {
        if (!replacementsValidationResults[i].valid) {
          setSingleReplacementText('')
          break;
        }
      }
      if (editValid) {
        setReplacementsValidated(false)
        setReplacementsValid(false)
      }
    }
  }

  const handleChangeReplacements = () => {
    /**
     * 1. Clicking on change replacements, we will need to empty data in the
     * singleReplacementText, multipleReplacements
     * based on describeType
     */
    if (describeType == 2) {
      let tempMultipleReplacements = [...multipleReplacements];
      tempMultipleReplacements = [{ id: 1, text: '', editable: true }];
      setMultipleReplacements(tempMultipleReplacements);
    } else {
      setSingleReplacementText('');
    }
    setReplacementsValid(false)
    setReplacementsValidated(false);
    setCanShowSaveButtons(false)
  };
  useEffect(() => {
    console.log("multipleReplacements: ", multipleReplacements)
    if (multipleReplacements.length === 0) return
    let totalCharsLength = 0;
    if (multipleReplacements.length > 1) {
      multipleReplacements.forEach((replacement) => {
        totalCharsLength = totalCharsLength + replacement.text.length;
      });
    } else {
      totalCharsLength = multipleReplacements[0]?.text?.length;
    }

    if (totalCharsLength >= multipleReplacements.length) {
      setCanValidateMulitpleReplacements(true);
    } else {
      setCanValidateMulitpleReplacements(false);
    }
  }, [multipleReplacements]);

  const updateReplacements = (replacements) => {
    console.log('replacements', replacements)
    if (deviationType === "process") {
      let emptyReplacements = replacements.filter(rep => rep.text === "")
      if (emptyReplacements.length > 0) {
        setReplacementsValid(false)
        setReplacementsValidated(false)
      } else {
        setReplacementsValid(true)
        setReplacementsValidated(true)
      }
    }
    setMultipleReplacements(replacements);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (endDate < date) setEndDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    //console.log(date);
  };

  const handleReasonOrDescription = (e) => {
    const reasonValue = e.target.value;
    setReasonOrDescription(reasonValue);
  };

  useEffect(() => {
    if ((replacementsValidated && replacementsValid) && reasonOrDescription) {
      setCanShowSaveButtons(true)
      setAreVerified(true);
    } else {
      setCanShowSaveButtons(false)
      setAreVerified(false);
    }
  }, [replacementsValidated, replacementsValid, reasonOrDescription])

  return (
    <Container fluid style={{ padding: '0px', margin: '0px' }}>
      <hr />
      <Row style={{ margin: '5px' }}>
        <span
          style={{
            fontSize: '16px',
            color: '#65686B',
            textTransform: 'uppercase',
            fontWeight: 'bold',
          }}
        >
          1. Dev Details
        </span>
        <span>
          {' '}
          ( <span style={{ color: 'red' }}>*</span>are required fields)
        </span>
      </Row>
      <TextInputBox readonly title="Header Material:" value={devDetails.headerMaterial} />
      <Row style={{ margin: '0px' }}>
        <Col xs={6} className="right" style={{ padding: '0px' }}>
          <span style={{ paddingRight: '5px' }}>{'Plant(s):'}</span>
        </Col>
        <Col xs={6} className="left" style={{ padding: '0px' }}>
          {devDetails.plants
            ? devDetails.plants.map((plant) => (
              <DisabledText
                text={plant}
                style={{ width: '100%', marginBottom: '5px' }}
              />
            ))
            : undefined}
        </Col>
      </Row>
      <Row style={{ margin: '0px' }}>
        <Col xs={6} className="right" style={{ padding: '0px' }}>
          <span style={{ color: 'red' }}>*</span>
          <span style={{ paddingRight: '5px' }}>{'Original(s):'}</span>
        </Col>
        <Col xs={6} style={{ padding: '0px', margin: '0px' }}>
          {!isOriginalsChosen && !onSaveAndReview ? (
            <Row style={{ margin: '0px' }}>
              <u
                style={{ cursor: 'pointer' }}
                onClick={() => setShowOriginals(true)}
              >
                Choose Original(s)
              </u>
            </Row>
          ) : (
            <Col style={{ padding: '0px', margin: '0px' }}>
              <Col className="left" style={{ padding: '0px', margin: '0px' }}>
                {originals.length > 0 &&
                  originals.map((original) => {
                    return (
                      <Col
                        style={{
                          padding: '0px',
                          margin: '0px',
                          width: '100%',
                        }}
                      >
                        <DisabledText
                          text={original.title}
                          style={{ width: '100%', marginBottom: '5px' }}
                        />
                        <div style={{ color: '#A0A0A0' }}>
                          <small>
                            {original.description}
                          </small>
                        </div>
                      </Col>
                    )
                  })}
              </Col>

              {deviationType !== 'relabel' && !onSaveAndReview ? <Col
                style={{
                  marginBottom: '10px',
                  marginTop: '5px',
                  textAlign: 'start',
                  paddingLeft: '0px',
                }}
              >
                <u
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowOriginals(true)}
                >
                  Change Original(s)
                </u>
              </Col> : undefined}
            </Col>
          )}
        </Col>
      </Row>

      {isOriginalsChosen ? (
        describeType == 2 ? (
          <Row style={{ margin: '0px' }}>
            <Col xs={6} md={6} className="right" style={{ padding: '0px' }}>
              <span style={{ color: 'red' }}>*</span>
              <span style={{ paddingRight: '5px' }}>{'Replacement(s):'}</span>
            </Col>
            <Col xs={6} className="left" style={{ padding: '0px' }}>
              <MultipleReplacement
                replacementsValid={replacementsValid}
                multipleReplacements={multipleReplacements}
                deviationType={deviationType}
                // setMultipleReplacements={setMultipleReplacements}
                replacementsValidationResults={replacementsValidationResults}
                updateReplacements={updateReplacements}
                replacementsValidated={replacementsValidated}
              />

              {replacementsValidated && replacementsValid && !onSaveAndReview ? (
                <Col
                  style={{
                    marginBottom: '10px',
                    marginTop: '5px',
                    textAlign: 'start',
                    paddingLeft: '0px',
                  }}
                >
                  <u
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleChangeReplacements()}
                  >
                    Change Replacement(s)
                  </u>
                </Col>
              ) : null}
            </Col>
          </Row>
        ) : (
          <Row style={{ margin: '0px' }}>
            <Col xs={6} md={6} className="right" style={{ padding: '0px' }}>
              <span style={{ color: 'red' }}>*</span>
              <span style={{ paddingRight: '5px' }}>{'Replacement(s):'}</span>
            </Col>
            <Col xs={6} className="left" style={{ padding: '0px' }}>
              <EditableText
                deviationType={deviationType}
                style={{ width: '100%', marginBottom: '5px' }}
                onChange={(e) => handleReplaceInput(e.target.value)}
                disabled={replacementsValidated && replacementsValid && (deviationType === "component" || deviationType === "relabel") ? true : false}
                text={singleReplacementText}
              />
              {/*Code commented to show the description on replacement */}
              <div style={{ color: '#A0A0A0' }}><small>{replacementsValidationResults?.length > 0 && replacementsValid ? replacementsValidationResults[0]?.description : ""}</small></div>
              {replacementsValidated && replacementsValid && !onSaveAndReview ? (
                <Col
                  style={{
                    marginBottom: '10px',
                    marginTop: '5px',
                    textAlign: 'start',
                    paddingLeft: '0px',
                  }}
                >
                  <u
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleChangeReplacements()}
                  >
                    Change Replacement(s)
                  </u>
                </Col>
              ) : null}
            </Col>
          </Row>
        )
      ) : null}

      {isOriginalsChosen && (deviationType === "component" || deviationType === "relabel") && (!replacementsValidated || !replacementsValid) ? (
        describeType == 2 ? (
          <OutLineButton
            text="Validate Replacement(s)"
            backgroundColor="#65686B"
            isClickable={
              canValidateMultipleReplacements
              // multipleReplacements.length > 0 &&
              // multipleReplacements[0].text.length > 0
              //   ? true
              //   : false
            }
            onClick={handleValidateReplacements}
          />
        ) : (
          <OutLineButton
            text="Validate Replacement(s)"
            backgroundColor="#65686B"
            onClick={handleValidateReplacements}
            isClickable={singleReplacementText.length > 0 ? true : false}
          />
        )
      ) : null}
      {zwcpDetails && isOriginalsChosen ? <p style={{ color: "red" }}>Caution! ZCWP's allergens aren't validated</p> : undefined}
      {replacementsValidated && replacementsValid ? (
        <Col style={{ margin: '0px', padding: '0px' }}>
          <Row style={{ margin: '0px', marginBottom: '5px' }}>
            <Col xs={6} md={6} className="right" style={{ padding: '0px' }}>
              <span style={{ color: 'red' }}>*</span>
              <span style={{ paddingRight: '5px' }}>{'Dev Begin Date:'}</span>
            </Col>
            <Col className="left" style={{ padding: '0px' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disablePast
                  disableToolbar
                  className="inputStyle"
                  inputFormat="MM/DD/YYYY"
                  value={startDate ? dayjs(startDate) : null}
                  onChange={handleStartDateChange}
                  disabled={onSaveAndReview}
                  maxDate={maxDate ? dayjs(maxDate) : null}
                  renderInput={(params) => <TextField {...params} variant="outlined" InputAdornmentProps={{ position: 'end' }} />}
                />
              </LocalizationProvider>
            </Col>
          </Row>
          <Row style={{ margin: '0px', marginBottom: '5px' }}>
            <Col xs={6} md={6} className="right" style={{ padding: '0px' }}>
              <span style={{ color: 'red' }}>*</span>
              <span style={{ paddingRight: '5px' }}>{'End Date:'}</span>
            </Col>
            <Col className="left" style={{ padding: '0px' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disablePast
                  disableToolbar
                  className="inputStyle"
                  inputFormat="MM/DD/YYYY"
                  value={endDate ? dayjs(endDate) : null}
                  onChange={handleEndDateChange}
                  disabled={onSaveAndReview}
                  maxDate={maxDate ? dayjs(maxDate) : null}
                  renderInput={(params) => <TextField {...params} variant="outlined" InputAdornmentProps={{ position: 'end' }} />}
                />
              </LocalizationProvider>
            </Col>
          </Row>
          <Row style={{ margin: '0px' }}>
            <Col xs={6} md={6} className="right" style={{ padding: '0px' }}>
              <span style={{ color: 'red' }}>*</span>
              <span style={{ paddingRight: '5px' }}>{'Reason/Description:'}</span>
            </Col>
            <Col xs={6} md={6} className="left" style={{ padding: '0px' }}>

            </Col>

          </Row>
          <Row style={{ marginLeft: '50px' }}>
            <Col xs={12} md={12} className="right" style={{ padding: '0px' }}>
              <TextareaAutosize
                value={reasonOrDescription}
                onChange={handleReasonOrDescription}
                rows={3}
                maxLength={1000}
                className="inputStyle2"
                disabled={onSaveAndReview}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={{ offset: 6, span: 6 }} md={{ offset: 6, span: 6 }} className='right' style={{ paddingRight: '42px' }}>
              <span className="fontSizeMobile">(Up to 1,000 characters)</span>
            </Col>
          </Row>
          {userInfo?.role === 'CORP' || userInfo?.role === "ADMIN" ? (
            <FormGroup style={{ marginBottom: '0.5rem' }}>
              <FormControlLabel
                component="legend"
                control={
                  <Checkbox
                    onChange={(e) => handleSpecChgChange(e)}
                    color="default"
                  />
                }
                checked={specChgReq}
                label="Spec change required"
                disabled={onSaveAndReview}
              />
            </FormGroup>
          ) : undefined}
          {specChgReq === true ? (
            <Row>
              <Col className="text-right pr-1">
                <FormLabel component="span" className="fontSizeMobile">
                  Npd number:
                </FormLabel>
              </Col>
              <Col className="pl-0 pb-1">
                <TextField
                  size="small"
                  variant="outlined"
                  className="inputStyle"
                  value={npdNumber}
                  onChange={(e) => handleNpdNumberChange(e)}
                />
              </Col>
            </Row>
          ) : undefined}
        </Col>
      ) : null}

      {showOriginals ? <ChooseOriginals
        showSteps={showOriginals}
        closeSteps={setShowOriginals}
        originalsChosen={originalsChosen}
        setOriginals={setOriginals}
        formulaSteps={formulaSteps}
        canSelectMultiple={describeType == 3 ? true : false}
        size={"md"}
        officialProductDetails={officialProductDetails}
        zwcpDetails={zwcpDetails}
        plantCodes={deviationDetails ? [deviationDetails.plantCode] : plantCodes}
        userInfo={userInfo}
        showAdditionalComponents={showAdditionalComponents}
        setShowAdditionalComponents={setShowAdditionalComponents}
        additionalComponents={additionalComponents}
        onGetAdditionalComponents={onGetAdditionalComponents}
        materialNumber={materialNumber}
        setLoading={setLoading}
        onSetAdditionalComponents={onSetAdditionalComponents}
        deviationType={deviationType}
      /> : undefined}
      {showValidationModal && replacementsValidationResults ? <ValidateReplacementModal
        open={showValidationModal}
        onClose={onValidationClose}
        replacements={replacementsValidationResults}
        replacementsValid={replacementsValid}
        acceptedTerms={acceptedTerms}
        setAcceptedTerms={setAcceptedTerms}
        includesAZCWP={includesAZCWP}
      /> : undefined}
    </Container>
  );
};

export default DevDetailsRequest;
