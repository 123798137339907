// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HistoryMobileToggles_toggleLabels__N-ypR {
    padding-top: 8px;
    font-size: 14px;
    color: #65686B;
}

.HistoryMobileToggles_grayedToggleLabel__e\\+lYO {
    padding-top: 8px;
    font-size: 14px;
    color: #EAEAEA;
}`, "",{"version":3,"sources":["webpack://./src/components/historyMobileToggles/HistoryMobileToggles.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB","sourcesContent":[".toggleLabels {\n    padding-top: 8px;\n    font-size: 14px;\n    color: #65686B;\n}\n\n.grayedToggleLabel {\n    padding-top: 8px;\n    font-size: 14px;\n    color: #EAEAEA;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggleLabels": `HistoryMobileToggles_toggleLabels__N-ypR`,
	"grayedToggleLabel": `HistoryMobileToggles_grayedToggleLabel__e+lYO`
};
export default ___CSS_LOADER_EXPORT___;
