import React from 'react'
import { Col, Row } from 'react-bootstrap'
import FormGroup from '@mui/material/FormGroup';
import Android12Switch from '../../androidToggle/AndroidToggle'
import FormControlLabel from '@mui/material/FormControlLabel';
import styles from './HistoryReportToggles.module.css'
import Switch from '@mui/material/Switch';

const HistoryReportToggles = props => {

    return (
        <>
            <Col xs={8} sm={8} md={8} >
                <Row >
                    <span className={styles.toggleLabels}>Approver History</span>
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch
                                sx={{
                                    color: 'white', backgroundColor: 'gray', borderRadius: '34px', width: '60px', height: '20px',
                                    marginLeft: '10px', marginRight: '10px', marginTop: '5px'
                                }}
                                color='default'
                                checked={!props.isApproverChecked}
                                onChange={props.onChangeIsApprover}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />}
                        />
                    </FormGroup>
                    <span className={styles.toggleLabels}>Requester History</span>
                </Row>
            </Col>
            <Col xs={4} sm={4} md={4} >
                <Row style={{ marginLeft: "0px" }}>
                    {props.isApproverChecked ? <> <span className={styles.toggleLabels}>My Actions</span>
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch
                                    sx={{
                                        color: 'white', backgroundColor: 'gray', borderRadius: '34px', width: '60px', height: '20px',
                                        marginLeft: '10px', marginRight: '10px', marginTop: '5px'
                                    }}
                                    color='default'
                                    checked={!props.isMyActionsChecked}
                                    onChange={props.onChangeMyActions}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />}
                            />
                        </FormGroup>
                        <span className={styles.toggleLabels}>BU All</span> </> : <span className={props.draftClicked ? styles.goBack : styles.drafts} onClick={props.onDraftClick}>{props.draftClicked ? 'Go back' : 'Drafts'}</span>}
                </Row>
            </Col>
        </>
    )
}

export default HistoryReportToggles