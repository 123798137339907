import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarIcon from '@mui/icons-material//Star';
import StarBorderOutlinedIcon from '@mui/icons-material//StarBorderOutlined';
import BackIcon from '@mui/icons-material//ArrowBackIos'
import OutlinedInput from '@mui/material/OutlinedInput';
// import SearchIcon from '@mui/icons-material//Search';
import styles from './leftMenu.module.css'

const FavoritePlantsMenu = props => {

    const [searchValue, setSearchValue] = useState("")
    const [filteredPlants, setFilteredPlants] = useState([])

    let favPlantCount = props.plantsForUser?.filter(plant => plant.isFavorite).length

    const onEnterKeyPress = e => {
        if (e.key === "Enter") {
            filterPlants()
        }
    }

    const onHandleChange = e => {
        setSearchValue(e.target.value)
    }

    const filterPlants = () => {
        let plantsDuplicate = [...props.plantsForUser]
        if (searchValue !== "") {
            plantsDuplicate = plantsDuplicate.filter(plant => plant.plantCode.includes(searchValue))
        }
        setFilteredPlants(plantsDuplicate)
    }

    useEffect(() => {
        if (!props.plantsForUser) return
        setFilteredPlants([...props.plantsForUser])
    }, [props.plantsForUser])

    const backFavPlantsLink =
        <div>
            <StarIcon className={styles.star} />
            Favorite plants
        </div>

    const plantsSearchBar =
        <OutlinedInput
            fullWidth={true}
            type="text"
            className="inputStyle"
            style={{ paddingLeft: '5px', width: '90%', fontSize: '14px' }}
            placeholder="Search by Plant Code"
            onKeyPress={(e) => onEnterKeyPress(e)}
            onChange={(e) => onHandleChange(e)}
            inputProps={{ style: { fontFamily: 'proxima-nova' } }}
            value={searchValue}
        // startAdornment={
        //     <InputAdornment sizeSmall >
        //         <IconButton aria-label="search" onClick={props.onHandleClick}>
        //             <SearchIcon />
        //         </IconButton>
        //     </InputAdornment>
        // }
        />

    const plantsForUserList =
        <div style={{ overflowY: 'scroll', overflowX: 'hidden', maxHeight: '820px', height: '820px' }}>
            {plantsSearchBar}
            <div style={{ marginTop: '10px' }}>
                {filteredPlants ? filteredPlants.map(plant => {
                    return (
                        <li key={plant.plantCode} style={{ whiteSpace: 'nowrap' }} title={plant.plantName}>
                            {plant.isFavorite ?
                                <StarIcon
                                    className={styles.star}
                                    onClick={(e) => {
                                        props.markUnmarkFavoriteAndRefresh(plant.plantCode)
                                        e.stopPropagation()
                                    }}
                                /> :
                                <StarBorderOutlinedIcon
                                    className={styles.star}
                                    onClick={(e) => {
                                        props.markUnmarkFavoriteAndRefresh(plant.plantCode)
                                        e.stopPropagation()
                                    }}
                                />}
                            <label >{plant.plantCode + " - " + plant.plantName}</label>
                        </li>
                    )
                })
                    : undefined}
            </div>
        </div>



    return (
        <Box
            sx={{ width: 250 }}
            role="presentation"
        >
            <List>
                <ListItem key={1} classes={{ root: styles.noTopBotPadding }}>
                    <ListItemIcon style={{ cursor: "pointer" }}>
                        <BackIcon onClick={() => props.toggleMenu("menu", true)} />
                    </ListItemIcon>
                    <ListItemText classes={{ primary: styles.mainText }} primary={backFavPlantsLink} />
                </ListItem>
                <ListItem key={2} classes={{ root: styles.noTopBotPadding, primary: styles.noTopBotPadding }}>
                    <ListItemText classes={{ primary: styles.plantsTitle }} primary={"PLANTS"} />
                </ListItem>
                <ListItem key={3} classes={{ root: styles.favoritePlantCount }}>
                    <ListItemText classes={{ primary: styles.favoritePlantCount }} primary={"You have " + favPlantCount + " favorites"} />
                </ListItem>
                <ListItem key={4} >
                    {plantsForUserList}
                </ListItem>
            </List>
        </Box>
    )
}

export default FavoritePlantsMenu