// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MobileHomePage_refreshButton__VZ1NY {
    margin-left: 15px !important;
    margin-bottom: 15px !important;
    font-size: 18px;
    background-color: #002554 !important;
    color: white !important;
    font-family: 'proxima-nova',
}
`, "",{"version":3,"sources":["webpack://./src/screens/mobileHomePage/MobileHomePage.module.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,8BAA8B;IAC9B,eAAe;IACf,oCAAoC;IACpC,uBAAuB;IACvB;AACJ","sourcesContent":[".refreshButton {\n    margin-left: 15px !important;\n    margin-bottom: 15px !important;\n    font-size: 18px;\n    background-color: #002554 !important;\n    color: white !important;\n    font-family: 'proxima-nova',\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"refreshButton": `MobileHomePage_refreshButton__VZ1NY`
};
export default ___CSS_LOADER_EXPORT___;
