import * as actionTypes from "./actionTypes"
import serverAxios from "../../services/axios-instance"

const setAllPlantsForHeaderMaterial = plants => {
    return {
        type: actionTypes.SET_ALL_PLANTS_FOR_HEADER_MATERIAL,
        headerMaterialPlants: plants
    }
}

export const setOfficialProduct = product => {
    return {
        type: actionTypes.SET_OFFICIAL_PRODUCT,
        officialProduct: product
    }
}

export const setZcwpDetails = zcwpDetails => {
    return {
        type: actionTypes.SET_ZCWP_DETAILS,
        zcwpDetails
    }
}

export const setLastSavedDeviations = lastSavedDeviations => {
    return {
        type: actionTypes.SET_LAST_SAVED_DEVIATIONS,
        lastSavedDeviations
    }
}

export const setUpdateDeviation = deviationData => {
    return {
        type: actionTypes.SET_UPDATE_DEVIATION,
        deviationData
    }
}

export const setAdditionalComponents = additionalComponents => {
    return {
        type: actionTypes.SET_ADDITIONAL_COMPONENTS,
        additionalComponents
    }
}

export const setLoadingText = loadingText => {
    return {
        type: actionTypes.SET_LOADING_TEXT,
        loadingText
    }
}

export const getAllPlantsForHeaderMaterial = (headerMaterial) => {
    return (dispatch, getState) => {
        let plantCodes = getState().userInfo.userInfo.plantCodes
        let role = getState().userInfo.userInfo.role
        console.log("myRole: ", role)
        dispatch(setLoadingText("Retrieving plants for material"))
        return new Promise(async (resolve) => {
            const callAxios = await serverAxios();
            callAxios
                .get(`sapGsm/getPlantsByMaterialNumber/${headerMaterial}`)
                .then(result => {
                    console.log("result: ", result)
                    if (result?.data && result?.data?.isSuccess) {
                        let plants = result.data.data
                        if (role !== "CORP" && role !== "ADMIN") plants = plants.filter(plant => plantCodes.includes(plant.Plant))
                        dispatch(setAllPlantsForHeaderMaterial(plants))
                    }
                    resolve(result)
                })
                .catch(err => {
                    console.log("err json: ", JSON.stringify(err))
                    console.log("err: ", err)
                    resolve(JSON.stringify(err))
                })
        })
    }
}

export const getOfficialProduct = (materialNumber) => {
    return (dispatch) => {
        dispatch(setZcwpDetails(undefined))
        dispatch(setOfficialProduct(undefined))
        dispatch(setLoadingText("Retrieving Official Product details"))
        return new Promise(async (resolve) => {
            const callAxios = await serverAxios();
            callAxios
                .post(`sapGsm/getOfficialProduct`, { materialNumber: materialNumber })
                .then(result => {
                    console.log("result product: ", result)
                    if (result?.data?.isSuccess && typeof result?.data?.data !== "string") dispatch(setOfficialProduct(result.data.data))
                    resolve(result)
                })
                .catch(err => {
                    console.log("err json: ", JSON.stringify(err))
                    console.log("err: ", err)
                    resolve(JSON.stringify(err))
                })
        })
    }
}

export const validateZcwpMaterial = (materialNumber) => {
    return (dispatch) => {
        dispatch(setZcwpDetails(undefined))
        dispatch(setLoadingText("Validating ZCWP"))
        return new Promise(async (resolve) => {
            const callAxios = await serverAxios();
            callAxios.post('sapGsm/getZcwpDetails', { materialNumber }).then(result => {
                console.log("result zcwp details: ", result)
                if (result?.data?.isSuccess && result?.data?.data.length > 0) dispatch(setZcwpDetails(result.data.data))
                resolve(result)
            }).catch(err => {
                console.log("valid zcwp material error: ", err.response)
                resolve(err.response)
            })
        })
    }
}

export const saveDeviation = (deviationData, additionalComponents) => {
    console.log("deviationData: ", deviationData)
    return dispatch => {
        dispatch(setLoadingText("Saving deviation"))
        return new Promise(async (resolve) => {
            const callAxios = await serverAxios();
            callAxios.post(additionalComponents ? 'deviations/saveDeviationAdditionalComponents' : 'deviations/saveDeviation', { data: deviationData }).then(result => {
                console.log("result saveDeviation: ", result)
                if (additionalComponents && result?.data?.isSuccess) {
                    if (deviationData.multipleType === 0 || deviationData.multipleType === 2) {
                        console.log("0 save deviation result.data: ", result.data)
                        let newDeviationIds = result.data.data.map(row => row.data.newDeviationIds)
                        console.log("0 newDeviationIds: ", newDeviationIds)
                        dispatch(setLastSavedDeviations(newDeviationIds))
                    }
                    if (deviationData.multipleType === 1) {
                        console.log("1 save deviation result.data: ", result.data)
                        let newDeviationIds = result.data.data.flatMap(row => row.data.newDeviationIds?.split(","))
                        console.log("1 newDeviationIds: ", newDeviationIds)
                        dispatch(setLastSavedDeviations(newDeviationIds))
                    }
                } else {
                    if (result?.data?.isSuccess) dispatch(setLastSavedDeviations(result?.data?.data?.newDeviationIds.split(",")))
                }
                resolve(result)
            }).catch(err => {
                console.log("Code error: ", err)
                console.log("could not save deviation error: ", err.response)
                resolve(err.response)
            })
        })
    }
}

export const updateDeviation = deviationData => {
    return dispatch => {
        dispatch(setLoadingText("Updating deviation"))
        return new Promise(async (resolve) => {
            const callAxios = await serverAxios();
            callAxios.post('deviations/editDeviation', { data: deviationData }).then(result => {
                console.log("result updateDeviation: ", result)
                //dispatch(setLastSavedDeviations(result.data.data.newDeviationIds.split(",")))
                resolve(result)
            }).catch(err => {
                console.log("could not update deviation error: ", err.response)
                resolve(err.response)
            })
        })
    }
}

export const getAdditionalComponents = (materialNumber, plantCodes) => {
    return dispatch => {
        dispatch(setLoadingText("Retrieving additional components"))
        return new Promise(async (resolve) => {
            const callAxios = await serverAxios();
            callAxios.post('sapGsm/getAdditionalComponents', { materialNumber, plantCodes }).then(result => {
                dispatch(setAdditionalComponents(result?.data?.data))
                resolve(result)
            }).catch(err => {
                console.log("could getAdditionalComponents error: ", err.response)
                resolve(err.response)
            })
        })
    }
}