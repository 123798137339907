import React, { useEffect, useState } from "react";

const OutLineButton = ({ text, isClickable = true, backgroundColor = "#007AFF", onClick }) => {
  // console.log("outlinebutton", isClickable);
  /**
   * Two different styles for clickable and not clickable states
   * */
  const [styles, setStyles] = useState({
    color: "#A0A0A0",
    backgroundColor: "white",
    borderRadius: "5px",
    border: "1px solid",
  });
  useEffect(() => {
    // console.log("useffect outline");
    /**
     * If it is clickable we will have to change background and text color
     */
    if (isClickable) {
      setStyles({
        color: "white",
        backgroundColor: backgroundColor,
        borderRadius: "5px",
        border: "1px solid",
      });
    } else {
      setStyles({
        color: "#A0A0A0",
        backgroundColor: "white",
        borderRadius: "5px",
        border: "1px solid",
      });
    }
  }, [isClickable]);
  return (
    <button disabled={!isClickable} style={styles}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default OutLineButton;
