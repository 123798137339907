// Override http config
module.exports.api = {
    url: 'https://spec-dev-api.cloud.tyson.com/v1/',
};

module.exports.okta = {
    clientId: '0oae8474fmdg6Dzym357',
    url: 'https://login.tyson.com/',
    issuer: 'https://login.tyson.com/oauth2/default',
    redirectUri: 'https://spec-dev-v2.cloud.tyson.com/',
};

module.exports.environment = {
    environment: 'prod'
}

module.exports.version = { number: '1.0' }
