import React from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const BarChartReChart = props => {
    const CandyBar = props => {
        const {
            x: oX,
            y: oY,
            width: oWidth,
            height: oHeight,
            fill
        } = props;

        const x = oX;
        const y = oHeight < 0 ? oY + oHeight : oY;
        const width = oWidth;
        const height = Math.abs(oHeight);

        return (
            <rect fill={fill}
                mask='url(#mask-stripe)'
                x={x}
                y={y}
                width={width}
                height={height} />
        );
    };

    // const CustomizedTick = props =>{
    //   console.log('custom',props)
    //   return(
    //   <div>
    //     <span {...props}>Testing</span>
    //   </div>
    //   )
    // }

    return (
      <div
        style={{
          height: '180px',
          width: props.width,
          fontFamily: 'proxima-nova',
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            layout="vertical"
            width={500}
            height={200}
            data={props.data}
            margin={{
              top: 15,
              right: 25,
              left: 25,
              bottom: 15,
            }}
          >
            <pattern
              id="pattern-stripe"
              width="20"
              height="8"
              patternUnits="userSpaceOnUse"
              patternTransform="rotate(45)"
            >
              <rect
                width="4"
                height="8"
                transform="translate(0,0)"
                fill="white"
              ></rect>
            </pattern>
            <mask id="mask-stripe">
              <rect
                x="0"
                y="0"
                width="100%"
                height="100%"
                fill="url(#pattern-stripe)"
              />
            </mask>
            <CartesianGrid strokeDasharray="0" horizontal={false} />
            <XAxis type="number" interval={0}/>
            <YAxis
              interval={0}
              type="category"
              dataKey="name"
              // tick={<div style={{backgroundColor:'blue',color:'black'}}></div>}
              onClick={(e) => props.onChartClick(e)}
              // tick={{stroke: 'red', strokeWidth: 2}}
              // tick={<CustomizedTick
              // style={{backgroundColor:'blue',color:'white'}}
              // />}
              style={{lightingColor:'red'}}
            >
            </YAxis>
            <Tooltip />
            <Bar dataKey="Pending" stackId="a" shape={<CandyBar fill="#d32f2f" />} onClick={(e) => props.onChartClick(e)}/>
            <Bar dataKey="Accept" onClick={(e) => props.onChartClick(e)} stackId="a" fill="rgba(20, 194, 50, 1)"/>
            <Bar dataKey="Rejected" onClick={(e) => props.onChartClick(e)} stackId="a" fill="rgba(0, 122, 255, 1)" />
            <Bar dataKey="Cancel" onClick={(e) => props.onChartClick(e)} stackId="a" fill="rgba(0, 122, 255, 1)" />
            <Bar dataKey="Expired" onClick={(e) => props.onChartClick(e)} stackId="a" fill="rgba(160, 160, 160, 1)" />
            <Bar dataKey="Edit" onClick={(e) => props.onChartClick(e)} stackId="a" fill="rgb(158, 158, 158, 0.8)" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
}

export default BarChartReChart
