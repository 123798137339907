import {
  Checkbox,
  FormControlLabel,
  Tooltip,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import React from "react";
import { Row, Col } from "react-bootstrap";

/**
 * Requested by: Send to Approver(s): Result CCs:
 */
const MailingListBox = (props) => {
  const { title, distList, individualEmailsList, deviationDetails, setIndividualEmails, setSelectedDL } = props;
  const handleDistributionListSelected = (e, index) => {
    let tempDLSelected = [...distList]
    tempDLSelected[index].selected = e.target.checked
    setSelectedDL(tempDLSelected)
  }
  /**
   * I am just assuming that we will get isSelected from backend
   * This component has been reused for send to approvers and result ccs, so we will have
   * to write the actions from where we use this component but not in this component.
   */
  return (
    <Row noGutters>
      <Col xs={6} className="right">
        <span style={{ paddingRight: "5px" }}>{title}</span>
      </Col>
      <Col xs={6}>
        <span>{"Choose mailing list(s)"}</span>
        {distList.map((mail, index) => (
          <Row key={mail.name + index} style={{ width: "100%" }}>
            <Tooltip title={mail.name}>
              <FormControlLabel
                style={{
                  display: "inline-block",
                  width: "100%",
                  margin: "0px 0px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
                control={<Checkbox color="default" />}
                onChange={deviationDetails?.status === "EDIT" ? (e) => handleDistributionListSelected(e, index) : undefined}
                checked={mail.selected}
                label={<span>{mail.name}</span>}
              />
            </Tooltip>
          </Row>
        ))}
        <span>{"Enter individual email(s)"}</span>
        <TextField
          multiline
          value={individualEmailsList}
          onChange={deviationDetails?.status === "EDIT" || deviationDetails?.status === "REJECTED" ? (e) => setIndividualEmails(e.target.value) : undefined}
          rows={2}
          inputProps={{
            maxLength: 1000,
          }}
          variant="outlined"
          className="inputStyle"
        />
        <Row>
          <span className="fontSizeMobile" style={{ paddingLeft: "14px" }}>
            (Separate multiple email addresses using a comma)
          </span>
        </Row>
      </Col>
    </Row>
  );
};

export default MailingListBox;
