import { withStyles } from '@mui/styles';

import Tooltip from '@mui/material/Tooltip';

export const HtmlToolTip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'rgba(234, 234, 234, 0.7)',
        color: '#65686B',
        maxWidth: 220,
        minWidth: 220,
        fontWeight: 400,
        border: '1px solid #dadde9',
        opacity: 0.5,
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: '5px',
    },
}))(Tooltip);
