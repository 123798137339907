import React, { useState, useEffect } from 'react';
import DeviationModal from "../deviationModal/DeviationModal";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material//Search';

const SearchBar = (props) => {
    const {
        onGetDeviationDetails,
        onGetDistributionLists,
        onValidateDeviationMaterial,
        onUpdateDeviation,
        userInfo,
        refreshData,
        onSaveDeviation,
        onStatusChange,
        searchedReceivedURLParam,
        setSearchedReceivedURLParam,
        deviationId,
        setLoading,
    } = props;
    const [searchValue, setSearchValue] = useState("");
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        if (!searchedReceivedURLParam && deviationId) {
            setSearchValue(deviationId)
        }
    }, [deviationId])

    /**
    * This is used to toggle the modal(dialog)
    */
    useEffect(() => {
        if (!searchedReceivedURLParam && deviationId) {
            onHandleClick()
        }
    }, [searchValue])


    const closeModal = () => {
        setOpenModal(false);
    };


    const onHandleClick = () => {
        console.log("searchValue")
        if (!searchValue) return;
        setSearchedReceivedURLParam(true)
        setOpenModal(true);
    }

    const onHandleChange = (e) => {
        setSearchValue(e.target.value);
    }

    const onEnterKeyPress = (e) => {
        if (e.key === "Enter") {
            //console.log('Enter was pressed', e.target.value);
            setOpenModal(true);
        }
        setSearchValue(e.target.value);
    }

    return (
        <div style={{ maxWidth: '500px', width: '500px' }}>
            {openModal ? <DeviationModal
                open={openModal}
                onClose={closeModal}
                deviationId={searchValue}
                onGetDeviationDetails={onGetDeviationDetails}
                onGetDistributionLists={onGetDistributionLists}
                onValidateDeviationMaterial={onValidateDeviationMaterial}
                onUpdateDeviation={onUpdateDeviation}
                userInfo={userInfo}
                refreshData={refreshData}
                onSaveDeviation={onSaveDeviation}
                currentStep="pendingItems"
                onStatusChange={onStatusChange}
                setLoading={setLoading}
            /> : undefined}

            <OutlinedInput
                fullWidth={true}
                style={{ width: '99%', color: 'black' }}
                type="text"
                className="inputStyle"
                placeholder="Search Dev ID"
                onKeyPress={(e) => onEnterKeyPress(e)}
                onChange={(e) => onHandleChange(e)}
                inputProps={{ style: { fontFamily: 'proxima-nova' } }}
                value={searchValue}
                startAdornment={
                    <InputAdornment position="end">
                        <IconButton aria-label="search" onClick={onHandleClick}>
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                }
            />
        </div>
    );
};
export default SearchBar;
