import React from 'react';
import { Col, Modal, Row, FormGroup } from "react-bootstrap";
import FormControlLabel from '@mui/material/FormControlLabel';

import Checkbox from '@mui/material/Checkbox';
import styles from './validateReplacementModal.module.css'
import GrayCross from '../../assets/gray_cross.svg'
import GrayCheck from '../../assets/gray_check.svg'
import GreenCheck from '../../assets/green_check.svg'
import './modalStyle.css'

const ValidateReplacementModal = props => {

    const { open, onClose, replacements, replacementsValid, acceptedTerms, setAcceptedTerms, includesAZCWP } = props
    console.log("replacements: ", replacements)

    let replacementRows = replacements ? replacements.map((replacement, index) => {
        return (
            replacementsValid ? <Row className={index === 0 ? styles.replacementRowTop : styles.replacementRowOther} key={index}>
                <Col xs={12} sm={12}>
                    <img src={GreenCheck} alt="GreenCheck" />
                    <label className={styles.materialLabel}>&nbsp;- Material ID: {replacement.materialNumber ? replacement.materialNumber : replacement}</label>
                </Col>
            </Row>
                : <>
                    <Row className={index === 0 ? styles.replacementRowTop : styles.replacementRowOther} key={index}>
                        <Col xs={12} sm={12} style={{ paddingRight: "0px !important" }}>
                            <img src={replacement.valid ? GrayCheck : GrayCross} alt="CheckCross" />
                            <label> &nbsp;- Material ID: {replacement.materialNumber}</label>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{ offset: 1, span: 4 }} sm={{ offset: 1, span: 4 }} style={{ paddingRight: "0px" }}>
                            <img src={replacement.validMaterial ? GrayCheck : GrayCross} alt="validMaterial" />
                            <label className={styles.validationsLabel}>&nbsp;Material ID</label>
                        </Col>
                        <Col xs={3} sm={3} >
                            <img src={replacement.validPlant ? GrayCheck : GrayCross} alt="validPlant" />
                            <label className={styles.validationsLabel}>&nbsp;Plant</label>
                        </Col>
                        <Col xs={4} sm={4} >
                            <img src={replacement.validAllergen ? GrayCheck : GrayCross} alt="validAllergen" />
                            <label className={styles.validationsLabel}>&nbsp;Allergen</label>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} sm={6} style={{ paddingRight: "0px" }} >
                            <img src={replacement.compatibleMaterialTypes ? GrayCheck : GrayCross} alt="compatibleMaterialTypes" />
                            <label className={styles.validationsLabel}>&nbsp;Compatible Material</label>
                        </Col>
                        <Col xs={6} sm={6}  >
                            <img src={replacement.validMayContain ? GrayCheck : GrayCross} alt="mayContain" />
                            <label className={styles.validationsLabel}>&nbsp;May Contain(Allergen)</label>
                        </Col>
                    </Row>
                </>
        )
    }) : undefined

    let validationMessage = replacementsValid ?
        <>
            {includesAZCWP ? <Row className={styles.buttonRow}>
                <Col xs={12} sm={12}>
                    <FormGroup style={{ marginBottom: '0.5rem', fontSize: '12px' }}>
                        <FormControlLabel
                            component="legend"
                            control={
                                <Checkbox
                                    onChange={() => setAcceptedTerms(!acceptedTerms)}
                                    color="default"
                                />
                            }
                            checked={acceptedTerms}
                            label="I understand that by creating this deviation I have manually checked to ensure that no new allergen is being introduced to this product"
                        />
                    </FormGroup>
                </Col>
            </Row> : undefined}
            <Row className={styles.buttonRow}>
                <Col xs={{ offset: 2, span: 8 }} sm={{ offset: 2, span: 8 }}>
                    <button
                        className={!includesAZCWP ? styles.continueButton : acceptedTerms ? styles.continueButton : styles.disabledButton}
                        onClick={() => onClose(false)}
                        disabled={includesAZCWP ? !acceptedTerms : false}
                    >
                        {"Continue with Validated"}
                    </button>
                </Col>
            </Row>
            <Row className={styles.buttonRow}>
                <Col xs={{ offset: 3, span: 8 }} sm={{ offset: 3, span: 8 }}>
                    <u onClick={() => onClose(true)} >Edit Replacement(s)</u>
                </Col>
            </Row>
        </> :
        <>
            <Row className={styles.notPassedRow} >
                <Col xs={{ offset: 1, span: 11 }} sm={{ offset: 1, span: 11 }}>
                    <span className={styles.notPassed}>Validation did not pass, unable to continue</span>
                </Col>
            </Row>
            <Row className={styles.editButtonRow}>
                <Col xs={{ offset: 3, span: 8 }} sm={{ offset: 3, span: 8 }}>
                    <button
                        className={styles.editButton}
                        onClick={() => onClose(true)}
                    >
                        Edit Replacement(s)
                    </button>
                </Col>
            </Row>
        </>

    return (
        < >
            <Modal
                show={open}
                onHide={onClose}
                dialogClassName="modal-content-2"
                centered
                keyboard="true" //Close the modal when escape key is pressed
                scrollable
                backdrop="static"
            >
                <Col xs={12} sm={12}>
                    <Row className={styles.titleRow}>
                        <Col xs={{ offset: 2, span: 10 }} sm={{ offset: 2, span: 8 }} style={{ paddingLeft: "0px" }}>
                            <span className={styles.titleLabel}>Replacement(s) validation:</span>
                        </Col>
                    </Row>
                    {replacementRows}
                    {validationMessage}
                </Col>

            </Modal>
        </>
    )
}

export default ValidateReplacementModal
