import * as actionTypes from "../actions/actionTypes"

const initialState = {
    deviationsPending: [],
    deviationsHistory: [],
    favoritePlantsDeviations: [],
    paginationParametersPending: {
        dateLimiter: "",
        rowLimit: 100,
        currentPage: 0,
        pageSize: 5,
    },
    paginationParametersHistory: {
        dateLimiter: "",
        rowLimit: 100,
        currentPage: 0,
        pageSize: 5,
    },
}


export const DeviationGridReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_FAVORITE_PLANTS_DEVIATIONS:
            return {
                ...state,
                favoritePlantsDeviations: action.favoritePlantsDeviations,
            }
        case actionTypes.SET_DEVIATIONS_PENDING:
            return {
                ...state,
                deviationsPending: action.deviationsPending,
            }
        case actionTypes.SET_DEVIATIONS_HISTORY:
            return {
                ...state,
                deviationsHistory: action.deviationsHistory,
            }
        case actionTypes.SET_PAGINATION_PARAMETERS_PENDING:
            return {
                ...state,
                paginationParametersPending: action.paginationParametersPending
            }
        case actionTypes.SET_PAGINATION_PARAMETERS_HISTORY:
            return {
                ...state,
                paginationParametersHistory: action.paginationParametersHistory
            }
        default:
            return state;
    }
}
