import React, { useEffect } from 'react';
import { Container, Col, Row, FormGroup } from 'react-bootstrap';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import BoldText from '../../boldText/BoldText';
import TextInputBox from '../../deviationModal/textInputBox/TextInputBox';

const ContactInfoData = (props) => {
  const {
    userInfo,
    setApproversSelected,
    setCCSelected,
    approversSelected,
    ccSelected,
    setContactInfoVerified,
    individualEmailsList,
    setIndividualEmailsList,
    ccIndividualEmailsList,
    setccIndividualEmailsList, } = props

  const handleDistributionListSelected = (e, type, index) => {
    // type 1 = approvers
    // type 2 = cc
    let tempDLSelected = type === 1 ? [...approversSelected] : [...ccSelected]
    tempDLSelected[index].selected = e.target.checked
    type === 1 ? setApproversSelected(tempDLSelected) : setCCSelected(tempDLSelected)
  }

  const validateEnteredEmailsList = (enteredEmails) => {
    var emails = enteredEmails.split(',');
    var valid = true;
    var regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    for (var i = 0; i < emails.length; i++) {
      if (!regex.test(emails[i].replace(/\s/g, ''))) {
        valid = false;
      }
    }
    return valid;
  };

  const isIndividualEmailsListValid = (individualsInfo) => {
    if (individualsInfo.trim().length == 0) {
      return true;
    }
    return validateEnteredEmailsList(individualsInfo)
  }

  const atLeastOneEmailSelected = (emailsList) => {
    console.log("emailsList: ", emailsList)
    return emailsList.find((email) => email.selected === true);
  }

  useEffect(() => {
    const emailSelected = atLeastOneEmailSelected(approversSelected);
    const isIndividualsValid = isIndividualEmailsListValid(individualEmailsList)
    //Since cc is not mandatory we just validate it if it is filled
    const isCCIndividualsValid = ccIndividualEmailsList.length > 0 ? isIndividualEmailsListValid(ccIndividualEmailsList) : true

    let emailsVerified = false
    if (emailSelected !== undefined) {
      emailsVerified = emailSelected && isIndividualsValid && isCCIndividualsValid
    } else {
      emailsVerified = individualEmailsList.length > 0 && isIndividualsValid && isCCIndividualsValid
    }

    if (emailsVerified) {
      setContactInfoVerified(true)
    } else {
      setContactInfoVerified(false)
    }

  }, [individualEmailsList, ccIndividualEmailsList, approversSelected, ccIndividualEmailsList])

  return (
    <Container fluid style={{ padding: '0px' }}>
      <hr />
      <div style={{ textAlign: 'start' }}>
        <BoldText text="2. Contact Info" />
      </div>
      <Col>
        <TextInputBox readonly title="Requested by:" value={userInfo?.name} />
        <Row>
          <Col className="text-right pr-1 pb-1">
            <span style={{ color: 'red' }}>*</span>
            <FormLabel component="span" style={{ fontSize: "14px", color: "#000" }}>
              Send to Approver(s):
            </FormLabel>
          </Col>
          <Col className="pl-0 pb-1 text-left">
            <FormLabel component="span" style={{ fontSize: "14px", color: "#000" }}>
              Choose mailing list(s)
            </FormLabel>
            <FormGroup>
              {approversSelected.map((approver, index) => (
                <FormControlLabel
                  key={approver}
                  component="legend"
                  control={<Checkbox color="default" />}
                  label={approver.name}
                  checked={approver.selected}
                  onChange={(e) => handleDistributionListSelected(e, 1, index)}
                />
              ))}
            </FormGroup>
            <FormLabel component="span" style={{ fontSize: "14px", color: "#000" }}>
              Or, enter individual email(s)
            </FormLabel>

            <TextField
              multiline
              value={individualEmailsList}
              onChange={(e) => setIndividualEmailsList(e.target.value)}
              rows={2}
              inputProps={{
                maxLength: 1000,
              }}
              variant="outlined"
              className="inputStyle"
            />
            <Row>
              <span className="fontSizeMobile" style={{ paddingLeft: "14px" }}>
                (Separate multiple email addresses using a comma)
              </span>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="text-right pr-1 pb-1">
            <FormLabel component="span" style={{ fontSize: "14px", color: "#000" }}>
              Result CCs:
            </FormLabel>
          </Col>
          <Col className="pl-0 pb-1 text-left">
            <FormLabel component="span" style={{ fontSize: "14px", color: "#000" }}>
              Choose mailing list(s)
            </FormLabel>
            <FormGroup>
              {ccSelected.map((cc, index) => (
                <FormControlLabel
                  component="legend"
                  control={<Checkbox color="default" />}
                  label={cc.name}
                  checked={cc.selected}
                  onChange={(e) => handleDistributionListSelected(e, 2, index)}
                />
              ))}
            </FormGroup>
            <FormLabel component="span" style={{ fontSize: "14px", color: "#000" }}>
              Or, enter individual email(s)
            </FormLabel>

            <TextField
              multiline
              value={ccIndividualEmailsList}
              onChange={(e) => setccIndividualEmailsList(e.target.value)}
              rows={2}
              inputProps={{
                maxLength: 1000,
              }}
              variant="outlined"
              className="inputStyle"
            />
            <Row>
              <span className="fontSizeMobile" style={{ paddingLeft: "14px" }}>
                (Separate multiple email addresses using a comma)
              </span>
            </Row>
          </Col>
        </Row>
      </Col>
    </Container>
  );
};

export default ContactInfoData;
