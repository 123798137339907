const hostnameVal = window.location.hostname;
let envConfig = {};
//this switch function will verify in wich env is the app executing
//and will load all the necessary info for okta login.
console.log('hostnameVal: ', hostnameVal);
switch (hostnameVal) {
  case 'localhost':
    envConfig = require('./test');
    break;
  case 'spec-dev-dev.cloud.tyson.com':
    envConfig = require('./dev');
    break;
  case 'spec-dev-v2-qa.cloud.tyson.com':
    envConfig = require('./qa');
    break;
  case 'spec-dev-v2.cloud.tyson.com':
    envConfig = require('./prod');
    break;
  default:
    envConfig = require('./test');
}
module.exports = envConfig;
