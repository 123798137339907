import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import HistoryReportToggles from './historyReportToggles/HistoryReportToggles';
import BarChartReChart from '../barChartReChart/barChartReChart'

const HistoryReportAccordion = props => {
    const {
        deviations,
        onTabChange,
        tabIndex,
        isMyActionsChecked,
        isApproverChecked,
        onChangeIsApprover,
        onChangeMyActions,
        onDraftClick,
        draftClicked,
        arrowClick,
        currentMonthFiscalYear
    } = props

    const fiscalYearMonthTab = (
    <>
        {tabIndex === 1 || tabIndex === 3 ? <a style={{cursor: "pointer"}} onClick={() => arrowClick(false)}>«</a> : undefined}
        <Tab>{currentMonthFiscalYear}</Tab>
        {tabIndex === 1 || tabIndex === 3 ? <a style={{cursor: "pointer"}} onClick={() => arrowClick(true)}>»</a> : undefined}
    </>
    )

    return (
        <Container fluid>
        <Row>
            <HistoryReportToggles 
            isMyActionsChecked={isMyActionsChecked}
            isApproverChecked={isApproverChecked}
            onChangeIsApprover={onChangeIsApprover}
            onChangeMyActions={onChangeMyActions}
            onDraftClick={onDraftClick}
            draftClicked={draftClicked}
            />
        </Row>
        <Row>
            <Col>
                <Tabs selectedIndex={tabIndex} onSelect={index => onTabChange(index)}>
                    <TabList>
                        <Tab>W</Tab>
                        <Tab>M</Tab>
                        <Tab>Q</Tab>
                        <Tab>Y</Tab>
                        {fiscalYearMonthTab}
                    </TabList>
                    <TabPanel />
                    <TabPanel />
                    <TabPanel />
                    <TabPanel />
                    <TabPanel />
                </Tabs>
                <div >
                    <BarChartReChart 
                        data={deviations} 
                        height={'35vh'} 
                        width={'28vw'} 
                        onChartClick={() => {}} 
                    />
                </div>
            </Col>
        </Row>
        </Container>
    )
}

export default HistoryReportAccordion