const mapStateToProps = state => {
    return {
        deviationsPending: state.deviationGrid.deviationsPending,
        deviationsHistory: state.deviationGrid.deviationsHistory,
        favoritePlantsDeviations: state.deviationGrid.favoritePlantsDeviations,
        paginationParametersPending: state.deviationGrid.paginationParametersPending,
        paginationParametersHistory: state.deviationGrid.paginationParametersHistory,
        headerMaterialPlants: state.newDeviation.headerMaterialPlants,
        officialProductDetails: state.newDeviation.officialProductDetails,
        zwcpDetails: state.newDeviation.zwcpDetails,
        lastSavedDeviations: state.newDeviation.lastSavedDeviations,
        additionalComponents: state.newDeviation.additionalComponents,
        userInfo: state.userInfo.userInfo,
        favoritePlants: state.general.favoritePlants,
        plantsForUser: state.general.plantsForUser,
        loadingText: state.general.loadingText,
    }
}

export default mapStateToProps
