import React, { useEffect, useState } from "react";
import BoldText from "../../boldText/BoldText";
import MailingListBox from "./mailingListBox/MailingListBox";
import TextInputBox from "../textInputBox/TextInputBox";
import swal from "sweetalert2";

const ContactInfo = (props) => {
  /**
   * Requested by: Send to Approver(s): Result CCs:
   */

  const { deviationDetails,
    individualEmailsList,
    ccIndividualEmailsList,
    setccIndividualEmailsList,
    setIndividualEmailsList,
    setApproversSelected,
    approversSelected,
    setCCSelected,
    ccSelected,
  } = props;
  //const [ distributionLists, setDistributionLists] = useState([])

  //console.log("emails approvers", deviationDetails.approversList);
  //console.log("emails cc ", deviationDetails.MailingListBox);
  //console.log("Dist list from contact info ", distList);
  return (
    <div>
      <hr />
      <BoldText text="2. Contact INFO" />
      <TextInputBox
        title="Requested by:"
        value={deviationDetails?.createUser}
        des={"(Requester will receive all notification emails)"}
      />
      {deviationDetails?.status === "ACCEPT" ? <TextInputBox
        title="Approved by:"
        value={deviationDetails?.editUser}
        des={""}
      /> : undefined}
      <MailingListBox
        title="Send to Approver(s):"
        distList={approversSelected}
        individualEmailsList={individualEmailsList}
        setSelectedDL={setApproversSelected}
        setIndividualEmails={setIndividualEmailsList}
        deviationDetails={deviationDetails}
      />
      <MailingListBox
        title="Result CCs:"
        distList={ccSelected}
        individualEmailsList={ccIndividualEmailsList}
        setSelectedDL={setCCSelected}
        setIndividualEmails={setccIndividualEmailsList}
        deviationDetails={deviationDetails}
      />
    </div>
  );
};

export default ContactInfo;
