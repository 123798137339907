import React from 'react';
import Modal from '@mui/material/Modal';
import LinearProgress from '@mui/material/LinearProgress';
import LoadingImg from '../../assets/LoadingAni.gif'
import './styling.css'

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}
const LoadingModal = ({ showLoader, hideLoader, text }) => {
    const close = (e, reason) => {
        if (reason === "backdropClick") return
        hideLoader()
    }

    const body = (
        <div className="paper">
            <img className="img" src={LoadingImg} alt="Loading..." />
            <p style={{ fontFamily: 'proxima-nova', fontSize: '12px' }}>{text ?? "Loading"}</p>
            <LinearProgress />
        </div>
    );

    return (
        <div>
            <Modal
                open={showLoader}
                onClose={close}
                aria-labelledby="loader-modal"
                aria-describedby="loader modal is used to show while fetching data from server"
            >
                {body}
            </Modal>
        </div>
    )
}

export default LoadingModal
