import React from "react";

/**
 * This button can be reused for different colored and text buttons
 * Ex: approve button, view details button etc...
 * Default background color: #65686B
 * Default text: button
 */
const RoundButton = ({
  bgColor = "#65686B",
  text = "button",
  onClick,
  color,
  borderColor,
  border,
  borderStyle,
  borderWidth,
  ...otherProps
}) => {
  const defaultStyles = {
    backgroundColor: bgColor,
    borderRadius: "10%",
    color: color ? color : "white",
    border: border ? border : "none",
    borderColor: borderColor ? borderColor : "white",
    borderStyle: borderStyle ? borderStyle : "none",
    borderWidth: borderWidth ? borderWidth : "0",
    margin: "2px 5px",
    cursor: "pointer",
  };
  const otherStyles = { ...otherProps.style };
  return (
    <button style={Object.assign(defaultStyles, otherStyles)} onClick={onClick}>
      {text}
    </button>
  );
};

export default RoundButton;
