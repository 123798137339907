// Override http config
module.exports.api = {
    url: 'https://spec-dev-api-dev.cloud.tyson.com/v1/',
};

module.exports.okta = {
    clientId: '0oav5345h5G8mmz2S0h7',
    url: 'https://tyson-dev.oktapreview.com/',
    issuer: 'https://tyson-dev.oktapreview.com/oauth2/default',
    redirectUri: 'https://spec-dev-dev.cloud.tyson.com/',
};

module.exports.environment = {
    environment: 'dev'
}

module.exports.version = { number: '1.0' }
