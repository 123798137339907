import MaterialTable, { MTableCell } from "material-table";
import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material//Delete";
import SearchIcon from "@mui/icons-material//Search";
import AddBox from "@mui/icons-material//AddBox";
import ArrowDownward from "@mui/icons-material//ArrowDownward";
import Check from "@mui/icons-material//Check";
import ChevronLeft from "@mui/icons-material//ChevronLeft";
import ChevronRight from "@mui/icons-material//ChevronRight";
import Clear from "@mui/icons-material//Clear";
import Edit from "@mui/icons-material//Edit";
import FilterList from "@mui/icons-material//FilterList";
import FirstPage from "@mui/icons-material//FirstPage";
import LastPage from "@mui/icons-material//LastPage";
import Remove from "@mui/icons-material//Remove";
import SaveAlt from "@mui/icons-material//SaveAlt";
import ViewColumn from "@mui/icons-material//ViewColumn";
import moment from "moment-timezone";
import { Col, Container, Row } from "react-bootstrap";
import RoundButton from "../roundButton/RoundButton";
import FavouriteIcon from "../../assets/favourite_icon.svg";
import DeviationModal from "../deviationModal/DeviationModal";
import RejectDialog from "../deviationModal/rejectDialog/RejectDialog";
import ApproveDialog from "../deviationModal/approveDialog/ApproveDialog";
import CloneDialog from "../deviationModal/cloneDialog/CloneDialog";
import CancelDialog from "../deviationModal/cancelDialog/CancelDialog";
import Paper from '@mui/material/Paper';

const DeviationsTableMobile = (props) => {
  const { deviations,
    onGetDeviationDetails,
    onGetDistributionLists,
    onValidateDeviationMaterial,
    onUpdateDeviation,
    userInfo,
    refreshData,
    onSaveDeviation,
    onStatusChange,
    paginationParametersPending,
    onSetPaginationParametersPending,
    showTitle,
    onModal,
    setLoading,
  } = props

  const convertGMTToCSTTime = (gmtTime) => {
    var m = moment.utc(gmtTime);
    m.tz("America/Chicago");
    return m.format("hh:mm A");
  };
  const convertGMTToCSTDate = (gmtTime) => {
    var m = moment.utc(gmtTime);
    // m.tz("America/Chicago");
    return m.format("YYYY/MM/DD");
  };


  const pageChange = currentPage => {
    if (onModal) return
    onSetPaginationParametersPending({ ...paginationParametersPending, currentPage })
  }

  const rowsPerPageChange = pageSize => {
    if (onModal) return
    onSetPaginationParametersPending({ ...paginationParametersPending, pageSize })
  }

  /**
   * Deviations table columns
   */
  let tableColumns = (data) => {
    return [
      {
        title: (
          <span>
            <img src={FavouriteIcon} alt="" style={{ marginRight: '5px' }} />
            Plant
          </span>
        ),
        render: (data) => (
          <span>
            {data.componentPlantCode
              ? data.componentPlantCode
              : data.processPlantCode}
          </span>
        ),
        type: 'numeric',
      },
      {
        title: 'Dev ID',
        field: 'deviationId',
        type: 'numeric',
        cellStyle: {
          whiteSpace: 'nowrap',
        },
      },
      {
        title: 'Header Material',
        field: 'materialNumber',
        cellStyle: {
          whiteSpace: 'nowrap',
        },
      },
      {
        title: 'Begin-End',
        render: (data) => (
          <span>
            {convertGMTToCSTDate(data.beginDate)} -{' '}
            {convertGMTToCSTDate(data.endDate)}
          </span>
        ),
        cellStyle: {
          whiteSpace: 'nowrap',
        },
      },
      {
        title: 'Type',
        field: 'devType',
        cellStyle: {
          whiteSpace: 'nowrap',
        },
      },
    ];
  }


  /**
   * Need to get deviations data from backend
   */
  /**
   * This is used to toggle the modal(dialog)
   */
  const [openModal, setOpenModal] = useState(false);

  /**
   * Currently selected row data
   */
  const [rowData, setRowData] = useState({});

  /**
   * Handles displaying reject,approve dialogs
   */
  const [showRejectDialog, setShowRejectDialog] = useState(false);
  const [showApproveDialog, setShowApproveDialog] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showCloneDialog, setShowCloneDialog] = useState(false);

  // Hardcoding roles until we have Okta Roles in place
  // role can be "CORP" or "PLANT"
  // const role = "PLANT"

  const onRejectButtonClick = () => {
    console.log("onRejectmain");
    setOpenModal(false);
    setShowRejectDialog(true);
  };

  const onApproveButtonClick = () => {
    console.log("onApprovemain");
    setOpenModal(false);
    setShowApproveDialog(true);
  };

  const onCancelButtonClick = () => {
    console.log("Cancel plant user")
    setOpenModal(false);
    setShowCancelDialog(true);
  }

  const onCloneButtonClick = () => {
    console.log("onClone")
    setOpenModal(false);
    setShowCloneDialog(true);
  }

  /**
   * Used to close the modal by setting openModal to be false.
   * This will get called when the modal is closed from it's own component.
   * So we will have to pass this reference to the parent modal
   */

  const closeModal = () => {
    setOpenModal(false);
  };

  const handleRowClick = (rowData) => {
    /**
     * Let's open the modal by setting openModal to true and before that let's just
     * set the rowData to what we have just selected
     */
    setRowData(rowData);
    setOpenModal(true);
  };

  useEffect(() => {
    console.log("tableMobile deviations: ", deviations)
  }, [deviations])

  return (
    <Container fluid style={{ fontFamily: 'proxima-nova' }}>
      <Col style={{ padding: '0px', alignItems: 'center' }}>
        <Row
          style={{
            marginTop: '0px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {showTitle ? <span className="colorBlue subtitleStyle">Pending</span> : undefined}
        </Row>
        <MaterialTable
          title=""
          columns={tableColumns(deviations)}
          onChangePage={page => pageChange(page)}
          onChangeRowsPerPage={pageSize => rowsPerPageChange(pageSize)}
          data={deviations}
          icons={{
            Search: SearchIcon,
            ResetSearch: DeleteIcon,
            PreviousPage: ChevronLeft,
            NextPage: ChevronRight,
            FirstPage: FirstPage,
            LastPage: LastPage,
            SortArrow: ArrowDownward,
            AddBox: AddBox,
            Clear: Clear,
            Edit: Edit,
            FilterList: FilterList,
            Check: Check,
            Remove: Remove,
            SaveAlt: SaveAlt,
            ViewColumn: ViewColumn,
          }}
          options={{
            search: true,
            actionsColumnIndex: -1,
            headerStyle: {
              margin: '0px',
              padding: '0px',
              textAlign: 'center',
              fontSize: '12px',
            },
            rowStyle: {
              fontSize: '12px',
              margin: '0px 5px',
              padding: '0px',
            },
            tableLayout: 'auto',
          }}
          localization={{
            body: {
              emptyDataSourceMessage: 'No deviations to display',
            },
          }}
          onRowClick={(event, rowData) => {
            handleRowClick(rowData);
          }}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
            Action: (props) => {
              return props.action.buttonText === 'Approve' ? (
                <RoundButton
                  bgColor="#14C232"
                  text="Approve"
                  onClick={(event) => props.action.onClick(event, props.data)}
                ></RoundButton>
              ) : (
                <RoundButton
                  bgColor="#65686B"
                  text="View Detail"
                  onClick={(event) => props.action.onClick(event, props.data)}
                ></RoundButton>
              );
            },
          }}
        ></MaterialTable>
      </Col>
      {openModal ? (
        <DeviationModal
          open={openModal}
          onClose={closeModal}
          deviationId={rowData?.deviationId}
          onGetDeviationDetails={onGetDeviationDetails}
          onGetDistributionLists={onGetDistributionLists}
          onValidateDeviationMaterial={onValidateDeviationMaterial}
          onUpdateDeviation={onUpdateDeviation}
          userInfo={userInfo}
          refreshData={refreshData}
          onSaveDeviation={onSaveDeviation}
          onStatusChange={onStatusChange}
          setLoading={setLoading}
        />
      ) : undefined}
      {showRejectDialog ? (
        <RejectDialog
          show={showRejectDialog}
          setShowRejectDialog={(value) => setShowRejectDialog(value)}
          rowData={rowData}
        />
      ) : undefined}
      {showApproveDialog ? (
        <ApproveDialog
          show={showApproveDialog}
          rowData={rowData}
          setShowApproveDialog={(value) => setShowApproveDialog(value)}
        />
      ) : undefined}
      {showCancelDialog ? (
        <CancelDialog
          show={showCancelDialog}
          rowData={rowData}
          setShowCancelDialog={(value) => setShowCancelDialog(value)}
        />
      ) : undefined}
      {showCloneDialog ? (
        <CloneDialog
          show={showCloneDialog}
          rowData={rowData}
          setShowCloneDialog={(value) => setShowCloneDialog(value)}
        />
      ) : undefined}
    </Container>
  );
};

export default DeviationsTableMobile;
