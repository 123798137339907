import React from 'react';

const ColoredLine = ({ color = "#002554" }) => (
    <hr
        style={{
            color: color,
            backgroundColor: color,
            height: 2.5,
            margin: 2,
            width: 26
        }}
    />
);
const DrawerIcon = () => {
    return (
        <div>
            <ColoredLine />
            <ColoredLine />
            <ColoredLine />
        </div>
    );
};

export default DrawerIcon;
