import * as actionTypes from "./actionTypes"
import serverAxios from "../../services/axios-instance"


export const onSetDeviationsPending = deviations => {
    return {
        type: actionTypes.SET_DEVIATIONS_PENDING,
        deviationsPending: deviations
    }
}

export const onSetDeviationsHistory = deviations => {
    return {
        type: actionTypes.SET_DEVIATIONS_HISTORY,
        deviationsHistory: deviations
    }
}

export const setFavoritePlantsDeviations = deviations => {
    return {
        type: actionTypes.SET_FAVORITE_PLANTS_DEVIATIONS,
        favoritePlantsDeviations: deviations
    }
}

export const setPaginationParametersPending = paginationParametersPending => {
    return {
        type: actionTypes.SET_PAGINATION_PARAMETERS_PENDING,
        paginationParametersPending
    }
}

export const setPaginationParametersHistory = paginationParametersHistory => {
    return {
        type: actionTypes.SET_PAGINATION_PARAMETERS_HISTORY,
        paginationParametersHistory
    }
}

export const setLoadingText = loadingText => {
    return {
        type: actionTypes.SET_LOADING_TEXT,
        loadingText
    }
}

export const getDeviations = (role, plantCodes, user, businessUnit, dateLimiter, rowLimit, addDeviations) => {
    console.log("addDeviations: ", addDeviations)
    return (dispatch, getState) => {
        dispatch(setLoadingText("Retrieving deviations"))
        let pending = [...getState().deviationGrid.deviationsPending]
        let history = [...getState().deviationGrid.deviationsHistory]
        let onlyPending = []
        let onlyPendingNoDuplicates = []
        return new Promise(async (resolve, reject) => {
            const callAxios = await serverAxios();
            callAxios
                .post(`deviations/getAllDeviationsByRole`, { role, plantCodes, dateLimiter, rowLimit, user, businessUnit })
                .then(result => {
                    console.log("result____: ", result)
                    // let count = []
                    // result.data.data.forEach(deviation => {
                    //     let index = count.findIndex(dev => dev.deviationId === deviation.deviationId)
                    //     index === -1 ? count.push({deviationId: deviation.deviationId, count: 1}) : count[index].count += 1
                    // })
                    // console.log("count___: ", count)
                    // let duplicates = count.filter(dev => dev.count > 1)
                    // console.log("duplicatesssss:____  ", duplicates)
                    if (result?.data && result?.data?.isSuccess) {
                        let deviationsNoDuplicates = result.data.data.filter(dev => {
                            let index = history.findIndex(hist => hist.deviationId === dev.deviationId)
                            return index === -1
                        })
                        console.log("deviationsNoDuplicates: ", deviationsNoDuplicates)
                        onlyPending = result.data.data.filter(dev => dev.status === "PENDING")
                        onlyPendingNoDuplicates = deviationsNoDuplicates.filter(dev => dev.status === "PENDING")
                        // First time
                        if (pending.length === 0 && history.length === 0) {
                            console.log("first time loading")
                            dispatch(onSetDeviationsPending(onlyPending))
                            dispatch(onSetDeviationsHistory(result.data.data))
                        } else {
                            pending.push.apply(pending, onlyPendingNoDuplicates)
                            history.push.apply(history, deviationsNoDuplicates)
                            dispatch(onSetDeviationsPending(addDeviations ? pending : onlyPending))
                            dispatch(onSetDeviationsHistory(addDeviations ? history : result.data.data))
                        }
                    }
                    resolve(result)
                })
                .catch(err => {
                    console.log("err: ", err.response)
                    resolve(err.response)
                })
        })
    }
}

export const getFavoritePlantsDeviations = (user, dateLimiter, rowLimit, addDeviations) => {
    return (dispatch, getState) => {
        dispatch(setLoadingText("Retrieving deviations for favorite plants"))
        let favoriteDeviations = [...getState().deviationGrid.favoritePlantsDeviations]
        let onlyPending = []
        return new Promise(async (resolve, reject) => {
            const callAxios = await serverAxios();
            callAxios
                .post(`deviations/getAllDeviationsForFavoritePlants`, { user, dateLimiter, rowLimit })
                .then(result => {
                    console.log("result: ", result)
                    if (result?.data && result?.data?.isSuccess) {
                        onlyPending = result.data.data.filter(dev => dev.status === "PENDING")
                        favoriteDeviations.push.apply(favoriteDeviations, onlyPending)
                        dispatch(setFavoritePlantsDeviations(addDeviations ? favoriteDeviations : onlyPending))
                    }
                    resolve(result)
                })
                .catch(err => {
                    console.log("err: ", err.response)
                    resolve(err.response)
                })
        })
    }
}
