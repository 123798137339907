import * as actionTypes from "./actionTypes"
import serverAxios from "../../services/axios-instance"

const onSetFavoritePlants = favoritePlants => {
    return {
        type: actionTypes.SET_FAVORITE_PLANTS,
        favoritePlants
    }
}

const onSetPlantsForUser = plantsForUser => {
    return {
        type: actionTypes.SET_PLANTS_FOR_USER,
        plantsForUser
    }
}

export const setLoadingText = loadingText => {
    return {
        type: actionTypes.SET_LOADING_TEXT,
        loadingText
    }
}

export const getDeviationDetails = (deviationId) => {
    return dispatch => {
        dispatch(setLoadingText("Retrieving deviation details"))
        return new Promise(async(resolve) => {
            const callAxios = await serverAxios();
            callAxios
                .post('deviations/getDeviation', {
                    deviationId
                })
                .then((result) => {
                    console.log('deviation details', result);
                    resolve(result);
                })
                .catch((error) => {
                    console.log('error.response: ', error.response)
                    resolve(error.response)
                });
        })
    }
}

export const getDistributionLists = plantCodes => {
    console.log("getDL action plantCodes: ", plantCodes)
    return dispatch => {
        dispatch(setLoadingText("Retrieving distribution lists"))
        return new Promise(async(resolve) => {
            const callAxios = await serverAxios();
            callAxios.post('deviations/getDistributionLists', { plantCodes: plantCodes }).then(result => {
                console.log("distribution lists: ", result.data.data)
                resolve(result)
            }).catch(err => {
                console.log("getDistributionLists error: ", err.response)
                resolve(err.response)
            })
        })
    }
}

export const validateDeviationMaterial = (plants, replacementMaterialNumbers, originalMaterialNumbers, isZcwp) => {
    return dispatch => {
        dispatch(setLoadingText("Validating replacements"))
        return new Promise(async(resolve) => {
            const callAxios = await serverAxios();
            callAxios.post('sapGsm/validateDeviationMaterial', { plants, replacementMaterialNumbers, originalMaterialNumbers, isZcwp }).then(result => {
                console.log("valid deviation material: ", result)
                resolve(result)
            }).catch(err => {
                console.log("valid deviation material error: ", err.response)
                resolve(err.response)
            })
        })
    }
}

export const statusChange = deviations => {
    return dispatch => {
        dispatch(setLoadingText("Updating deviation status"))
        return new Promise(async(resolve) => {
            const callAxios = await serverAxios();
            callAxios.post('deviations/statusChange', { deviations }).then(result => {
                console.log("status change: ", result)
                resolve(result)
            }).catch(err => {
                console.log("status change error: ", err.response)
                resolve(err.response)
            })
        })
    }
}

export const getFavoritePlants = () => {
    return (dispatch, getState) => {
        let userEmail = getState().userInfo.userInfo.email
        dispatch(setLoadingText("Retrieving favorite plants"))
        return new Promise(async(resolve) => {
            const callAxios = await serverAxios();
            callAxios.post('general/getFavoritePlants', { userEmail }).then(result => {
                console.log("getFavoritePlants: ", result)
                dispatch(onSetFavoritePlants(result.data.data))
                resolve(result)
            }).catch(err => {
                console.log("getFavoritePlants error: ", err.response)
                resolve(err.response)
            })
        })
    }
}

export const markUnmarkFavoritePlants = plantCode => {
    return (dispatch, getState) => {
        let userEmail = getState().userInfo.userInfo.email
        dispatch(setLoadingText("Marking/Unmarking favorite plant"))
        return new Promise(async(resolve) => {
            const callAxios = await serverAxios();
            callAxios.post('general/markUnmarkFavorite', { userEmail, plantCode }).then(result => {
                console.log("markUnmarkFavorite: ", result)
                resolve(result)
            }).catch(err => {
                console.log("markUnmarkFavorite error: ", err.response)
                resolve(err.response)
            })
        })
    }
}

export const getPlantsForUser = () => {
    return (dispatch, getState) => {
        let userEmail = getState().userInfo.userInfo.email
        let role = getState().userInfo.userInfo.role
        let plantCodes = getState().userInfo.userInfo.plantCodes
        let businessUnit = getState().userInfo.userInfo.businessUnit
        dispatch(setLoadingText("Retrieving plants for user"))
        return new Promise(async(resolve) => {
            const callAxios = await serverAxios();
            callAxios.post('general/getPlantsForUser', { userEmail, plantCodes, role, businessUnit }).then(result => {
                console.log("getPlantsForUser: ", result)
                dispatch(onSetPlantsForUser(result.data.data))
                resolve(result)
            }).catch(err => {
                console.log("getPlantsForUser error: ", err.response)
                resolve(err.response)
            })
        })
    }
}