import axios from "axios";
import config from "../config"
import {
    authClient,
    loginWithRedirect,
    scopes
} from '../services/okta-auth';

//let instance = undefined;

const instance = async () => {
    try {
        const session = authClient.session.get() // Get current session
        const accessToken = await authClient.token
            .getWithoutPrompt({
                scopes: scopes.scopes,
                sessionToken: session
            })
            .then(response => {
                console.log("axios response", response);
                return response.tokens.accessToken.accessToken;
            });

        return axios.create({
            baseURL: config.api.url,
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + accessToken,
            },
        });

    } catch (exception) {
        loginWithRedirect();
        console.log(exception);
        return null;
    }
}

// try{

//             const session = authClient.session.get() // Get current session
//             authClient.token
//             .getWithoutPrompt({
//             scopes: scopes.scopes,
//             sessionToken: session
//             })
//             .then(response => {
//                 const accessToken = response.tokens.accessToken;
//                 instance = axios.create({
//                     baseURL: config.api.url,
//                     headers: {
//                         "Content-Type": "application/json;charset=UTF-8",
//                         "Authorization": "Bearer "+ accessToken,
//                     },
//                 });
//             })

// }catch(exception){
//     loginWithRedirect();
//     console.log(exception)
// }

export default instance;
