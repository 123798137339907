
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Media from 'react-media';
import { connect } from 'react-redux'
import swal from 'sweetalert2'
import { useParams } from "react-router-dom";
import moment from 'moment-timezone'
import Toolbar from '../../components/toolbar/Toolbar';
import DesktopHomePage from '../desktopHomePage/DesktopHomePage';
import MobileHomePage from '../mobileHomePage/MobileHomePage';
import mapStateToProps from './mapStateToProps'
import mapDispatchToProps from './mapDispatchToProps'
import LoadingModal from '../../components/loadingSpinner/loading'
import dateCalculations from './dateCalculations'

const Home = (props) => {
    const { deviationsPending,
        deviationsHistory,
        onGetDeviations,
        headerMaterialPlants,
        onGetAllPlantsForHeaderMaterial,
        onGetOfficialProduct,
        onGetDeviationDetails,
        onValidateZcwpMaterial,
        onValidateDeviationMaterial,
        officialProductDetails,
        zwcpDetails,
        onGetDistributionLists,
        userInfo,
        onSaveDeviation,
        lastSavedDeviations,
        onStatusChange,
        onSetOfficialProduct,
        onSetZcwpDetails,
        onSetLastSavedDeviations,
        favoritePlants,
        onGetFavoritePlants,
        onMarkUnmarkFavoritePlants,
        onSetDeviationsPending_,
        onSetDeviationsHistory_,
        onUpdateDeviation,
        onSetUserInfo,
        paginationParametersPending,
        paginationParametersHistory,
        onSetPaginationParametersPending,
        onSetPaginationParametersHistory,
        favoritePlantsDeviations,
        onGetFavoritePlantsDeviations,
        onGetPlantsForUser,
        plantsForUser,
        additionalComponents,
        onGetAdditionalComponents,
        onSetAdditionalComponents,
        loadingText,
    } = props

    let { deviationId } = useParams()

    const [loading, setLoading] = useState(false)
    const [searchedReceivedURLParam, setSearchedReceivedURLParam] = useState(false)
    const [view, setView] = useState("pending")
    const [showOnlyFavorites, setShowOnlyFavorites] = useState(false)

    const [myDeviationsHistory, setMyDeviationsHistory] = useState([])
    const [filteredDeviationsByStatus, setFilteredDeviationsByStatus] = useState([])
    const [filteredMyDeviationsByStatus, setFilteredMyDeviationsByStatus] = useState([])
    const [filteredDeviationsByStatusData, setFilteredDeviationsByStatusData] = useState([])
    const [filteredMyDeviationsByStatusData, setFilteredMyDeviationsByStatusData] = useState([])
    const [statusClicked, setStatusClicked] = useState("")
    const [currentFiscalYear, setCurrentFiscalYear] = useState("")
    const [currentMonth, setCurrentMonth] = useState("")
    const [currentMonthFiscalYear, setCurrentMonthFiscalYear] = useState("")

    const [dateRange, setDateRange] = useState(undefined)
    const [tabIndex, setTabIndex] = useState(0)

    const lowerCaseExceptFirstLetter = (string) => {
        return string.charAt(0) + string.slice(1).toLowerCase()
    }

    const toggleOnlyFavorites = () => {
        setShowOnlyFavorites(!showOnlyFavorites)
    }

    // const compare = (a, b) => {
    //     if (a.favorite && b.favorite) {
    //         if (a.componentPlantCode ?? a.processPlantCode < b.componentPlantCode ?? b.processPlantCode) {
    //             return 1
    //         }
    //         if (a.componentPlantCode ?? a.processPlantCode > b.componentPlantCode ?? b.processPlantCode) {
    //             return -1
    //         }
    //     }
    //     if (!a.favorite) {
    //         return 1
    //     }
    //     if (!b.favorite) {
    //         return -1
    //     }
    //     return 0
    // }
    const getAllDeviations = () => {
        setLoading(true)
        return new Promise((resolve) => {
            if (showOnlyFavorites && view === "pending") {
                setLoading(true)
                onGetFavoritePlantsDeviations(userInfo?.email, "", 99999999, false).then(res => {
                    console.log("fetching ALL deviations from favorite plants")
                    setLoading(false)
                }).catch(err => {
                    swal.fire({
                        icon: "warning",
                        title: "No data returned from service",
                        timer: 3000,
                    })
                    console.log("fetching deviations from favorite plants error: ", err)
                    setLoading(false)
                })
            } else {
                onGetDeviations(userInfo?.role, userInfo?.plantCodes, userInfo?.email, userInfo?.businessUnit, "", 99999999, false).then(res => {
                    console.log("fetching ALL deviations")
                    setLoading(false)
                    resolve(res)
                }).catch(err => {
                    swal.fire({
                        icon: "warning",
                        title: "No data returned from service",
                        timer: 3000,
                    })
                    console.log("getAllDeviations error: ", err)
                    setLoading(false)
                })
            }

        })
    }

    const refreshData = () => {
        setLoading(true)
        return new Promise((resolve) => {
            if (showOnlyFavorites && view === "pending") {
                setLoading(true)
                onGetFavoritePlantsDeviations(userInfo?.email, "", 100, false).then(res => {
                    console.log("fetching deviations from favorite plants")
                    setLoading(false)
                }).catch(err => {
                    swal.fire({
                        icon: "warning",
                        title: "No data returned from service",
                        timer: 3000,
                    })
                    console.log("fetching deviations from favorite plants error: ", err)
                    setLoading(false)
                })
            } else {
                onGetDeviations(userInfo?.role, userInfo?.plantCodes, userInfo?.email, userInfo?.businessUnit, "", 100, false).then(res => {
                    console.log("Refreshing grid Data")
                    setLoading(false)
                    resolve(res)
                }).catch(err => {
                    swal.fire({
                        icon: "warning",
                        title: "No data returned from service",
                        timer: 3000,
                    })
                    console.log("refreshData error: ", err)
                    setLoading(false)
                })
            }

        })
    }

    const markUnmarkFavoriteAndRefresh = plantCode => {
        console.log("plantCode: ", plantCode)
        setLoading(true)
        onMarkUnmarkFavoritePlants(plantCode).then(res => {
            onGetPlantsForUser().then(res => {
                setLoading(false)
            }).catch(err => {
                swal.fire({
                    icon: "warning",
                    title: "No data returned from service",
                    timer: 3000,
                })
                console.log("onGetPlantsForUser error: ", err)
                setLoading(false)
            })
        }).catch(err => {
            swal.fire({
                icon: "warning",
                title: "No data returned from service",
                timer: 3000,
            })
            console.log("markUnmarkFavoriteAndRefresh error: ", err)
            setLoading(false)
        })
    }

    const splitStringAndLowerCase = (string, separator) => {
        return string.toLowerCase().split(separator)
    }

    const getRoleFromOkta = roles => {
        // Grab only spec dev roles in case the user by any chance has another non-spec dev role in here
        // Hotfix for Okta issue
        // console.log('roles', roles);
        // roles.push('SD admin');
        console.log("roles: ", roles)
        let specDevRoles = roles.filter(role => role.split(" ").includes("SD"))
        // "SD FSQA Admin"
        //let specDevRoles = ["SD FSQA Corporate Prepared User"].filter(role => role.split(" ").includes("SD"))
        // let specDevRoles = ["SD 1500 Plant User"].filter(role => role.split(" ").includes("SD"))
        let businessUnit = ""
        specDevRoles = specDevRoles.map(role => {
            console.log("role: ", role)
            businessUnit = splitStringAndLowerCase(role, " ").includes("poultry") ? "Poultry" : splitStringAndLowerCase(role, " ").includes("prepared") ? "Prepared" : ""
            return splitStringAndLowerCase(role, " ").includes("admin") ? "ADMIN" : splitStringAndLowerCase(role, " ").includes("corporate") ? "CORP" : "PLANT"
        })
        console.log("specDevRoles: ", specDevRoles)
        // After filtering the roles again based on what they contain, we check for roles from higer to lower priority due to the user just be able to have 1 role
        //return "PLANT"
        let role = specDevRoles.includes("ADMIN") || specDevRoles.includes("CORP") ? "ADMIN" : "PLANT"
        return [role, businessUnit]
    }

    const getPlantsFromOktaRole = roles => {
        // Grab only spec dev roles in case the user by any chance has another non-spec dev role in here
        // roles.push("SD 1500 Plant User")
        let specDevRoles = roles.filter(role => role.split(" ").includes("SD"))
        let specDevPlants = []
        specDevPlants = specDevRoles.flatMap(role => {
            return splitStringAndLowerCase(role, " ").filter(word => !isNaN(parseInt(word)))
        })
        console.log("specDevRoles plants: ", specDevPlants)
        return specDevPlants
    }

    const calculateIfOnLastPage = (currentPage, pageSize, deviationsNumber) => {
        let isLastPage = false
        let currentPage_ = currentPage + 1
        isLastPage = (currentPage_ * pageSize) >= deviationsNumber
        return isLastPage
    }

    useEffect(() => {
        if (!deviationsPending || deviationsPending.length === 0) return
        let deviationsLength = showOnlyFavorites ? favoritePlantsDeviations.length : deviationsPending.length
        let lastDeviation = showOnlyFavorites ? favoritePlantsDeviations[deviationsLength - 1] : deviationsHistory[deviationsLength - 1]
        let isLastPage = calculateIfOnLastPage(paginationParametersPending.currentPage, paginationParametersPending.pageSize, deviationsLength)
        if (!lastDeviation) return
        let formattedDate = new Date(lastDeviation.createdAt)
        formattedDate = formattedDate.getFullYear() + "-" + (formattedDate.getMonth() + 1) + "-" + formattedDate.getDate() + " " + formattedDate.getHours() + ":" + formattedDate.getMinutes() + ":" + formattedDate.getSeconds()
        console.log("formattedDate: ", formattedDate)
        if (!isLastPage) return

        // Retrieve next 100 records for favorite plants
        if (showOnlyFavorites) {
            setLoading(true)
            onGetFavoritePlantsDeviations(userInfo?.email, formattedDate, 100, true).then(res => {
                console.log("fetching next deviations from favorite plants")
                setLoading(false)
            }).catch(err => {
                swal.fire({
                    icon: "warning",
                    title: "No data returned from service",
                    timer: 3000,
                })
                console.log("fetching next deviations from favorite plants error: ", err)
                setLoading(false)
            })
        }  // Retrieve next 100 records for all plants
        else {
            setLoading(true)
            onGetDeviations(userInfo?.role, userInfo?.plantCodes, userInfo?.email, userInfo?.businessUnit, formattedDate, 100, true).then(res => {
                console.log("pulling next grid Data")
                setLoading(false)
            }).catch(err => {
                swal.fire({
                    icon: "warning",
                    title: "No data returned from service",
                    timer: 3000,
                })
                console.log("refreshData error: ", err)
                setLoading(false)
            })
        }
    }, [paginationParametersPending])

    useEffect(() => {
        if (!deviationsHistory || deviationsHistory.length === 0) return
        let deviationsLength = deviationsHistory.length
        let lastDeviation = deviationsHistory[deviationsHistory.length - 1]
        let isLastPage = calculateIfOnLastPage(paginationParametersHistory.currentPage, paginationParametersHistory.pageSize, deviationsLength)
        if (!lastDeviation) return
        let formattedDate = new Date(lastDeviation.createdAt)
        formattedDate = formattedDate.getFullYear() + "-" + (formattedDate.getMonth() + 1) + "-" + formattedDate.getDate() + " " + formattedDate.getHours() + ":" + formattedDate.getMinutes() + ":" + formattedDate.getSeconds()
        console.log("formattedDate: ", formattedDate)
        if (!isLastPage) return
        // Retrieve next 100 records for all plants
        setLoading(true)
        onGetDeviations(userInfo?.role, userInfo?.plantCodes, userInfo?.email, userInfo?.businessUnit, formattedDate, 100, true).then(res => {
            console.log("pulling next grid Data")
            setLoading(false)
        }).catch(err => {
            swal.fire({
                icon: "warning",
                title: "No data returned from service",
                timer: 3000,
            })
            console.log("refreshData error: ", err)
            setLoading(false)
        })
    }, [paginationParametersHistory])

    useEffect(() => {
        if (!userInfo) return
        if (!deviationsPending || deviationsPending.length === 0) return
        if (showOnlyFavorites) {
            if (favoritePlantsDeviations && favoritePlantsDeviations.length > 0) return
            setLoading(true)
            onGetFavoritePlantsDeviations(userInfo.email, "", 100, false).then(res => {
                console.log("fetching deviations from favorite plants")
                setLoading(false)
            }).catch(err => {
                swal.fire({
                    icon: "warning",
                    title: "No data returned from service",
                    timer: 3000,
                })
                console.log("fetching deviations from favorite plants error: ", err)
                setLoading(false)
            })
        } else {
            if (deviationsPending && deviationsPending.length > 0) return
            refreshData()
        }
    }, [showOnlyFavorites])

    useEffect(() => {
        console.log("userInfo: ", userInfo)
        if (!userInfo) return
        if ((userInfo.role !== "ADMIN" && userInfo.role !== "CORP") && (!userInfo.plantCodes || userInfo.plantCodes.length === 0)) return
        refreshData()
        onGetPlantsForUser()
    }, [userInfo])

    const convertGMTToCSTDate = (gmtTime, myFormat) => {
        let m = moment.utc(gmtTime);
        // m.tz("America/Chicago");
        return m.format(myFormat);
    };

    // deviationsHistory.createdAt for date filter
    // Filtering by status

    const fillDeviationsByStatus = ([deviations, firstDay, lastDay], myActions) => {

        let firstDayString = convertGMTToCSTDate(firstDay, "MM/DD/YYYY")
        let lastDayString = convertGMTToCSTDate(lastDay, "MM/DD/YYYY")
        setDateRange(firstDayString + " to " + lastDayString)
        let devByStatus = []

        if (!deviations || deviations.length === 0) {
            devByStatus = [{ name: "Pending", Pending: 0, data: [] }, { name: "Expired", Expired: 0, data: [] }, { name: "Edit", Expired: 0, data: [] }]
            myActions ? setFilteredMyDeviationsByStatus(devByStatus) : setFilteredDeviationsByStatus(devByStatus)
            return
        }
        deviations.forEach(dev => {
            if (devByStatus.filter(dv => dv.name === lowerCaseExceptFirstLetter(dev.status ?? "Null")).length === 0) devByStatus.push({ name: lowerCaseExceptFirstLetter(dev.status ?? "Null"), [lowerCaseExceptFirstLetter(dev.status ?? "Null")]: 0, data: [] })
            let statusIndex = devByStatus.findIndex(status => status.name === lowerCaseExceptFirstLetter(dev.status ?? "Null"))
            if (statusIndex !== -1) devByStatus[statusIndex].data.push(dev)
        })
        // console.log("devByStatussss: ", devByStatus)
        devByStatus.forEach(dev => {
            let filteredArray = deviations.filter(dv => lowerCaseExceptFirstLetter(dv.status ?? "Null") === dev.name)
            dev[dev.name] = filteredArray.length
        })

        myActions ? setFilteredMyDeviationsByStatus(devByStatus) : setFilteredDeviationsByStatus(devByStatus)
    }

    const filterDeviationsByStatus = (tempDeviations, myActions) => {
        // deviations.createdAt for date filter
        // 0 = W (Week)
        // 1 = M (Month)
        // 2 = Q (Quarter)
        // 3 = Y (Year)
        // 4 = ???
        switch (tabIndex) {
            case 0:
                fillDeviationsByStatus(dateCalculations.deviationsByWeek(tempDeviations), myActions)
                break;
            case 1:
                fillDeviationsByStatus(dateCalculations.deviationsByMonth(tempDeviations), myActions)
                break;
            case 2:
                fillDeviationsByStatus(dateCalculations.deviationsByQuarter(tempDeviations), myActions)
                break;
            case 3:
                fillDeviationsByStatus(dateCalculations.deviationsByYear(tempDeviations), myActions)
                break;
            case 4:
                fillDeviationsByStatus(dateCalculations.deviationsByYearMonth(tempDeviations, currentFiscalYear, currentMonth), myActions)
                break;
            default:
                break;
        }
    }

    const onTabChange = index => {
        setTabIndex(index)
    }

    const arrowClick = next => {
        if (tabIndex === 1) {
            setCurrentMonth(dateCalculations.nextPrevMonth(next, currentMonth))
        }

        if (tabIndex === 3) {
            setCurrentFiscalYear(dateCalculations.nextPrevFiscalYear(next, currentFiscalYear))
        }
    }

    useEffect(() => {
        const tempDeviations = [...deviationsHistory]
        if (!tempDeviations || tempDeviations.length === 0) return
        let myDevs = deviationsHistory.filter(dev => dev.userEmail.toLowerCase() === userInfo?.email.toLowerCase())
        setMyDeviationsHistory(myDevs)
        console.log("tempDeviations line 84: ", tempDeviations)
        console.log("myDevs: ", myDevs)
        filterDeviationsByStatus(tempDeviations, false)
        filterDeviationsByStatus(myDevs, true)
        console.log("tabIndex: ", tabIndex)
    }, [deviationsHistory, tabIndex])

    useEffect(() => {
        console.log("statusClicked: ", statusClicked)
        if (statusClicked === "") return
        if (!filteredDeviationsByStatus || filteredDeviationsByStatus.length === 0) return
        console.log("filteredDeviationsByStatus: ", filteredDeviationsByStatus)
        let statusIndex = filteredDeviationsByStatus.findIndex(status => status.name === statusClicked)
        if (statusIndex !== -1) {
            setFilteredDeviationsByStatusData(filteredDeviationsByStatus[statusIndex].data)
        }
    }, [statusClicked])

    useEffect(() => {
        console.log("statusClicked: ", statusClicked)
        if (statusClicked === "") return
        if (!filteredMyDeviationsByStatus || filteredMyDeviationsByStatus.length === 0) return
        console.log("filteredMyDeviationsByStatus: ", filteredMyDeviationsByStatus)
        let myStatusIndex = filteredMyDeviationsByStatus.findIndex(status => status.name === statusClicked)
        if (myStatusIndex !== -1) {
            setFilteredMyDeviationsByStatusData(filteredMyDeviationsByStatus[myStatusIndex].data)
        }
    }, [statusClicked])

    useEffect(() => {
        console.log("window.sessionStorage.getItem(user)", window.sessionStorage.getItem("user"))
        let userInfo = undefined
        let userItem = undefined
        let role = ""
        let businessUnit = ""
        let plantCodes = []
        try {
            //Hotfix for Okta issue
            userItem = JSON.parse(window.sessionStorage.getItem("user"))
            console.log("userItem: ", userItem)
            if (!userItem.SpecDevSPA || userItem.SpecDevSPA.length === 0) {
                swal.fire({
                    icon: "warning",
                    title: "The user has no spec dev roles assigned"
                })
                onSetUserInfo(userInfo)
                return
            }
            [role, businessUnit] = getRoleFromOkta(userItem.SpecDevSPA)
            plantCodes = getPlantsFromOktaRole(userItem.SpecDevSPA)
            userInfo = {
                name: userItem.name,
                email: userItem.email,
                role,
                plantCodes,
                businessUnit
            }

        } catch (err) {
            console.log("error parsing userInfo: ", err)
        }
        console.log("userInfo___: ", userInfo)
        onSetUserInfo(userInfo)
    }, [window.sessionStorage.getItem("user")])

    useEffect(() => {
        setCurrentFiscalYear(dateCalculations.getCurrentFiscalYear)
        setCurrentMonth(dateCalculations.getCurrentMonth)
    }, [])

    useEffect(() => {
        if (currentMonth === "" || currentFiscalYear === "") return
        setCurrentMonthFiscalYear(dateCalculations.createDateFiscalYearAndMonthString(currentFiscalYear, currentMonth))
    }, [currentFiscalYear, currentMonth])


    return (
        <Container fluid>
            {/**
         * Media is a react-media module used to handle responsiveness of the application
         * It can be used to render different screens for different dimension devices
         * ex: screen1 for desktop and screen2 for mobile and so on.
         * We can either use query or queries....Refer more details in the npm registry
         */}
            <Col>
                <Row>
                    <Toolbar
                        onGetDeviationDetails={onGetDeviationDetails}
                        onGetDistributionLists={onGetDistributionLists}
                        onUpdateDeviation={onUpdateDeviation}
                        onValidateDeviationMaterial={onValidateDeviationMaterial}
                        userInfo={userInfo}
                        onGetDeviations={onGetDeviations}
                        onGetFavoritePlants={onGetFavoritePlants}
                        refreshData={refreshData}
                        searchedReceivedURLParam={searchedReceivedURLParam}
                        setSearchedReceivedURLParam={setSearchedReceivedURLParam}
                        deviationId={deviationId}
                        onStatusChange={onStatusChange}
                        plantsForUser={plantsForUser}
                        markUnmarkFavoriteAndRefresh={markUnmarkFavoriteAndRefresh}
                        setLoading={setLoading}
                    />
                    <Media
                        queries={{
                            mobile: '(max-width:425px)',
                            desktop: '(min-width:426px)', //not specifying any max width...
                        }}
                    >
                        {(matches) => (
                            <>
                                {matches.desktop && (
                                    <DesktopHomePage
                                        showOnlyFavorites={showOnlyFavorites}
                                        toggleOnlyFavorites={toggleOnlyFavorites}
                                        deviationsPending={showOnlyFavorites ? favoritePlantsDeviations : deviationsPending}
                                        deviationsHistory={deviationsHistory}
                                        myDeviationsHistory={myDeviationsHistory}
                                        headerMaterialPlants={headerMaterialPlants}
                                        onGetAllPlantsForHeaderMaterial={onGetAllPlantsForHeaderMaterial}
                                        onGetOfficialProduct={onGetOfficialProduct}
                                        onGetDeviationDetails={onGetDeviationDetails}
                                        onValidateZcwpMaterial={onValidateZcwpMaterial}
                                        onValidateDeviationMaterial={onValidateDeviationMaterial}
                                        officialProductDetails={officialProductDetails}
                                        zwcpDetails={zwcpDetails}
                                        onGetDistributionLists={onGetDistributionLists}
                                        userInfo={userInfo}
                                        onSaveDeviation={onSaveDeviation}
                                        lastSavedDeviations={lastSavedDeviations}
                                        onStatusChange={onStatusChange}
                                        onSetOfficialProduct={onSetOfficialProduct}
                                        onSetZcwpDetails={onSetZcwpDetails}
                                        onSetLastSavedDeviations={onSetLastSavedDeviations}
                                        onMarkUnmarkFavoritePlants={onMarkUnmarkFavoritePlants}
                                        onUpdateDeviation={onUpdateDeviation}
                                        refreshData={refreshData}
                                        getAllDeviations={getAllDeviations}
                                        paginationParametersPending={paginationParametersPending}
                                        onSetPaginationParametersPending={onSetPaginationParametersPending}
                                        paginationParametersHistory={paginationParametersHistory}
                                        onSetPaginationParametersHistory={onSetPaginationParametersHistory}
                                        setView={setView}
                                        additionalComponents={additionalComponents}
                                        onGetAdditionalComponents={onGetAdditionalComponents}
                                        onSetAdditionalComponents={onSetAdditionalComponents}
                                        filteredDeviationsByStatus={filteredDeviationsByStatus}
                                        filteredMyDeviationsByStatus={filteredMyDeviationsByStatus}
                                        tabIndex={tabIndex}
                                        onTabChange={onTabChange}
                                        loading={loading}
                                        setLoading={setLoading}
                                        arrowClick={arrowClick}
                                        currentMonthFiscalYear={currentMonthFiscalYear}
                                    />
                                )}
                                {matches.mobile && (
                                    <MobileHomePage
                                        showOnlyFavorites={showOnlyFavorites}
                                        toggleOnlyFavorites={toggleOnlyFavorites}
                                        deviationsPending={showOnlyFavorites ? favoritePlantsDeviations : deviationsPending}
                                        deviationsHistory={deviationsHistory}
                                        myDeviationsHistory={myDeviationsHistory}
                                        onGetDeviations={onGetDeviations}
                                        headerMaterialPlants={headerMaterialPlants}
                                        onGetAllPlantsForHeaderMaterial={onGetAllPlantsForHeaderMaterial}
                                        onGetOfficialProduct={onGetOfficialProduct}
                                        onGetDeviationDetails={onGetDeviationDetails}
                                        onValidateZcwpMaterial={onValidateZcwpMaterial}
                                        onValidateDeviationMaterial={onValidateDeviationMaterial}
                                        officialProductDetails={officialProductDetails}
                                        zwcpDetails={zwcpDetails}
                                        onGetDistributionLists={onGetDistributionLists}
                                        userInfo={userInfo}
                                        onSaveDeviation={onSaveDeviation}
                                        lastSavedDeviations={lastSavedDeviations}
                                        onStatusChange={onStatusChange}
                                        onSetOfficialProduct={onSetOfficialProduct}
                                        onSetZcwpDetails={onSetZcwpDetails}
                                        onSetLastSavedDeviations={onSetLastSavedDeviations}
                                        onGetFavoritePlants={onGetFavoritePlants}
                                        onUpdateDeviation={onUpdateDeviation}
                                        refreshData={refreshData}
                                        getAllDeviations={getAllDeviations}
                                        paginationParametersPending={paginationParametersPending}
                                        onSetPaginationParametersPending={onSetPaginationParametersPending}
                                        paginationParametersHistory={paginationParametersHistory}
                                        onSetPaginationParametersHistory={onSetPaginationParametersHistory}
                                        additionalComponents={additionalComponents}
                                        onGetAdditionalComponents={onGetAdditionalComponents}
                                        onSetAdditionalComponents={onSetAdditionalComponents}
                                        filteredDeviationsByStatus={filteredDeviationsByStatus}
                                        filteredDeviationsByStatusData={filteredDeviationsByStatusData}
                                        setFilteredDeviationsByStatusData={setFilteredDeviationsByStatusData}
                                        filteredMyDeviationsByStatus={filteredMyDeviationsByStatus}
                                        filteredMyDeviationsByStatusData={filteredMyDeviationsByStatusData}
                                        setFilteredMyDeviationsByStatusData={setFilteredMyDeviationsByStatusData}
                                        tabIndex={tabIndex}
                                        onTabChange={onTabChange}
                                        dateRange={dateRange}
                                        statusClicked={statusClicked}
                                        setStatusClicked={setStatusClicked}
                                        loading={loading}
                                        setLoading={setLoading}
                                        arrowClick={arrowClick}
                                        currentMonthFiscalYear={currentMonthFiscalYear}
                                    />
                                )}
                                {loading ? (
                                    <LoadingModal
                                        showLoader={loading}
                                        hideLoader={setLoading}
                                        text={loadingText}
                                    />
                                ) : null}
                            </>
                        )}
                    </Media>
                </Row>
            </Col>
        </Container>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
