import * as actionTypes from "../actions/actionTypes"

const initialState = {
    headerMaterialPlants: [],
    officialProductDetails: undefined,
    zwcpDetails: undefined,
    lastSavedDeviations: [],
    additionalComponents: []
}

export const NewDeviationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ALL_PLANTS_FOR_HEADER_MATERIAL:
            return {
                ...state,
                headerMaterialPlants: action.headerMaterialPlants
            }
        case actionTypes.SET_OFFICIAL_PRODUCT:
            return {
                ...state,
                officialProductDetails: action.officialProduct
            }
        case actionTypes.SET_ZCWP_DETAILS:
            return {
                ...state,
                zwcpDetails: action.zcwpDetails
            }
        case actionTypes.SET_LAST_SAVED_DEVIATIONS:
            return {
                ...state,
                lastSavedDeviations: action.lastSavedDeviations
            }
        case actionTypes.SET_ADDITIONAL_COMPONENTS:
            return {
                ...state,
                additionalComponents: action.additionalComponents
            }
        default:
            return state;
    }
}
