import React from 'react'
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import StarIcon from '@mui/icons-material//Star';
import styles from './leftMenu.module.css'

const Main = props => {

    const favPlantsLink =
        <div>
            <StarIcon style={{ color: '#ffb400', marginRight: '2px', marginBottom: '2px' }} />
            <a onClick={() => props.toggleMenu("favoritePlants", true)}>
                Favorite plants
            </a>
        </div>

    return (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={undefined}
            onKeyDown={undefined}
        >
            <List>
                <ListItem key={1} classes={{ root: styles.noTopBotPadding }}>
                    <ListItemText classes={{ primary: styles.mainText, root: styles.mainText }} primary={"Hello, " + props.user + "!"} />
                </ListItem>
                <ListItem key={2} classes={{ root: styles.noTopBotPadding }}>
                    <ListItemText classes={{ primary: styles.favoritePlants }} primary={favPlantsLink} />
                </ListItem>
            </List>
            <div className={styles.divToBottom}>
                <List>
                    <ListItem key={1} classes={{ root: styles.noTopBotPadding }}>
                        <ListItemText classes={{ primary: styles.otherText }} primary={"About"} />
                    </ListItem>
                    <ListItem key={2} >
                        <ListItemText classes={{ primary: styles.otherText }} primary={"Technical Support"} />
                    </ListItem>
                    <ListItem key={3} classes={{ root: styles.noTopBotPadding }}>
                        <ListItemText classes={{ primary: styles.otherText }} primary={"v1.0"} />
                    </ListItem>
                </List>
            </div>
        </Box>
    )
}

export default Main