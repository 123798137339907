import React from 'react';
import './EditableText.css';

const EditableText = (props) => {
  const { deviationType, text, style, onChange, disabled = false } = props;
  return (
    <input
      className="editable-text"
      style={style}
      // width="auto"
      disabled={disabled}
      type={deviationType === "component" || deviationType === "relabel" ? "tel" : ""}
      maxLength={deviationType === "component" || deviationType === "relabel" ? "11" : "100"}
      onChange={onChange}
      value={text}
    ></input>
  );
};

export default EditableText;
