import * as actionTypes from "../actions/actionTypes"

const initialState = {
    selectedDistributionLists: [],
    favoritePlants: undefined,
    plantsForUser: [],
    loadingText: "Loading"
}

export const GeneralDeviationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SELECTED_DISTRIBUTION_LISTS:
            return {
                ...state,
                selectedDistributionLists: action.distributionLists
            }
        case actionTypes.SET_FAVORITE_PLANTS:
            return {
                ...state,
                favoritePlants: action.favoritePlants
            }
        case actionTypes.SET_PLANTS_FOR_USER:
            return {
                ...state,
                plantsForUser: action.plantsForUser
            }
        case actionTypes.SET_LOADING_TEXT:
            return {
                ...state,
                loadingText: action.loadingText
            }
        default:
            return state;
    }
}
