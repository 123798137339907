// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paper {
    position: absolute;
    border-radius: 5%;
    width: 280px;
    height: 300px;
    background-color: white;
    text-align: center;
    box-shadow: .5px .5px gray;
    padding: 16px 32px 24px;
    top: 35%;
    left: 43%;
}

.img {
    width: 160px;
    height: 200px;
}

.linear {
    width: 100%;
}

.linear>*+* {
    margin-top: 16px;
    /* theme.spacing(2) */
}`, "",{"version":3,"sources":["webpack://./src/components/loadingSpinner/styling.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,0BAA0B;IAC1B,uBAAuB;IACvB,QAAQ;IACR,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,qBAAqB;AACzB","sourcesContent":[".paper {\n    position: absolute;\n    border-radius: 5%;\n    width: 280px;\n    height: 300px;\n    background-color: white;\n    text-align: center;\n    box-shadow: .5px .5px gray;\n    padding: 16px 32px 24px;\n    top: 35%;\n    left: 43%;\n}\n\n.img {\n    width: 160px;\n    height: 200px;\n}\n\n.linear {\n    width: 100%;\n}\n\n.linear>*+* {\n    margin-top: 16px;\n    /* theme.spacing(2) */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
