import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import MaterialTable from 'material-table';
import moment from "moment-timezone";
import DeleteIcon from '@mui/icons-material//Delete';
import SearchIcon from '@mui/icons-material//Search';
import AddBox from '@mui/icons-material//AddBox';
import ArrowDownward from '@mui/icons-material//ArrowDownward';
import Check from '@mui/icons-material//Check';
import ChevronLeft from '@mui/icons-material//ChevronLeft';
import ChevronRight from '@mui/icons-material//ChevronRight';
import Clear from '@mui/icons-material//Clear';
import Edit from '@mui/icons-material//Edit';
import FilterList from '@mui/icons-material//FilterList';
import FirstPage from '@mui/icons-material//FirstPage';
import LastPage from '@mui/icons-material//LastPage';
import Remove from '@mui/icons-material//Remove';
import SaveAlt from '@mui/icons-material//SaveAlt';
import ViewColumn from '@mui/icons-material//ViewColumn';
import FavouriteIcon from '../../../assets/favourite_icon.svg';

const ChooseDeviationsModal = (props) => {
  const { deviations, onCloseModal, onDeviationSelected } = props;
  const [showModal, setShowModal] = useState(true)
  const convertGMTToCSTTime = (gmtTime) => {
    let m = moment.utc(gmtTime);
    m.tz("America/Chicago");
    return m.format("hh:mm A");
  };
  const convertGMTToCSTDate = (gmtTime) => {
    let m = moment.utc(gmtTime);
    // m.tz("America/Chicago");
    return m.format("YYYY/MM/DD");
  };
  /**
   * Deviations table columns
   */
  let tableColumns = (data) => {
    return [
      {
        title: (
          <span>
            <img src={FavouriteIcon} alt="" style={{ marginRight: '5px' }} />
            Plant
          </span>
        ),
        //field: data.componentPlantCode ? "componentPlantCode" : "processPlantCode",
        render: (data) => (
          <span>
            {data.componentPlantCode
              ? data.componentPlantCode
              : data.processPlantCode}
          </span>
        ),
        type: 'string',
        cellStyle: {
          textAlign: 'left',
        },
      },
      {
        title: 'Dev ID',
        field: 'deviationId',
        type: 'numeric',
        cellStyle: {
          whiteSpace: 'nowrap',
        },
      },
      {
        title: 'Header Material',
        field: 'materialNumber',
        // width: 100,
        cellStyle: {
          whiteSpace: 'nowrap',
        },
      },
      {
        title: 'Begin-End',
        // width: 200,
        render: (data) => (
          <span>
            {convertGMTToCSTDate(data.beginDate)} -{' '}
            {convertGMTToCSTDate(data.endDate)}
          </span>
        ),
        cellStyle: {
          whiteSpace: 'nowrap',
        },
      },
      {
        title: 'Type',
        field: 'devType',
        // width: 100,
        cellStyle: {
          whiteSpace: 'nowrap',
        },
      },
    ];
  }

  const handleRowClick = (rowData) => {
    console.log('row clicked', rowData)
    onDeviationSelected(rowData)
    setShowModal(false)
  };

  const onModalClosed = () => {
    onCloseModal()
    setShowModal(false)
  }

  return (
    <Modal
      show={showModal}
      centered
      onHide={() => onModalClosed()}
    >
      <MaterialTable
        title=""
        columns={tableColumns(deviations)}
        data={deviations}
        icons={{
          Search: SearchIcon,
          ResetSearch: DeleteIcon,
          PreviousPage: ChevronLeft,
          NextPage: ChevronRight,
          FirstPage: FirstPage,
          LastPage: LastPage,
          SortArrow: ArrowDownward,
          AddBox: AddBox,
          Clear: Clear,
          Edit: Edit,
          FilterList: FilterList,
          Check: Check,
          Remove: Remove,
          SaveAlt: SaveAlt,
          ViewColumn: ViewColumn,
        }}
        options={{
          search: true,
          actionsColumnIndex: -1,
          headerStyle: {
            margin: '0px',
            padding: '0px',
            // justifyContent:'center',
            // alignItems:'center',
            textAlign: 'center',
            fontSize: '10px',
          },
          rowStyle: {
            // height:'10%',
            fontSize: '12px',
            margin: '0px 5px',
            padding: '0px',
            height: '20px'
          },
          tableLayout: 'auto',
        }}
        localization={{
          body: {
            emptyDataSourceMessage: 'No deviations to display',
          },
        }}
        onRowClick={(event, rowData) => {
          handleRowClick(rowData);
        }}
      ></MaterialTable>
    </Modal>
  );
};

export default ChooseDeviationsModal;
