import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import HistoryMobileToggles from '../../components/historyMobileToggles/HistoryMobileToggles';
import BarChartReChart from '../../components/barChartReChart/barChartReChart'
import DeviationsTableMobile from '../../components/mobile/DeviationsTableMobile';
import HistoryDeviationsTable from '../../components/historyDeviationsTable/HistoryDeviationsTable'
import ModalTitle from './ModalTitle'
import 'react-tabs/style/react-tabs.css';
import './style.css'

const HistoryMobile = (props) => {
    const { userInfo,
        onGetDeviationDetails,
        onGetDistributionLists,
        onValidateDeviationMaterial,
        onUpdateDeviation,
        refreshData,
        onSaveDeviation,
        onStatusChange,
        paginationParametersPending,
        onSetPaginationParametersPending,
        filteredDeviationsByStatus,
        filteredMyDeviationsByStatus,
        filteredDeviationsByStatusData,
        setFilteredDeviationsByStatusData,
        filteredMyDeviationsByStatusData,
        setFilteredMyDeviationsByStatusData,
        tabIndex,
        onTabChange,
        statusClicked,
        setStatusClicked,
        dateRange,
        paginationParametersHistory,
        onSetPaginationParametersHistory,
        deviations,
        myDeviations,
        setLoading,
        arrowClick,
        currentMonthFiscalYear,
    } = props;

    const [ isChartView, setIsChartView ] = useState(true)
    const [ isMyactionsChecked, setIsMyActionsChecked ] = useState(false)
    const [showChooseDeviationsModal, setShowChooseDeviationsModal] = useState(false)
    const [draftClicked, setDraftClicked] = useState(false)
    const [draftDeviations, setDraftDeviations] = useState([])
    const [draftDeviationsByStatus, setDraftDeviationsByStatus] = useState([])
    const [draftDeviationsByStatusData, setDraftDeviationsByStatusData] = useState([])
    const [approverHistory, setApproverHistory] = useState([])
    const [approverHistoryByStatus, setApproverHistoryByStatus] = useState([])
    const [approverHistoryByStatusData, setApproverHistoryByStatusData] = useState([])
    const [requesterHistory, setRequesterHistory] = useState([])
    const [requesterHistoryByStatus, setRequesterHistoryByStatus] = useState([])
    const [requesterHistoryByStatusData, setRequesterHistoryByStatusData] = useState([])
    let isAdmin = userInfo?.role === "CORP" || userInfo?.role === "ADMIN"

    const onChangeMyActions = e => {
        setIsMyActionsChecked(!e.target.checked)
    }

    const onChangeIsChartView = e => {
        setIsChartView(!e.target.checked)
    }

    const onDraftClick = () => {
        setDraftClicked(!draftClicked)
    }
    
    const onChartClick = e => {
        setStatusClicked(e.payload?.name?? e.value)
    }

    const onModalClosed = () => {
        setStatusClicked("") 
        if (isAdmin)  {
            if (isMyactionsChecked) {
                setApproverHistoryByStatusData([])
                setFilteredMyDeviationsByStatusData([]) 
            } else {
                setFilteredDeviationsByStatusData([])
            }
        } 
        
        if (!isAdmin) {
            if (draftClicked) {
                setDraftDeviationsByStatusData([])
            } else {
                setRequesterHistoryByStatusData([])
            }
            
        }
        setShowChooseDeviationsModal(false)
    }

    useEffect(() => {
        if (statusClicked === "") return
        if ( isAdmin && !isMyactionsChecked && !filteredDeviationsByStatusData || filteredDeviationsByStatusData.length === 0) return
        
        setApproverHistoryByStatusData(approverHistoryByStatus.filter(status => status.name === statusClicked)[0]?.data)
        
        setShowChooseDeviationsModal(true)
    }, [statusClicked, filteredDeviationsByStatusData])

    useEffect(() => {
        if (statusClicked === "") return
        if ( isAdmin && isMyactionsChecked && !filteredMyDeviationsByStatusData || filteredMyDeviationsByStatusData.length === 0) return

        if (!isAdmin && draftClicked) {
            setDraftDeviationsByStatusData(draftDeviationsByStatus.filter(status => status.name === statusClicked)[0]?.data)
        }
        if (!isAdmin && !draftClicked) {
            setRequesterHistoryByStatusData(requesterHistoryByStatus.filter(status => status.name === statusClicked)[0]?.data)
        }
        setShowChooseDeviationsModal(true)
    },[statusClicked, filteredMyDeviationsByStatusData])

    useEffect(() => {
        console.log("filteredDeviationsByStatus: ", filteredDeviationsByStatus)
        console.log("deviationsHistory: ", deviations)
        if (deviations) {
            // Requester
            if (!isAdmin) {
                setDraftDeviations(deviations.filter(dev => dev.status === "EDIT" || dev.status === "CANCEL"))
                setRequesterHistory(deviations.filter(dev => (dev.status !== "CANCEL" && dev.status !== "EDIT")))
            }
            if (isAdmin) {
                setDraftDeviations(myDeviations.filter(dev => dev.status === "EDIT" || dev.status === "CANCEL"))
                setRequesterHistory(myDeviations.filter(dev => (dev.status !== "CANCEL" && dev.status !== "EDIT")))
            }
            
            //Approver
            setApproverHistory(deviations.filter(dev => (dev.status !== "CANCEL" && dev.status !== "EDIT") && dev.editUser?.toLowerCase() === userInfo?.name.toLowerCase()))
        }
        if (filteredDeviationsByStatus) {
            // Requester
            if (!isAdmin) {
                setDraftDeviationsByStatus(filteredDeviationsByStatus.filter(status => status.name === "Edit" || status.name === "Cancel"))
                setRequesterHistoryByStatus(filteredDeviationsByStatus.filter(status => status.name !== "Edit" && status.name !== "Cancel"))
            }

            if (isAdmin) {
                setDraftDeviationsByStatus(filteredMyDeviationsByStatus.filter(status => status.name === "Edit" || status.name === "Cancel"))
                setRequesterHistoryByStatus(filteredMyDeviationsByStatus.filter(status => status.name !== "Edit" && status.name !== "Cancel"))
            }
            
            // Approver
            let approverHistoryStatus = filteredDeviationsByStatus.filter(status => status.name !== "Cancel" && status.name !== "Edit")
            
            approverHistoryStatus = approverHistoryStatus.map((status, index) => {
                let stat = {...status}
                console.log("status: ", status)
                stat.data = stat.data.filter( dev => dev.editUser?.toLowerCase() === userInfo?.name.toLowerCase())
                console.log("stat.data.length: ", stat.data.length)
                stat[stat.name] = stat.data.length
                if (stat.data.length === 0)  return { data: []}
                return stat
              })
              approverHistoryStatus.forEach((el, index) => {if (el.data.length === 0) approverHistoryStatus.splice(index, 1)})
            setApproverHistoryByStatus(approverHistoryStatus)
        }
    }, [deviations, filteredDeviationsByStatus])

    
    const fiscalYearMonthTab = (
        <>
            {tabIndex === 1 || tabIndex === 3 ? <a style={{cursor: "pointer"}} onClick={() => arrowClick(false)}>«</a> : undefined}
            <Tab>{currentMonthFiscalYear}</Tab>
            {tabIndex === 1 || tabIndex === 3 ? <a style={{cursor: "pointer"}} onClick={() => arrowClick(true)}>»</a> : undefined}
        </>
    )

    return (
        <>
            <Container fluid style={{"paddingLeft": "0px"}}>
                {isAdmin ? (
                    <Row style={{ marginTop: '20px', alignItems: 'center', justifyContent: 'center' }}>
                        <span className="colorBlue subtitleStyle">
                            Approver History
                        </span>

                    </Row>

                ) : (
                    <Row style={{ marginTop: '20px' }}>
                        <div></div>
                        <span className="colorBlue subtitleStyle" style={{marginLeft: '28%', position: 'relative' }}>
                            Requester History
                        </span>
                        <u style={draftClicked ? {marginLeft: '14%', position: 'relative' } : {marginLeft: '17%', position: 'relative' }} onClick={onDraftClick}>{ draftClicked ? "Go back" : "Drafts"}</u>
                    </Row>
                )}
                <Row>
                    <HistoryMobileToggles
                        isMyActionsChecked={isMyactionsChecked}
                        onChangeMyActions={onChangeMyActions}
                        isChartView={isChartView}
                        onChangeIsChartView={onChangeIsChartView}
                        isAdmin={isAdmin}
                    />
                </Row>
                <Row>
                    <Col>
                        
                        {isChartView ? 
                        <>
                        <Tabs selectedIndex={tabIndex} onSelect={index => onTabChange(index)}>
                            <TabList>
                                <Tab>W</Tab>
                                <Tab>M</Tab>
                                <Tab>Q</Tab>
                                <Tab>Y</Tab>
                                {fiscalYearMonthTab}
                            </TabList>
                            <TabPanel />
                            <TabPanel />
                            <TabPanel />
                            <TabPanel />
                            <TabPanel />
                        </Tabs>
                        <div>
                            <BarChartReChart 
                            data={isAdmin ? isMyactionsChecked ? approverHistoryByStatus : filteredDeviationsByStatus : draftClicked ? draftDeviationsByStatus : requesterHistoryByStatus} 
                            height={'80vh'} 
                            width={'100vw'} 
                            onChartClick={onChartClick} 
                            />
                        </div> </>: 
                        <HistoryDeviationsTable
                        deviationsHistory={ isAdmin ? isMyactionsChecked ? approverHistory : deviations : draftClicked ? draftDeviations : requesterHistory }
                        onGetDeviationDetails={onGetDeviationDetails}
                        onGetDistributionLists={onGetDistributionLists}
                        onValidateDeviationMaterial={onValidateDeviationMaterial}
                        onUpdateDeviation={onUpdateDeviation}
                        userInfo={userInfo}
                        refreshData={refreshData}
                        onSaveDeviation={onSaveDeviation}
                        onStatusChange={onStatusChange}
                        paginationParametersHistory={paginationParametersHistory}
                        onSetPaginationParametersHistory={onSetPaginationParametersHistory}
                        isMobile={true}
                        setLoading={setLoading}
                        />
                        }
                    </Col>
                </Row>
                {showChooseDeviationsModal ? 
                <Modal
                    show={showChooseDeviationsModal}
                    centered
                    onHide={onModalClosed}
                >
                    <ModalTitle dateRange={dateRange} statusClicked={statusClicked} />
                    <DeviationsTableMobile 
                    // { isAdmin ? isMyactionsChecked ? approverHistory : deviations : draftClicked ? draftDeviations : requesterHistory }
                        deviations={ isAdmin ? isMyactionsChecked ? approverHistoryByStatusData : filteredDeviationsByStatusData : draftClicked ? draftDeviationsByStatusData : requesterHistoryByStatusData }
                        onGetDeviationDetails={onGetDeviationDetails}
                        onGetDistributionLists={onGetDistributionLists}
                        onValidateDeviationMaterial={onValidateDeviationMaterial}
                        onUpdateDeviation={onUpdateDeviation}
                        userInfo={userInfo}
                        refreshData={refreshData}
                        onSaveDeviation={onSaveDeviation}
                        onStatusChange={onStatusChange}
                        paginationParametersPending={paginationParametersPending}
                        onSetPaginationParametersPending={onSetPaginationParametersPending}
                        showTitle={false}
                        onModal={true}
                    />
                </Modal> : undefined}
            </Container>
        </>
    )
}

export default HistoryMobile;
