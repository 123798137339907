import React from 'react';
import Button from '@mui/material/Button';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';


const CreateNewRequestButton = ({ onClick }) => {
  return (
    <Button
      variant="contained"
      // color="white"
      fontSize="large"
      disableElevation
      size="large"
      style={{ fontSize: '18px', backgroundColor: '#002554', color: 'white' }}
      startIcon={<AddCircleRoundedIcon style={{ fontSize: '30px' }} />}
      onClick={onClick}
    >
      Create a New Request
    </Button>
  );
};

export default CreateNewRequestButton;