import { Col, Row } from "react-bootstrap";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';

const TextInputAreaAutosize = (props) => {
    const { title, value, type, isDisabled, onChangeValue, required } = props;
    // console.log("type ", type);
    // console.log("title ", title);
    const inputAreaStyles = {

    }

    return (
        <>
            <Row noGutters className="mb-2">
                <Col xs={6} className="right">
                    {required ? <span style={{ color: 'red' }}>*</span> : undefined}
                    <span style={{ paddingRight: "5px" }}>{title}</span>
                </Col>

            </Row>
            <Row>
                <Col xs={12} style={{ padding: '0px' }}>
                    <Col>
                        <Row>
                            {(type === "tel") ?
                                <TextareaAutosize
                                    type={type}
                                    variant="outlined"
                                    inputProps={{
                                        pattern: "/^[0-9\b]+$/"
                                    }}
                                    maxLength={11}
                                    value={value}
                                    disabled={isDisabled}
                                    style={{ width: "100%", backgroundColor: "white", color: "#000000" }}
                                    onChange={onChangeValue}
                                    className="inputStyle3"
                                /> :
                                <TextareaAutosize
                                    type={type}
                                    maxLength={100}
                                    value={value}
                                    disabled={isDisabled}
                                    rows={3}
                                    style={{ width: "100%", backgroundColor: "white", color: "#000000" }}
                                    onChange={onChangeValue}
                                    className="inputStyle3"
                                />
                            }
                        </Row>
                    </Col>
                </Col>
            </Row>
        </>
    );
};

export default TextInputAreaAutosize;
