import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import TitleText from '../../titleText/TitleText';
import './DeviationModalHeader.css';

const SubTitle = ({date,status})=>{
    return (
        <div className="sub-title">{`Submitted on ${date} `}<span>{status}</span></div>
    )
}



const ComponentDevModalHeader = (props) => {
    // console.log('ComponentDevModalHeader',props)
    const { deviationDetails } = props;
    // const parseDate = ({date}) =>{
    //     console.log(date);
    //     let aux = date.split("T");
    //     console.log(aux);
    //     //return aux[0];
    // }

    //console.log(deviationDetails);
    return (
      //   <Row
      //     style={{
      //       display: 'flex',
      //       alignItems: 'center',
      //       justifyContent: 'center',
      //     }}
      //   >
      <div style={{
          display:'flex',
          alignItems:'center',
          flexDirection:'column',
          justifyContent:'center'
      }}>
        <TitleText
          text={`${deviationDetails ? deviationDetails?.devType?.toUpperCase() : ""} DEV (ID: ${
            deviationDetails ? deviationDetails.deviationId : ''
          })`}
        ></TitleText>
        <SubTitle
          date={
            deviationDetails ? deviationDetails.beginDate.split('T')[0] : ''
          }
          status={deviationDetails ? deviationDetails.status : ''}
        />
      </div>
    );
};

export default ComponentDevModalHeader;