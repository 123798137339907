import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Android12Switch from '../androidToggle/AndroidToggle'
import Switch from '@mui/material/Switch';

const FavoriteToggle = (props) => {
  return (

    <FormGroup sx={{ whiteSpace: 'nowrap' }}>
      <FormControlLabel
        control={
          <Switch
            sx={{
              color: 'white', backgroundColor: 'gray', borderRadius: '34px', width: '60px', height: '20px',
              marginTop: '20px', right: '0', marginRight: '10px'
            }}
            color='default'
            checked={props.checked}
            onChange={props.onChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />}
        label={props.checked ? "BU ALL" : "Favorite Plants"}
      />
    </FormGroup>
  )
}

export default FavoriteToggle