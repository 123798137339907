import React from 'react'
import Drawer from '@mui/material/Drawer';
import FavoritePlantsMenu from './favoritePlantsMenu';
import Main from './mainMenu'

const LeftMenu = props => {
    return (
        <>
            <Drawer
                anchor={"left"}
                open={props.isOpen}
                onClose={() => props.toggleMenu("menu", false)}
                ModalProps={{ keepMounted: true}}
                style={{overflowY: 'hidden'}}
                disableEnforceFocus
            >
                {props.menuView === "menu" ? 
                <Main 
                user={props.user} 
                toggleMenu={props.toggleMenu} 
                /> : 
                <FavoritePlantsMenu 
                toggleMenu={props.toggleMenu} 
                plantsForUser={props.plantsForUser}
                markUnmarkFavoriteAndRefresh={props.markUnmarkFavoriteAndRefresh}
                />}
            </Drawer>
        </>
    )
}

export default LeftMenu;