// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*desktop*/
@media only screen and (min-width: 768px) {
    .modal-content-2 {
        width:  20% !important;
        max-width: 20%!important;
    }
}

/*mobile*/
@media only screen and (max-width: 768px) {
    .modal-content-2 {
        width:  89% !important;
        max-width: 92%!important;
        font-size: 14px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/validateReplacementModal/modalStyle.css"],"names":[],"mappings":"AAAA,UAAU;AACV;IACI;QACI,sBAAsB;QACtB,wBAAwB;IAC5B;AACJ;;AAEA,SAAS;AACT;IACI;QACI,sBAAsB;QACtB,wBAAwB;QACxB,eAAe;IACnB;AACJ","sourcesContent":["/*desktop*/\n@media only screen and (min-width: 768px) {\n    .modal-content-2 {\n        width:  20% !important;\n        max-width: 20%!important;\n    }\n}\n\n/*mobile*/\n@media only screen and (max-width: 768px) {\n    .modal-content-2 {\n        width:  89% !important;\n        max-width: 92%!important;\n        font-size: 14px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
