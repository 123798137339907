import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

const ReplacementInputBox = (props) => {
  const { id, text, editable, description } = props.replacement;
  const { addNewReplacement, removeReplacement, updateReplacement, lastElement, firstElement, replacementsValid, replacementsValidated, deviationType, index } = props;
  const [canAddMore, setCanAddMore] = useState(false);
  const [replacementText, setReplacementText] = useState(text);

  useEffect(() => {
    setReplacementText(text);
    if (text.length === 0) {
      setCanAddMore(false);
    }
  }, [text]);

  const handleCanDelete = () => {
    removeReplacement(index)
  }

  const handleCanAddMore = () => {
    /**
     * Clicking on add more should make curent input box to non editable
     * and should add new row with new auto increment id, empty text and editable true
     */
    //updating current input box and adding new input box
    //adding more no longer sets current input to non editable
    updateReplacement(id, replacementText, description, true);
    addNewReplacement(id + 1);
  };
  const handleOnChange = (e) => {
    if (deviationType === "component" || deviationType === "relabel") {
      const re = /^[0-9\b]+$/;
      if (e.target.value === '' || re.test(e.target.value)) {
        const replacementValue = e.target.value;
        setReplacementText(e.target.value);
        updateReplacement(id, replacementValue, "", true);
        if (replacementValue.length > 0) {
          setCanAddMore(true);
        } else {
          setCanAddMore(false);
        }
      }
    }

    if (deviationType === "process") {
      const replacementValue = e.target.value;
      setReplacementText(e.target.value);
      updateReplacement(id, replacementValue, true, description);
      if (replacementValue.length > 0) {
        setCanAddMore(true);
      } else {
        setCanAddMore(false);
      }
    }

  };
  console.log("replacementsValid: ", replacementsValid)
  console.log("editable: ", editable)
  return (
    <Row style={{ marginBottom: '5px' }}>
      {/* <input
        type="tel"
        maxLength="11"
        onChange={(e) => handleOnChange(e)}
        disabled={editable ? false : true}
        value={replacementText}
      /> */}
      <TextField
        type={deviationType === "component" || deviationType === "relabel" ? "tel" : ""}
        size="small"
        variant="outlined"
        inputProps={{
          maxLength: deviationType === "component" || deviationType === "relabel" ? 11 : 100
        }}
        value={replacementText}
        disabled={replacementsValid && (deviationType === "component" || deviationType === "relabel") ? true : false}
        onChange={(e) => handleOnChange(e)}
        className="inputStyle"
      />
      {/* Code commented to show the description of replacement*/}
      {description !== "" ? (<div style={{ color: '#A0A0A0' }}><small>{description ?? ""}</small></div>) : null}
      {lastElement && !replacementsValidated && (deviationType === "component" || deviationType === "relabel") ? (
        <u
          onClick={() => handleCanAddMore()}
          style={{ visibility: canAddMore ? 'visible' : 'hidden' }}
        >
          + Add More
        </u>
      ) : null}

      {!firstElement && !replacementsValidated && (deviationType === "component" || deviationType === "relabel") ? (
        <u onClick={() => handleCanDelete()} style={{ color: 'red' }}> X remove </u>
      ) : null}

      {lastElement && replacementText !== "" && deviationType === "process" ? (
        <u
          onClick={() => handleCanAddMore()}
          style={{ visibility: canAddMore ? 'visible' : 'hidden' }}
        >
          + Add More
        </u>
      ) : null}
      {!firstElement && deviationType === "process" ? (
        <u onClick={() => handleCanDelete()} style={{ color: 'red' }}>X remove</u>
      ) : null}

    </Row>
  );
};

const MultipleReplacement = ({
  //   setMultipleReplacements,
  updateReplacements,
  multipleReplacements,
  replacementsValid,
  replacementsValidated,
  deviationType,
  replacementsValidationResults,
}) => {
  /**
   * 1. This component is used to display multiple replacements,
   * 2. For the first time we will be having one input box,
   * 3. Entering 11 digits in that box will enable add more link right next to the
   * input box.
   * 4. Clicking on add more link(button) will add new input box
   * 5. One we add new input box previous input box would become disabled
   * 6. We can add as many number of input fields as we wish
   */
  const [replacements, setReplacements] = useState([]);

  useEffect(() => {
    // Update replacements from outside variable just when it is the first time or when it is getting cleaned, otherwise it'll update in loop
    if (replacements.length === 0) setReplacements(multipleReplacements)
    if (multipleReplacements.length === 1 && multipleReplacements[0].id === 1 && multipleReplacements[0].text === "") setReplacements(multipleReplacements)
  }, [multipleReplacements])

  useEffect(() => {
    if (!replacementsValidationResults) return
    console.log("multipleReplacement replacementsValidationResults: ", replacementsValidationResults)
    console.log("multipleReplacement replacements: ", replacements)
    let updatedReplacements = [...replacements]
    if (replacementsValidated) {
      updatedReplacements.forEach(upRep => {
        let validationResult = replacementsValidationResults.find(element => {
          let formattedMaterialNumber = element.materialNumber
          let index = formattedMaterialNumber.indexOf(" - ")
          if (index !== -1) {
            formattedMaterialNumber = formattedMaterialNumber.substring(0, index)
          }
          console.log("formattedMaterialNumber: ", formattedMaterialNumber)
          return formattedMaterialNumber === upRep.text
        })
        upRep.description = validationResult.description ?? ""
      })
    }
    if (replacements.length > 0) updateReplacements(updatedReplacements)
  }, [replacementsValidated])

  useEffect(() => {
    console.log("replacements: ", replacements)
    updateReplacements(replacements);
  }, [replacements]);

  const addNewReplacement = (newId) => {
    const tempReplacements = [...replacements];
    tempReplacements.push({ id: newId, text: '', description: '', editable: true });
    setReplacements(tempReplacements);
  };

  const removeReplacement = index => {
    console.log("index: ", index)
    console.log("replacements: ", replacements)
    const tempReplacements = [...replacements];
    console.log("tempReplacements: ", tempReplacements)
    tempReplacements.splice(index, 1)
    console.log("tempReplacements splice: ", tempReplacements)
    setReplacements(tempReplacements);
  }

  const updateReplacement = (id, text, description, editable) => {
    const tempReplacements = [...replacements];
    const replacementIndex = tempReplacements.findIndex((obj) => obj.id === id);
    tempReplacements[replacementIndex].id = id;
    tempReplacements[replacementIndex].text = text;
    tempReplacements[replacementIndex].description = description;
    tempReplacements[replacementIndex].editable = editable;
    setReplacements(tempReplacements);
  };

  return (
    <Col>
      {replacements.map((replacement, index) => (
        <ReplacementInputBox
          replacement={replacement}
          updateReplacement={updateReplacement}
          addNewReplacement={addNewReplacement}
          removeReplacement={removeReplacement}
          lastElement={index === replacements.length - 1}
          firstElement={index === 0}
          index={index}
          replacementsValid={replacementsValid}
          replacementsValidated={replacementsValidated}
          deviationType={deviationType}
        />
      ))}

    </Col>
  );
};

export default MultipleReplacement;
