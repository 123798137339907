// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion_refreshButton__iKm5a {
    margin-left: 15px !important;
    margin-bottom: 15px !important;
    font-size: 18px;
    border-radius: 10% !important;
    padding: 10px 20px !important;
    background-color: #002554 !important;
    color: white !important;
    font-family: 'proxima-nova' !important;
}

.accordion_header__by4LZ {
    background-color: #002554 !important;
    font-size: 16px !important;
    background-color: #002554;
    color: white !important;
    font-weight: bold !important;
    margin-bottom: -1px !important;
    min-height: 56px !important;
    width: 100% !important;
    font-family: proxima-nova !important;
    font-weight: bold !important;
    padding-left: 12px !important;
    border-color: #f3f3f3 !important;
}

.accordion_header__by4LZ.accordion_expanded__C8agg {
    min-height: 56px !important;
    margin: 12px 0 !important;
}



.accordion_foot2__l-TjR {
    background-color: #FFF !important;
    color: black !important;
}




/* 
.icon {
    vertical-align: bottom !important;
    height: 20px !important;
    width: 20px !important;
}

.details {
    align-items: center !important;
}

.helper {


padding: 8px 16px !important;

}

.link {
    color: #007bff !important;

    text-decoration: none !important;
}

.link:hover {
    text-decoration: underline !important;
}

*/`, "",{"version":3,"sources":["webpack://./src/components/accordionPC/accordion.module.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,8BAA8B;IAC9B,eAAe;IACf,6BAA6B;IAC7B,6BAA6B;IAC7B,oCAAoC;IACpC,uBAAuB;IACvB,sCAAsC;AAC1C;;AAEA;IACI,oCAAoC;IACpC,0BAA0B;IAC1B,yBAAyB;IACzB,uBAAuB;IACvB,4BAA4B;IAC5B,8BAA8B;IAC9B,2BAA2B;IAC3B,sBAAsB;IACtB,oCAAoC;IACpC,4BAA4B;IAC5B,6BAA6B;IAC7B,gCAAgC;AACpC;;AAEA;IACI,2BAA2B;IAC3B,yBAAyB;AAC7B;;;;AAIA;IACI,iCAAiC;IACjC,uBAAuB;AAC3B;;;;;AAKA;;;;;;;;;;;;;;;;;;;;;;;;;;;;CA4BC","sourcesContent":[".refreshButton {\n    margin-left: 15px !important;\n    margin-bottom: 15px !important;\n    font-size: 18px;\n    border-radius: 10% !important;\n    padding: 10px 20px !important;\n    background-color: #002554 !important;\n    color: white !important;\n    font-family: 'proxima-nova' !important;\n}\n\n.header {\n    background-color: #002554 !important;\n    font-size: 16px !important;\n    background-color: #002554;\n    color: white !important;\n    font-weight: bold !important;\n    margin-bottom: -1px !important;\n    min-height: 56px !important;\n    width: 100% !important;\n    font-family: proxima-nova !important;\n    font-weight: bold !important;\n    padding-left: 12px !important;\n    border-color: #f3f3f3 !important;\n}\n\n.header.expanded {\n    min-height: 56px !important;\n    margin: 12px 0 !important;\n}\n\n\n\n.foot2 {\n    background-color: #FFF !important;\n    color: black !important;\n}\n\n\n\n\n/* \n.icon {\n    vertical-align: bottom !important;\n    height: 20px !important;\n    width: 20px !important;\n}\n\n.details {\n    align-items: center !important;\n}\n\n.helper {\n\n\npadding: 8px 16px !important;\n\n}\n\n.link {\n    color: #007bff !important;\n\n    text-decoration: none !important;\n}\n\n.link:hover {\n    text-decoration: underline !important;\n}\n\n*/"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"refreshButton": `accordion_refreshButton__iKm5a`,
	"header": `accordion_header__by4LZ`,
	"expanded": `accordion_expanded__C8agg`,
	"foot2": `accordion_foot2__l-TjR`
};
export default ___CSS_LOADER_EXPORT___;
