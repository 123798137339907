import React from "react";
import TextField from '@mui/material/TextField';
import { Col, Modal, Row } from "react-bootstrap";
import OutLineButton from "../../outLineButton/OutLineButton";

const RejectDialog = (props) => {

  const { show, setShowRejectDialog, deviationId, rejectDeviation, handleComments, comments } = props;

  /**
   * state to handle comment data
   */

  /**
   * You are about to reject the Component Dev (ID: 362).
   * Please enter a comment/reason for the rejection:
   *
   */

  const closeModal = () => {
    handleComments('')
    setShowRejectDialog(false)
  }

  return (
    <Modal show={show} centered onHide={closeModal}>
      <Col
        style={{
          alignItems: "center",
          justifyContent: "center",
          color: "65686B",
          // padding: "0px 20px",
          margin: "20px 0px",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <span>
            {`You are about to`}
            <span
              style={{ color: "#007AFF", fontWeight: "bold" }}
            >{` reject `}</span>
            {`the`}
          </span>
          <span
            style={{ color: "#65686B", fontWeight: "bold" }}
          >{` deviation (ID: ${deviationId}).`}</span>
        </div>
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          {"Please enter a comment/reason for the rejection:"}
        </div>
        <TextField
          variant="outlined"
          fullWidth
          onChange={(e) => handleComments(e.target.value)}
          value={comments}
          multiline
          rowsMax="4"
          inputProps={{
            maxLength: 1000,
          }}
        />
        <div style={{ color: "#A0A0A0", fontSize: "14px", textAlign: "right" }}>
          {"(Up to 1,000 characters)"}
        </div>
        <Row
          style={{
            justifyContent: "space-between",
            margin: "20px 0px 20px 20px",
          }}
        >
          {/**
           * This button is not clickable until some text has been been added in the
           * comment section, once the text enters outline becomes clickable and turns blue colur
           * just trimming the comments info because I don't want to make it clickable if user
           * enters a blank text.(can be changed if we don't like this idea)
           */}
          <OutLineButton
            text="Confirm Rejection"
            isClickable={comments?.trim().length > 0 ? true : false}
            onClick={rejectDeviation}
          />
          <span
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              color: "#65686B",
            }}
            onClick={closeModal}
          >
            Cancel
          </span>
        </Row>
      </Col>
    </Modal>
  );
};

export default RejectDialog;
