import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Button from '@mui/material/Button';
import BottomSheet from '../../components/bottomSheet/bottomSheet';
import CreateNewRequestMobile from '../../components/createNewRequestMobile/CreateNewRequestMobile';
import CreateNewRequestButton from '../../components/createNewRequestButton/CreateNewRequestButton';
import DeviationRequestMobile from '../../components/deviationRequestMobile/deviationRequestMobile';
import DevRequestContactInfo from '../../components/mobile/devRequestContactInfo/DevRequestContactInfo';
import HistoryMobile from '../historyMobile/HistoryMobile';
import DeviationsTableMobile from '../../components/mobile/DeviationsTableMobile';
import FavoriteToggle from '../../components/favoriteToggle/favoriteToggle'
import styles from './MobileHomePage.module.css'

const MobileHomePage = (props) => {
    // Hardcoding roles until we have Okta Roles in place
    // role can be "CORP" or "PLANT"
    const { headerMaterialPlants,
        onGetAllPlantsForHeaderMaterial,
        officialProduct,
        onGetOfficialProduct,
        deviationsPending,
        deviationsHistory,
        myDeviationsHistory,
        onGetDeviations,
        onGetDeviationDetails,
        onValidateZcwpMaterial,
        onValidateDeviationMaterial,
        onGetDistributionLists,
        userInfo,
        lastSavedDeviations,
        onStatusChange,
        onSaveDeviation,
        onSetOfficialProduct,
        onSetZcwpDetails,
        onSetLastSavedDeviations,
        officialProductDetails,
        zwcpDetails,
        refreshData,
        getAllDeviations,
        onUpdateDeviation,
        paginationParametersPending,
        paginationParametersHistory,
        onSetPaginationParametersPending,
        onSetPaginationParametersHistory,
        toggleOnlyFavorites,
        showOnlyFavorites,
        additionalComponents,
        onGetAdditionalComponents,
        onSetAdditionalComponents,
        filteredDeviationsByStatus,
        filteredDeviationsByStatusData,
        filteredMyDeviationsByStatus,
        filteredMyDeviationsByStatusData,
        setFilteredMyDeviationsByStatusData,
        setFilteredDeviationsByStatusData,
        tabIndex,
        onTabChange,
        dateRange,
        statusClicked,
        setStatusClicked,
        setLoading,
        arrowClick,
        currentMonthFiscalYear,
    } = props

    const stages = ["CREATE", "MODIFY"]
    const [currentStage, setCurrentStage] = useState(stages[0]); //Keeping Create as default stage
    const [devType, setDevType] = useState("")
    //iWantTo is going to be used in Plant user role, either you want to start from scrath or clone an existing one
    const [IWantTo, setIWantTo] = useState('brandNew');
    const [describeType, setDescribeType] = useState("")
    const [materialNumber, setMaterialNumber] = useState("")
    const [deviationDetails, setDeviationDetails] = useState("")
    const [plants, setPlants] = useState([])
    const [plantCodes, setPlantCodes] = useState([])
    const [headerMaterialVerified, setHeaderMaterialVerified] = useState(false)
    const [showBottomSheet, setShowBottomSheet] = useState(false);
    const [ingredientStatement, setIngredientStatement] = useState('')
    let isAdmin = userInfo.role === "CORP" || userInfo.role === "ADMIN"

    const handleCreateNewRequestButton = () => {
        setShowBottomSheet(true);
    };

    const handlePlantSelection = (multiple, value, checked, clearAll) => {
        if (clearAll) {
            setPlants([]);
            setPlantCodes([])
            return
        }
        const newPlants = [...plants];
        const newPlantCodes = [...plantCodes]
        let plantCode = ""
        let spaceIndex = 0
        spaceIndex = value.indexOf(" ")
        plantCode = value.substring(0, spaceIndex)
        if (multiple) {
            if (checked) {
                newPlants.push(value);
                newPlantCodes.push(plantCode)
                console.log('newPlants: ', newPlants);
            } else {
                const index = newPlants.indexOf(value);
                const index2 = newPlantCodes.indexOf(plantCode)
                newPlants.splice(index, 1);
                newPlantCodes.splice(index2, 1)
            }
            setPlants(newPlants);
            setPlantCodes(newPlantCodes)
        } else {
            if (checked) {
                setPlants([value]);
                setPlantCodes([plantCode])
            } else {
                setPlants([]);
                setPlantCodes([])
            }
        }
    };

    const handleBottomSheetClose = () => {
        setCurrentStage(stages[0]);
        setDevType('');
        setIWantTo('brandNew');
        setDescribeType('');
        setMaterialNumber('');
        setPlants([]);
        setShowBottomSheet(false);
        setHeaderMaterialVerified(false);
    };

    const renderChildren = () => {
        /**
         * We can render specific components based on some conditions...
         */
        // const stage = stages[0];
        switch (currentStage) {
            case 'CREATE':
                return (
                    <CreateNewRequestMobile
                        setCurrentStage={setCurrentStage}
                        setDevType={setDevType}
                        setIWantTo={setIWantTo}
                        devType={devType}
                        IWantTo={IWantTo}
                        setDescribeType={setDescribeType}
                        describeType={describeType}
                        setMaterialNumber={setMaterialNumber}
                        setDeviationDetails={setDeviationDetails}
                        materialNumber={materialNumber}
                        setHeaderMaterialVerified={setHeaderMaterialVerified}
                        headerMaterialVerified={headerMaterialVerified}
                        handlePlantSelection={handlePlantSelection}
                        headerMaterialPlants={headerMaterialPlants}
                        onGetAllPlantsForHeaderMaterial={onGetAllPlantsForHeaderMaterial}
                        onGetOfficialProduct={onGetOfficialProduct}
                        onGetDeviationDetails={onGetDeviationDetails}
                        onValidateZcwpMaterial={onValidateZcwpMaterial}
                        deviationsPending={deviationsPending}
                        deviationsHistory={deviationsHistory}
                        onSetOfficialProduct={onSetOfficialProduct}
                        onSetZcwpDetails={onSetZcwpDetails}
                        onSetLastSavedDeviations={onSetLastSavedDeviations}
                        userInfo={userInfo}
                        setLoading={setLoading}
                    />
                );
            case 'COMPONENT_DEV':
                return (
                    <DeviationRequestMobile
                        plants={plants}
                        plantCodes={plantCodes}
                        materialNumber={materialNumber}
                        describeType={describeType}
                        deviationDetails={deviationDetails}
                        onValidateDeviationMaterial={onValidateDeviationMaterial}
                        onGetDistributionLists={onGetDistributionLists}
                        onGetDeviationDetails={onGetDeviationDetails}
                        officialProductDetails={officialProductDetails}
                        zwcpDetails={zwcpDetails}
                        setCurrentStage={setCurrentStage}
                        handleBottomSheetClose={handleBottomSheetClose}
                        onSaveDeviation={onSaveDeviation}
                        userInfo={userInfo}
                        deviationType={devType}
                        onUpdateDeviation={onUpdateDeviation}
                        onGetDeviations={onGetDeviations}
                        additionalComponents={additionalComponents}
                        onGetAdditionalComponents={onGetAdditionalComponents}
                        onSetAdditionalComponents={onSetAdditionalComponents}
                        setLoading={setLoading}
                        setIngredientStatement={setIngredientStatement}
                    />
                );
            case 'CONTACT_INFO':
                return (
                    < DevRequestContactInfo
                        onGetDistributionLists={onGetDistributionLists}
                        userInfo={userInfo}
                        deviationDetails={deviationDetails}
                        plantCodes={plantCodes}
                        handleBottomSheetClose={handleBottomSheetClose}
                        lastSavedDeviations={lastSavedDeviations}
                        onStatusChange={onStatusChange}
                        setLoading={setLoading}
                    />
                );
            default:
                break;
        }
    };

    const deviationTable = (
        <>
            <Col style={{ paddingLeft: "0px" }}>
                <Row>
                    <DeviationsTableMobile
                        showTitle={true}
                        deviations={deviationsPending}
                        onGetDeviationDetails={onGetDeviationDetails}
                        onGetDistributionLists={onGetDistributionLists}
                        onValidateDeviationMaterial={onValidateDeviationMaterial}
                        onUpdateDeviation={onUpdateDeviation}
                        userInfo={userInfo}
                        refreshData={refreshData}
                        onSaveDeviation={onSaveDeviation}
                        onStatusChange={onStatusChange}
                        paginationParametersPending={paginationParametersPending}
                        onSetPaginationParametersPending={onSetPaginationParametersPending}
                        onModal={false}
                        setLoading={setLoading}
                    />
                </Row>
            </Col>
        </>
    )


    return (
        <Container
            fluid
            style={{
                width: '100vw',
                margin: '0px',
                padding: '0px',
                fontFamily: 'proxima-nova',
            }}
            md={12}
            sm={12}
            xs={12}
        >
            <Row style={{ justifyContent: 'flex-start' }}>
                <Button
                    variant="contained"
                    fontSize="large"
                    disableElevation
                    size="large"
                    className={styles.refreshButton}
                    onClick={refreshData}> Refresh
                </Button>
                <Button
                    variant="contained"
                    fontSize="large"
                    disableElevation
                    size="large"
                    className={styles.refreshButton}
                    onClick={getAllDeviations}> Show all
                </Button>
                {isAdmin ? <FavoriteToggle checked={!showOnlyFavorites} onChange={toggleOnlyFavorites} /> : undefined}
            </Row>
            {isAdmin ? deviationTable : null}
            <HistoryMobile
                deviations={deviationsHistory}
                myDeviations={myDeviationsHistory}
                userInfo={userInfo}
                onGetDeviationDetails={onGetDeviationDetails}
                onGetDistributionLists={onGetDistributionLists}
                onValidateDeviationMaterial={onValidateDeviationMaterial}
                onUpdateDeviation={onUpdateDeviation}
                refreshData={refreshData}
                onSaveDeviation={onSaveDeviation}
                onStatusChange={onStatusChange}
                paginationParametersPending={paginationParametersPending}
                onSetPaginationParametersPending={onSetPaginationParametersPending}
                filteredDeviationsByStatus={filteredDeviationsByStatus}
                filteredDeviationsByStatusData={filteredDeviationsByStatusData}
                filteredMyDeviationsByStatus={filteredMyDeviationsByStatus}
                filteredMyDeviationsByStatusData={filteredMyDeviationsByStatusData}
                setFilteredMyDeviationsByStatusData={setFilteredMyDeviationsByStatusData}
                setFilteredDeviationsByStatusData={setFilteredDeviationsByStatusData}
                tabIndex={tabIndex}
                onTabChange={onTabChange}
                dateRange={dateRange}
                statusClicked={statusClicked}
                setStatusClicked={setStatusClicked}
                paginationParametersHistory={paginationParametersHistory}
                onSetPaginationParametersHistory={onSetPaginationParametersHistory}
                setLoading={setLoading}
                ingredientStatement={ingredientStatement}
                arrowClick={arrowClick}
                currentMonthFiscalYear={currentMonthFiscalYear}
            />
            <div style={{ marginBottom: '130px' }}> </div>
            <div
                style={{
                    position: 'fixed',
                    display: 'flex',
                    justifyContent: 'center',
                    bottom: '0',
                    left: '0',
                    height: '88px',
                    backgroundColor: '#FFFFFF',
                    width: '100%',
                    boxShadow: '0px -2px 6px #A0A0A0',
                    alignItems: 'center',
                }}
            >
                <CreateNewRequestButton
                    onClick={() => handleCreateNewRequestButton()}
                />
            </div>

            {showBottomSheet ? <BottomSheet
                showBottomSheet={showBottomSheet}
                closeBottomSheet={handleBottomSheetClose}
            >
                {renderChildren()}
            </BottomSheet> : undefined}
        </Container>
    );
};

export default MobileHomePage;
