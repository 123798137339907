// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bold-text{
    font-size: 16px;
    color: #65686B;
    text-transform: uppercase;
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/boldText/BoldText.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,cAAc;IACd,yBAAyB;IACzB,iBAAiB;AACrB","sourcesContent":[".bold-text{\n    font-size: 16px;\n    color: #65686B;\n    text-transform: uppercase;\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
