import React from 'react'
import { Col, Row } from 'react-bootstrap'
import FormGroup from '@mui/material/FormGroup';
import Android12Switch from '../androidToggle/AndroidToggle'
import styles from './HistoryMobileToggles.module.css'
import { setUserInfo } from '../../store/actions';

const HistoryMobileToggles = props => {
    return (
    <>
         <Col xs={6} sm={6}>
            {props.isAdmin ? <Row style={{marginLeft: "5px"}}>
                <span className={props.isMyActionsChecked ? styles.toggleLabels : styles.grayedToggleLabel}>My Actions</span>
                <FormGroup>
                    <Android12Switch 
                        checked={!props.isMyActionsChecked}
                        onChange={props.onChangeMyActions}
                        inputProps={{ 'aria-label': 'controlled' }} 
                    />
                </FormGroup>
                <span className={!props.isMyActionsChecked ? styles.toggleLabels : styles.grayedToggleLabel}>BU All</span>
            </Row> : undefined}
        </Col> 
        <Col xs={6} sm={6} style={{paddingLeft: "3px"}}>
            <Row style={{marginLeft: "0px"}}>
                <span className={styles.toggleLabels}>Chart View</span>
                <FormGroup>
                    <Android12Switch 
                        checked={!props.isChartView}
                        onChange={props.onChangeIsChartView}
                        inputProps={{ 'aria-label': 'controlled' }} 
                    />
                </FormGroup>
                <span className={styles.toggleLabels}>List View</span>
            </Row>
        </Col>
    </>
    )
}
export default HistoryMobileToggles