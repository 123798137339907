import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import IconButton from '@mui/material/IconButton';
import DrawerIcon from '../drawerIcon/DrawerIcon';
import SearchBar from '../searchBar/SearchBar';
import TitleText from '../titleText/TitleText';
import Media from 'react-media';
import LeftMenu from '../leftMenu/leftMenu'

const Toolbar = (props) => {
  const {
    onGetDeviationDetails,
    onGetDistributionLists,
    onValidateDeviationMaterial,
    onUpdateDeviation,
    userInfo,
    refreshData,
    onSaveDeviation,
    onStatusChange,
    searchedReceivedURLParam,
    setSearchedReceivedURLParam,
    deviationId,
    plantsForUser,
    markUnmarkFavoriteAndRefresh,
    setLoading,
  } = props;

  const [menuView, setMenuView] = useState("menu")
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toggleMenu = (menuView_, open) => {
    console.log("menuView_: ", menuView_)
    setIsMenuOpen(open)
    setMenuView(menuView_)
  }


  return (
    <Container fluid className="m-0 p-0">
      <Media queries={{ mobile: { maxWidth: 425 } }}
      >
        {(matches) =>
          matches.mobile ? (
            <Col className="m-0 p-0">
              <Row className="align-items-center" style={{ justifyContent: 'space-between' }}>
                <IconButton style={{ width: '50px', height: '50px' }} onClick={() => toggleMenu("menu", true)}>
                  <DrawerIcon />
                </IconButton>
                <LeftMenu
                  isOpen={isMenuOpen}
                  menuView={menuView}
                  toggleMenu={toggleMenu}
                  user={userInfo?.name}
                  plantsForUser={plantsForUser}
                  markUnmarkFavoriteAndRefresh={markUnmarkFavoriteAndRefresh}
                />
                <TitleText text="tyson spec dev" />
                <span>&nbsp;</span>
              </Row>
              <Row>
                <SearchBar
                  onGetDeviationDetails={onGetDeviationDetails}
                  onGetDistributionLists={onGetDistributionLists}
                  onValidateDeviationMaterial={onValidateDeviationMaterial}
                  onUpdateDeviation={onUpdateDeviation}
                  userInfo={userInfo}
                  refreshData={refreshData}
                  onSaveDeviation={onSaveDeviation}
                  onStatusChange={onStatusChange}
                  searchedReceivedURLParam={searchedReceivedURLParam}
                  setSearchedReceivedURLParam={setSearchedReceivedURLParam}
                  deviationId={deviationId}
                  setLoading={setLoading}
                />
              </Row>
            </Col>
          ) : (
            <Col
              style={{
                marginTop: '25px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Col md={10} lg={10}>
                <Row
                  style={{
                    display: 'flex',
                    // backgroundColor:'red',
                    alignItems: 'center',
                  }}
                >
                  <IconButton style={{ width: '50px', height: '50px' }} onClick={() => toggleMenu("menu", true)}>
                    <DrawerIcon />
                  </IconButton>
                  <LeftMenu
                    isOpen={isMenuOpen}
                    menuView={menuView}
                    toggleMenu={toggleMenu}
                    user={userInfo?.name}
                    plantsForUser={plantsForUser}
                    markUnmarkFavoriteAndRefresh={markUnmarkFavoriteAndRefresh}
                  />
                  {/* <div style={{ textAlign: 'center' }}> */}
                  <TitleText text="tyson spec dev" />
                  {/* </div> */}
                  <SearchBar
                    onGetDeviationDetails={onGetDeviationDetails}
                    onGetDistributionLists={onGetDistributionLists}
                    onValidateDeviationMaterial={onValidateDeviationMaterial}
                    onUpdateDeviation={onUpdateDeviation}
                    userInfo={userInfo}
                    refreshData={refreshData}
                    onSaveDeviation={onSaveDeviation}
                    onStatusChange={onStatusChange}
                    searchedReceivedURLParam={searchedReceivedURLParam}
                    setSearchedReceivedURLParam={setSearchedReceivedURLParam}
                    deviationId={deviationId}
                    setLoading={setLoading}
                  />
                </Row>
              </Col>
              <Col
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  marginRight: '20px',
                }}
              >
                <span
                  style={{
                    fontSize: '16px',
                    color: '#65686B',
                    fontFamily: 'proxima-nova',
                  }}
                >{`Hello, ${userInfo?.name}!`}</span>
              </Col>
            </Col>
          )
        }
      </Media>

      {/* <Media queries={{mobile: {maxWidth: 375}}}>
          {(matches) =>
            matches.mobile ? null : (
              <Col
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  marginRight: '20px',
                }}
              >
                <span
                  style={{fontSize: '16px', color: '#65686B'}}
                >{`Hello, ${userInfo.name}!`}</span>
              </Col>
            )
          }
        </Media> */}
      {/* // </Col> */}
    </Container>
  );
};

export default Toolbar;
