// Override http config
module.exports.api = {
    url: 'http://localhost:8080/v1/',
};

// module.exports.okta = {
//     clientId: '0oa11eoaehcCZdELK0h8',
//     url: 'https://tyson.oktapreview.com/',
//     issuer: 'https://tyson.oktapreview.com/oauth2/default',
//     redirectUri: 'https://spec-dev-v2-qa.cloud.tyson.com/',
// };
module.exports.okta = {
    clientId: '0oav5345h5G8mmz2S0h7',
    url: 'https://tyson-dev.oktapreview.com/',
    issuer: 'https://tyson-dev.oktapreview.com/oauth2/default',
    redirectUri: 'https://localhost:3000/',
};

module.exports.environment = {
    environment: 'test'
}


module.exports.version = { number: '1.0' }
