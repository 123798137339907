import React from "react";
import { Col, Row } from "react-bootstrap";
import DisabledText from "../../disabledText/DisabledText";
import dateIcon from "../../../assets/date.svg";

/**
 * It will take title and value
 *
 */
const TextInputBox = ({ title, value, des = "", isDate = false }) => {
  return (
    <Row noGutters className="mb-2">
      <Col xs={6} className="right">
        <span style={{ paddingRight: "5px", color: "#65686b" }}>{title}</span>
      </Col>
      <Col xs={6} className="left">
        <Col>
          <Row>
            {isDate ? (
              <DisabledText text={value} style={{ width: "80%" }} />
            ) : (
              <DisabledText text={value} style={{ width: "100%" }} />
            )}
            {isDate ? <img src={dateIcon} /> : ""}
          </Row>
          <span style={{ fontSize: "14px", color: "#A0A0A0" }}>{des}</span>
        </Col>
      </Col>
    </Row>
  );
};

export default TextInputBox;
