import { Col, Row } from "react-bootstrap";
import TextField from '@mui/material/TextField';

const TextInputField = (props) => {
    const { title, value, type, isDisabled, isMultiline, onChangeValue, required } = props;
    // console.log("type ", type);
    // console.log("title ", title);
    return (
        <Row noGutters className="mb-2">
            <Col xs={6} className="right">
                {required ? <span style={{ color: 'red' }}>*</span> : undefined}
                <span style={{ paddingRight: "5px" }}>{title}</span>
            </Col>
            <Col xs={6} className="left">
                <Col>
                    <Row>
                        {(type === "tel") ?
                            <TextField
                                type={type}
                                size="small"
                                variant="outlined"
                                inputProps={{
                                    maxLength: 11,
                                    pattern: "/^[0-9\b]+$/"
                                }}
                                value={value}
                                disabled={isDisabled}
                                style={{ width: "100%", backgroundColor: "white", border: "none", color: "#CCCCCC" }}
                                onChange={onChangeValue}
                                className="inputStyle"
                            /> :
                            <TextField
                                type={type}
                                size="small"
                                variant="outlined"
                                inputProps={{ maxLength: 100 }}
                                value={value}
                                disabled={isDisabled}
                                multiline={isMultiline}
                                rows={3}
                                style={{ width: "100%", backgroundColor: "white", border: "none", color: "#CCCCCC" }}
                                onChange={onChangeValue}
                                className="inputStyle"
                            />
                        }
                    </Row>
                </Col>
            </Col>
        </Row>
    );
};

export default TextInputField;
