import React, { useState, useEffect } from 'react';
import { Modal, FormCheck } from 'react-bootstrap';
import OutLineButton from '../../components/outLineButton/OutLineButton';
import OriginalsAccordion from '../../components/originalsAccordion/OriginalsAccordion';
import swal from 'sweetalert2'

const ChooseOriginals = (props) => {
  const {
    showSteps,
    closeSteps,
    originalsChosen,
    setOriginals,
    canSelectMultiple,
    formulaSteps,
    packing,
    size,
    officialProductDetails,
    zwcpDetails,
    plantCodes,
    userInfo,
    showAdditionalComponents,
    setShowAdditionalComponents,
    additionalComponents,
    onGetAdditionalComponents,
    materialNumber,
    setLoading,
    onSetAdditionalComponents,
    deviationType,
  } = props;
  const [originalsSelected, setOriginalsSelected] = useState([]);
  const [addtionalSteps, setAdditionalSteps] = useState([])
  const [allAdditionalSelected, setAllAdditionalSelected] = useState(false)
  const [additionalOriginalsSelected, setAdditionalOriginalsSelected] = useState([])
  const steps = formulaSteps ? formulaSteps : [];
  const [first, ...rest] = steps;
  const newArr = [...rest, first];

  console.log("step bran ", newArr)
  //steps.reverse();
  const checkAllAdditionalSelected = () => {
    console.log("additionalOriginalsSelected: ", additionalOriginalsSelected)
    console.log("plantCodes: ", plantCodes)
    let atLeastOnePerPlant = false
    for (let i = 0; i < plantCodes.length; i++) {
      atLeastOnePerPlant = additionalOriginalsSelected.some(origSel => origSel.plant === plantCodes[i] && (origSel.contents && origSel.contents.length > 0))
      if (!atLeastOnePerPlant) break
    }
    setAllAdditionalSelected(atLeastOnePerPlant)
  }

  const formatAdditionalOriginals = () => {
    let duplicateAdditionalOriginals = [...additionalOriginalsSelected]
    let formattedOriginals = duplicateAdditionalOriginals.flatMap(addOriginal => {
      return addOriginal.contents.flatMap(content => {
        return {
          allergens: content.allergens,
          mayContain: [],
          description: content.description,
          materialType: content.materialType,
          title: content.title,
          plant: addOriginal.plant
        }
      })
    })
    console.log("formattedOriginals: ", formattedOriginals)
    return formattedOriginals
  }

  const handleDone = () => {
    let formattedOriginals = []
    if (showAdditionalComponents) {
      formattedOriginals = formatAdditionalOriginals()
    }
    setOriginals(showAdditionalComponents ? formattedOriginals : originalsSelected)
    originalsChosen()
    onSetAdditionalComponents([])
    closeSteps()
  };

  const handleHide = () => {
    setOriginals([])
    setOriginalsSelected([])
    setAdditionalOriginalsSelected([])
    onSetAdditionalComponents([])
    closeSteps()
  }

  useEffect(() => {
    if (!additionalComponents) return
    let formattedSteps = additionalComponents.map(addComp => {
      let title = addComp.plant
      let contents = addComp.components.map(comp => {
        return {
          title: comp,
          description: "",
          allergens: [],
          mayContain: [],
          materialType: ""
        }
      })
      return {
        title,
        contents
      }
    })
    console.log("formattedSteps: ", formattedSteps)
    setAdditionalSteps(formattedSteps)
  }, [additionalComponents])

  useEffect(() => {
    console.log("showAdditionalComponents: ", showAdditionalComponents)
    console.log("additionalComponents: ", additionalComponents)
    if (showAdditionalComponents) setOriginalsSelected([])
    if (!showAdditionalComponents) {
      setAdditionalOriginalsSelected([])
      return
    }
    if (additionalComponents.length > 0) return
    setLoading(true)
    onGetAdditionalComponents(materialNumber, plantCodes).then(res => {
      setLoading(false)
      if (!res.data?.isSuccess) {
        swal.fire({
          icon: "warning",
          title: "No data returned from service!",
        })
      }
    }).catch(e => {
      console.log("error calling getAdditionalComponents: ", e)
      setLoading(false)
      swal.fire({
        icon: "warning",
        title: "No data returned from service!",
      })
    })
  }, [showAdditionalComponents])

  useEffect(() => {
    checkAllAdditionalSelected()
  }, [additionalOriginalsSelected])

  return (
    <Modal
      show={showSteps}
      onHide={handleHide}
      size={'lg'}
      dialogClassName="ingredient-step-modal"
      centered
      keyboard="true" //Close the modal when escape key is pressed
      scrollable={true}
    >
      <div
        style={{
          margin: '10px',
          fontFamily: 'proxima-nova',
          fontSize: '12px',
          textAlign: 'center',
        }}
      >
        {canSelectMultiple ? "Select MULTIPLE original component from a step:" : "Select ONE original component from a step:"}
      </div>
      <Modal.Body style={{ fontFamily: 'proxima-nova', fontSize: '12px' }}>
        <OriginalsAccordion
          steps={newArr}
          canSelectMultiple={canSelectMultiple}
          setOriginalsSelected={setOriginalsSelected}
          disabled={showAdditionalComponents}
          isAdditionalComponents={false}
        />
        {showAdditionalComponents ? <OriginalsAccordion
          steps={addtionalSteps}
          canSelectMultiple={canSelectMultiple}
          setOriginalsSelected={setAdditionalOriginalsSelected}
          disabled={!showAdditionalComponents}
          isAdditionalComponents={true}
        /> : undefined}
      </Modal.Body>
      <div style={{ textAlign: 'center', margin: '10px' }}>
        {deviationType !== "process" ? <FormCheck
          checked={showAdditionalComponents}
          onChange={(e) => setShowAdditionalComponents(e.target.checked)}
          label="I need to see additional component(s)"
          disabled={originalsSelected.length > 0}
        /> : undefined}
        <div style={{ marginTop: '10px' }}>
          <OutLineButton
            text="Done"
            backgroundColor="#65686B"
            isClickable={showAdditionalComponents ? allAdditionalSelected : originalsSelected.length > 0 ? true : false}
            onClick={() => { handleDone() }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ChooseOriginals;
