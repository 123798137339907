import React, { useState } from 'react'
import '../../style/bottomSheet.css'
import { Container, Row } from 'react-bootstrap'
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

const BottomModal = ({ show, setShow, children, dismiss }) => {

    const handleClickAway = (e) => {
        if (!e.target.closest('.swal2-actions')) {
            dismiss()
        }
        // setShow(false)
    }

    return (
        <Row
            className={`modal `}
            style={{
                height: show ? "100%" : "0",
                // padding:"10px"
            }}
        >
            <Container className={`modal_content ${!show ? "bg_tranparent" : ""}`}>
                {/* <Col
                    className="close_btn"
                // onClick={(e) => {
                //     e.stopPropagation();
                //     setShow(false);
                // }}
                >

                </Col> */}

                <ClickAwayListener
                    onClickAway={handleClickAway}>
                    <Row style={{ height: "100%" }}>
                        <Container fluid>
                            {children}
                        </Container>
                    </Row>
                </ClickAwayListener>
            </Container>
        </Row>
    );
}

const Children = (props) => {
    return (
        <Row>
            {props.children}
        </Row>
    )
}

const BottomSheet = (props) => {
    const { showBottomSheet, closeBottomSheet } = props;
    // console.log('showBottomSheet', showBottomSheet)
    const [show, setShow] = useState(showBottomSheet);

    const dismissBottomSheet = () => {
        closeBottomSheet()
    }

    return (
        <Container fluid style={{ backgroundColor: 'red', padding: '10px', margin: '10px' }}>
            {/* <Button variant="contained" color="secondary" onClick={openBottomSheet}>
                Open Bottom Sheet
            </Button> */}
            <BottomModal
                show={showBottomSheet}
                setShow={setShow}
                dismiss={() => dismissBottomSheet()}
            >
                {/* <span>Component Dev</span> */}
                <Row style={{ justifyContent: 'flex-end', marginRight: '10px' }}>
                    <IconButton
                        onClick={() => dismissBottomSheet()}
                        aria-label="delete"
                        size="medium"
                        className="close_btn"
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                </Row>

                <Children>{props.children}</Children>
            </BottomModal>
        </Container>
    );
}

export default BottomSheet;
