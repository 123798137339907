import MaterialTable, { MTableCell } from "material-table";
import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import DeleteIcon from "@mui/icons-material//Delete";
import SearchIcon from "@mui/icons-material//Search";
import AddBox from "@mui/icons-material//AddBox";
import ArrowDownward from "@mui/icons-material//ArrowDownward";
import Check from "@mui/icons-material//Check";
import ChevronLeft from "@mui/icons-material//ChevronLeft";
import ChevronRight from "@mui/icons-material//ChevronRight";
import Clear from "@mui/icons-material//Clear";
import Edit from "@mui/icons-material//Edit";
import FilterList from "@mui/icons-material//FilterList";
import FirstPage from "@mui/icons-material//FirstPage";
import LastPage from "@mui/icons-material//LastPage";
import Remove from "@mui/icons-material//Remove";
import SaveAlt from "@mui/icons-material//SaveAlt";
import ViewColumn from "@mui/icons-material//ViewColumn";
import StarIcon from '@mui/icons-material//Star';
import Paper from '@mui/material/Paper';
import { Col, Container, Row } from "react-bootstrap";
import RoundButton from "../roundButton/RoundButton";
import StarBorderOutlinedIcon from '@mui/icons-material//StarBorderOutlined';
import swal from 'sweetalert2'
import { HtmlToolTip } from "./HtmlToolTip";
import FavouriteIcon from "../../assets/favourite_icon.svg";
import DeviationModal from "../deviationModal/DeviationModal";
import RejectDialog from "../deviationModal/rejectDialog/RejectDialog";
import ApproveDialog from "../deviationModal/approveDialog/ApproveDialog";
import CloneDialog from "../deviationModal/cloneDialog/CloneDialog";
import CancelDialog from "../deviationModal/cancelDialog/CancelDialog";

const DeviationsTable = (props) => {

  const {
    deviationsPending,
    onGetDeviationDetails,
    onGetDistributionLists,
    onValidateDeviationMaterial,
    onUpdateDeviation,
    userInfo,
    onSaveDeviation,
    refreshData,
    currentStep,
    onStatusChange,
    paginationParametersPending,
    onSetPaginationParametersPending,
    setLoading,
    loading
  } = props;

  const convertGMTToCSTTime = (gmtTime) => {
    let m = moment.utc(gmtTime);
    m.tz("America/Chicago");
    return m.format("hh:mm A");
  };
  const convertGMTToCSTDate = (gmtTime) => {
    let m = moment.utc(gmtTime).format("YYYY/MM/DD");
    // m.tz("America/Chicago");
    return m;
  };

  const pageChange = currentPage => {
    onSetPaginationParametersPending({ ...paginationParametersPending, currentPage })
  }

  const rowsPerPageChange = pageSize => {
    onSetPaginationParametersPending({ ...paginationParametersPending, pageSize })
  }

  const [deviationsCustom, setDeviationsCustom] = useState([])

  useEffect(() => {
    let tempDeviations = [...deviationsPending]
    tempDeviations = tempDeviations.map(dev => { return { ...dev, plantField: dev.componentPlantCode ?? dev.processPlantCode } })
    // setShowRejectDialog(showRejectDialog);
    setDeviationsCustom(tempDeviations)
  }, [deviationsPending]);


  let tableColumns = (data) => {
    return [
      {
        title: 'Dev ID',
        field: 'deviationId',
        type: 'numeric',
        width: 100,
      },
      {
        title: 'Request Date',
        field: 'createdAt',
        type: 'date',
        cellStyle: {
          width: 10,
          maxWidth: 10,
        },
        headerStyle: {
          width: 20,
          maxWidth: 20,
        },
        width: 100,
      },
      {
        title: (
          <span>
            <img src={FavouriteIcon} alt="" style={{ marginRight: '5px' }} />
            Plant
          </span>
        ),
        render: (data) => (
          <div>
            <span className="favorite-button">
              {data.isFavorite ? (
                <StarIcon
                  style={{ color: '#ffb400' }}
                />
              ) : (
                <StarBorderOutlinedIcon />
              )}
            </span>
            <span>
              {data.componentPlantCode
                ? data.componentPlantCode
                : data.processPlantCode}
            </span>
          </div>
        ),
        type: 'string',
        field: 'plantField',
        cellStyle: {
          textAlign: 'left',
        },
      },
      {
        title: 'Header Material',
        field: 'materialNumber',
        width: 100,
      },
      {
        title: 'Begin-End',
        width: 200,
        render: (data) => (
          <span>
            {convertGMTToCSTDate(data.beginDate)} -{' '}
            {convertGMTToCSTDate(data.endDate)}
          </span>
        ),
      },
      {
        title: 'Type',
        width: 100,
        render: data => {
          const isComponent = data.devType === "component" || data.devType === "relabel"
          const myTooltip = (
            <>
              <span>- Original:</span>
              <br />
              <span><b>{isComponent ? data.sapMaterialNumber : data.recipeStepName}</b></span>
              <br />
              <br />
              <span>- Replacement:</span>
              <br />
              <span><b>{isComponent ? data.replacementSapMaterialNumber : data.deviationValue}</b></span>
              <br />
              <br />
              <span>- Reason/Description:</span>
              <br />
              <span>{data.description}</span>
            </>
          )
          return (<HtmlToolTip title={myTooltip}>
            <span>{data.devType}</span>
          </HtmlToolTip>)
        },
        field: 'devType'
      },
    ];
  }

  /**
   * This is used to toggle the modal(dialog)
   */
  const [openModal, setOpenModal] = useState(false);

  /**
   * Currently selected row data
   */
  const [rowData, setRowData] = useState({});

  /**
   * Handles displaying reject,approve dialogs
   */
  const [showRejectDialog, setShowRejectDialog] = useState(false);
  const [showApproveDialog, setShowApproveDialog] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showCloneDialog, setShowCloneDialog] = useState(false);


  // const onRejectDialogClose = ()=>{
  //   setShowRejectDialog(false)
  // }

  /**
   * This will get called when deviations data changes
   */

  /**
   * Called upon clicking approve button
   */

  const onApprove = (e, rowInfo) => {
    e.stopPropagation();
    let deviationDetails = undefined
    setLoading(true)
    onGetDeviationDetails(rowInfo.deviationId).then((result) => {
      setLoading(false)
      setLoading(false)
      if (!result?.data?.isSuccess) {
        swal.fire({
          icon: "warning",
          title: "No data returned from service",
          timer: 3000,
        })
        return;
      }
      deviationDetails = result.data.data
      if (!deviationDetails) return
      let distributionLists = deviationDetails.distributionLists?.split(",").map(dl => {
        let name = dl.substring(0, dl.indexOf('-'))
        let isCC = dl.substring(dl.indexOf('-') + 1, dl.length)
        return { name, isCC, selected: false }
      });
      let individualEmails = ""
      let individualEmailsCC = ""

      deviationDetails.customEmails?.split(',').forEach(email => {
        let name = email.substring(0, email.indexOf('-'))
        let isCC = email.substring(email.indexOf('-') + 1, email.length)
        if (isCC === "0") {
          individualEmails = individualEmails === "" ? name : ", " + name
        }
        if (isCC === "1") {
          individualEmailsCC = individualEmailsCC === "" ? name : ", " + name
        }
      })
      let today = new Date()
      today.setHours(0, 0, 0, 0)
      let beginDate = moment(deviationDetails.beginDate, 'YYYY-MM-DD').toDate()
      // if (beginDate < today) {
      //   swal.fire({
      //     icon: "warning",
      //     title: "You cannot approve a deviation if its start date already passed",
      //     timer: 5000,
      //   })
      //   return
      // }

      let cc = distributionLists?.filter(dl => dl.isCC === '1').map(cc => cc.name).join(",")
      let emailTo = distributionLists?.filter(dl => dl.isCC === '0').map(dl => dl.name).join(",")
      let deviation = [{
        deviationId: deviationDetails.deviationId,
        statusChange: "ACCEPT",
        editUser: userInfo?.name,
        editReason: "Approved",
        emailTo,
        cc,
        customEmailTo: individualEmails,
        customCC: individualEmailsCC,
      }]
      console.log("deviation onApprove: ", deviation)
      setLoading(true)
      onStatusChange(deviation).then(result => {
        setLoading(false)
        if (typeof result === "string" || !result) {
          swal.fire({
            icon: "error",
            title: "Error returned from service",
            text: result ?? ""
          })
          return
        }
        if (!result?.data?.isSuccess) {
          swal.fire({
            icon: "error",
            title: "Could not submit deviation(s)",
            text: result.data.data.error?.message,
          })
          return
        }

        swal.fire({
          icon: "success",
          title: "Deviation Approved!",
          text: "You successfully approved the deviation with id: " + deviation[0].deviationId,
          confirmButtonText: "Got it!"
        }).then(res => {
          if (res.dismiss || res.isConfirmed || res.isDismissed) {
            refreshData()
          }
        })
        // setShowApproveDialog(true)
      })
    })
  }

  /**
   * Called upon clicking view details button
   */
  const onViewDetails = (e, rowInfo) => {
    // e.preventDefault();
    console.log("view details", rowInfo);
    setRowData(rowInfo);
    setOpenModal(true);
    e.stopPropagation();
  };

  const onRejectButtonClick = () => {
    console.log("onRejectmain");
    setOpenModal(false);
    setShowRejectDialog(true);
  };

  const onApproveButtonClick = () => {
    console.log("onApprovemain");
    setOpenModal(false);
    setShowApproveDialog(true);
  };

  const onCancelButtonClick = () => {
    console.log("Cancel plant user")
    setOpenModal(false);
    setShowCancelDialog(true);
  }

  const onCloneButtonClick = () => {
    console.log("onClone")
    setOpenModal(false);
    setShowCloneDialog(true);
  }


  const closeModal = () => {
    setOpenModal(false);
  };

  const handleRowClick = (rowData) => {
    setRowData(rowData);
    setOpenModal(true);
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <MaterialTable
            title=""
            columns={tableColumns(deviationsCustom)}
            onChangePage={page => pageChange(page)}
            onChangeRowsPerPage={pageSize => rowsPerPageChange(pageSize)}
            data={deviationsCustom}
            icons={{
              Search: SearchIcon,
              ResetSearch: DeleteIcon,
              PreviousPage: ChevronLeft,
              NextPage: ChevronRight,
              FirstPage: FirstPage,
              LastPage: LastPage,
              SortArrow: ArrowDownward,
              AddBox: AddBox,
              Clear: Clear,
              Edit: Edit,
              Filter: FilterList,
              Check: Check,
              Remove: Remove,
              SaveAlt: SaveAlt,
              ViewColumn: ViewColumn,
            }}
            options={{
              search: true,
              actionsColumnIndex: -1,
              rowStyle: {
                fontFamily: 'proxima-nova'
              }
            }}
            localization={{
              body: {
                emptyDataSourceMessage: "No deviations to display",
              },
            }}
            onRowClick={(event, rowData) => {
              handleRowClick(rowData);
            }}
            actions={[
              userInfo?.role === "CORP" || userInfo?.role === "ADMIN" ? {
                buttonText: "Approve",
                onClick: (event, rowData) => onApprove(event, rowData),
              } : undefined,
              {
                buttonText: "View Details",
                onClick: (event, rowData) => onViewDetails(event, rowData),
              },
            ]}
            components={{
              Container: props => <Paper {...props} elevation={0} />,
              Action: (props) => {
                // console.log("props.data: ", props.data)
                return props.action.buttonText === "Approve" ? (
                  <RoundButton
                    bgColor="#14C232"
                    text="Approve"
                    onClick={(event) => props.action.onClick(event, props.data)}
                  ></RoundButton>
                ) : (
                  <RoundButton
                    bgColor="#65686B"
                    text="View Detail"
                    onClick={(event) => props.action.onClick(event, props.data)}
                  ></RoundButton>
                );
              },
            }}
          ></MaterialTable>
        </Col>
      </Row>
      {openModal ? (
        <DeviationModal
          open={openModal}
          onClose={closeModal}
          deviationId={rowData.deviationId}
          onGetDeviationDetails={onGetDeviationDetails}
          onGetDistributionLists={onGetDistributionLists}
          onValidateDeviationMaterial={onValidateDeviationMaterial}
          onUpdateDeviation={onUpdateDeviation}
          userInfo={userInfo}
          refreshData={refreshData}
          onSaveDeviation={onSaveDeviation}
          currentStep={currentStep}
          onStatusChange={onStatusChange}
          setLoading={setLoading}
        />
      ) : null}

      {showRejectDialog ? <RejectDialog
        show={showRejectDialog}
        setShowRejectDialog={(value) => setShowRejectDialog(value)}
        deviationId={rowData.deviationId}
      /> : undefined}
      {showApproveDialog && !loading ? <ApproveDialog
        show={showApproveDialog}
        deviationId={rowData.deviationId}
        setShowApproveDialog={setShowApproveDialog}
        refreshData={refreshData}
        onCloseModal={closeModal}
      /> : undefined}
      {showCloneDialog ? <CloneDialog
        show={showCloneDialog}
        deviationId={rowData.deviationId}
        setShowCloneDialog={(value) => setShowCloneDialog(value)}
      /> : undefined}
      {showCancelDialog ? <CancelDialog
        show={showCloneDialog}
        deviationId={rowData.deviationId}
        setShowCancelDialog={(value) => setShowCancelDialog(value)}
      /> : undefined}
    </Container>
  );
};

export default DeviationsTable;
