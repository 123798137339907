import MaterialTable, { MTableBody } from 'material-table';
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import DeleteIcon from '@mui/icons-material//Delete';
import SearchIcon from '@mui/icons-material//Search';
import AddBox from '@mui/icons-material//AddBox';
import ArrowDownward from '@mui/icons-material//ArrowDownward';
import Check from '@mui/icons-material//Check';
import ChevronLeft from '@mui/icons-material//ChevronLeft';
import ChevronRight from '@mui/icons-material//ChevronRight';
import Clear from '@mui/icons-material//Clear';
import Edit from '@mui/icons-material//Edit';
import FilterList from '@mui/icons-material//FilterList';
import FirstPage from '@mui/icons-material//FirstPage';
import LastPage from '@mui/icons-material//LastPage';
import Remove from '@mui/icons-material//Remove';
import SaveAlt from '@mui/icons-material//SaveAlt';
import ViewColumn from '@mui/icons-material//ViewColumn';
import { Col, Container, Row } from 'react-bootstrap';
import RoundButton from '../roundButton/RoundButton';
import DeviationModal from '../deviationModal/DeviationModal';
import { HtmlToolTip } from '../deviationsTable/HtmlToolTip';
import { CSVLink } from 'react-csv';
import Paper from '@mui/material/Paper';

const HistoryDeviationsTable = (props) => {
  const {
    deviationsHistory,
    onGetDeviationDetails,
    onGetDistributionLists,
    onValidateDeviationMaterial,
    onUpdateDeviation,
    userInfo,
    paginationParametersHistory,
    onSetPaginationParametersHistory,
    refreshData,
    onSaveDeviation,
    onStatusChange,
    isMobile,
    setLoading,
    changeFilter,
    getFilter,
  } = props;
  const tableRef = React.useRef();

  const [filteredData, setFilteredData] = useState(deviationsHistory);
  const [csvFileData, setCsvFileData] = useState('');
  const [deviationsCustom, setDeviationsCustom] = useState([]);
  const [showLink, setShowLink] = useState(false);
  const [plantFilter, setPlantFilter] = useState('');
  const [headerFilter, setHeaderFilter] = useState('');
  /* const [activeFilter, setActiveFilter] = useState({
     2: "",
     3: ""
   })*/

  useEffect(() => {
    let tempDeviations = [...deviationsHistory];
    tempDeviations = tempDeviations.map((dev) => {
      return {
        ...dev,
        plantField: dev.componentPlantCode ?? dev.processPlantCode,
      };
    });
    // setShowRejectDialog(showRejectDialog);
    setDeviationsCustom(tempDeviations);
  }, [deviationsHistory]);

  const convertGMTToCSTTime = (gmtTime) => {
    var m = moment.utc(gmtTime);
    m.tz('America/Chicago');
    return m.format('hh:mm A');
  };
  const convertGMTToCSTDate = (gmtTime) => {
    var m = moment.utc(gmtTime);
    // m.tz('America/Chicago');
    return m.format('YYYY/MM/DD');
  };

  const pageChange = (currentPage) => {
    onSetPaginationParametersHistory({
      ...paginationParametersHistory,
      currentPage,
    });
  };

  const rowsPerPageChange = (pageSize) => {
    onSetPaginationParametersHistory({
      ...paginationParametersHistory,
      pageSize,
    });
  };
  // const onFilterChange = (columnId, value) => {
  //   setActiveFilter({ ...activeFilter, [columnId]: value });
  // }

  const getBgColor = (status) => {
    switch (status) {
      case 'ACCEPT':
      case 'APPROVED':
        return '#14C232';
      case 'CANCEL':
      case 'REJECTED':
        return '#007AFF';
      case 'EXPIRED':
        return '#A0A0A0';
      case 'PENDING':
        return '#A0A0A0';
      case 'EDIT':
        return 'rgb(158, 158, 158)';
      default:
        return '#A0A0A0';
    }
  };
  /**
   * Deviations table columns
   */
  let tableColumns = (data) => {
    return [
      {
        title: 'Dev ID',
        field: 'deviationId',
        type: 'numeric',
        width: 100,
        filtering: false,
      },
      {
        title: 'Request Date',
        field: 'createdAt',
        type: 'date',
        cellStyle: {
          width: 10,
          maxWidth: 10,
        },
        headerStyle: {
          width: 20,
          maxWidth: 20,
        },
        width: 100,
        filtering: false,
      },
      {
        title: (
          <span>
            {/* <img src={FavouriteIcon} alt="" style={{ marginRight: '5px' }} /> */}
            Plant
          </span>
        ),
        field: 'plantField',
        //field: data.componentPlantCode ? "componentPlantCode" : "processPlantCode",
        render: (data) => (
          <span>
            {data.componentPlantCode
              ? data.componentPlantCode
              : data.processPlantCode}
          </span>
        ),
        type: 'string',
        cellStyle: {
          textAlign: 'left',
        },
        defaultFilter: plantFilter,
      },
      {
        title: 'Header Material',
        field: 'materialNumber',
        width: 100,
        defaultFilter: headerFilter,
      },
      {
        title: 'Begin-End',
        field: 'Begin-End',
        filtering: false,
        // width: 200,
        render: (rowData) => (
          <Row>
            <div>
              <span>
                {convertGMTToCSTDate(rowData.beginDate)} -{' '}
                {convertGMTToCSTDate(rowData.endDate)}
              </span>
            </div>
          </Row>
        ),
      },
      {
        title: 'Type',
        width: 100,
        filtering: false,
        render: (data) => {
          const isComponent =
            data.devType === 'component' || data.devType === 'relabel';
          const myTooltip = (
            <>
              <span>- Original:</span>
              <br />
              <span>
                <b>
                  {isComponent ? data.sapMaterialNumber : data.recipeStepName}
                </b>
              </span>
              <br />
              <br />
              <span>- Replacement:</span>
              <br />
              <span>
                <b>
                  {isComponent
                    ? data.replacementSapMaterialNumber
                    : data.deviationValue}
                </b>
              </span>
              <br />
              <br />
              <span>- Reason/Description:</span>
              <br />
              <span>{data.description}</span>
            </>
          );
          return (
            <HtmlToolTip title={myTooltip}>
              <span>{data.devType}</span>
            </HtmlToolTip>
          );
        },
        field: 'devType',
      },
      {
        title: 'Status',
        field: 'status',
        filtering: false,
        customFilterAndSearch: (term, rowData) =>
          rowData?.status?.toLowerCase()?.indexOf(term?.toLowerCase()) != -1,
        render: (rowData) => (
          <Row>
            <div
              style={{
                backgroundColor: getBgColor(rowData?.status),
                width: '5px',
                height: '20px',
                marginRight: '5px',
              }}
            />
            <span>{rowData?.status}</span>
          </Row>
        ),
      },
    ];
  };

  let tableColumnsMobile = (data) => {
    return [
      {
        title: (
          <span>
            {/* <img src={FavouriteIcon} alt="" style={{ marginRight: '5px' }} /> */}
            Plant
          </span>
        ),
        field: 'plant',
        //field: data.componentPlantCode ? "componentPlantCode" : "processPlantCode",
        render: (...data) => (
          <span>
            {data.componentPlantCode
              ? data.componentPlantCode
              : data.processPlantCode}
          </span>
        ),
        type: 'string',
        cellStyle: {
          textAlign: 'left',
        },
      },
      {
        title: 'Dev ID',
        field: 'deviationId',
        type: 'numeric',
        width: 100,
      },
      {
        title: 'Header Material',
        field: 'materialNumber',
        width: 100,
      },
      {
        title: 'Begin-End',
        render: (data) => (
          <span>
            {convertGMTToCSTDate(data.beginDate)} -{' '}
            {convertGMTToCSTDate(data.endDate)}
          </span>
        ),
        cellStyle: {
          whiteSpace: 'nowrap',
        },
      },
      {
        title: 'Type',
        render: (data) => (
          <span>{data.devType.toUpperCase().substring(0, 1)}</span>
        ),
      },
      {
        title: 'Status',
        field: 'status',
        customFilterAndSearch: (term, rowData) =>
          rowData?.status?.toLowerCase().indexOf(term?.toLowerCase()) != -1,
        render: (rowData) => (
          <Row>
            <div
              style={{
                backgroundColor: getBgColor(rowData?.status),
                width: '5px',
                height: '20px',
                marginRight: '5px',
              }}
            />
            <span>
              {rowData?.status === 'CANCEL'
                ? 'R'
                : rowData?.status?.substring(0, 1)}
            </span>
          </Row>
        ),
      },
    ];
  };
  /**
   * This is used to toggle the modal(dialog)
   */
  const [openModal, setOpenModal] = useState(false);

  /**
   * Currently selected row data
   */
  const [rowData, setRowData] = useState({});

  /**
   * Handles displaying reject,approve dialogs
   */
  const [showRejectDialog, setShowRejectDialog] = useState(false);
  const [showApproveDialog, setShowApproveDialog] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showCloneDialog, setShowCloneDialog] = useState(false);

  // Hardcoding roles until we have Okta Roles in place
  // role can be "CORP", "ADMIN" or "PLANT"

  useEffect(() => {
    // setShowRejectDialog(showRejectDialog);
  }, [showRejectDialog]);

  // const onRejectDialogClose = ()=>{
  //   setShowRejectDialog(false)
  // }

  /**
   * This will get called when deviations data changes
   */

  /**
   * Called upon clicking approve button
   */
  // const onApprove = (e, rowInfo) => {
  //   // e.preventDefault();
  //   console.log('approved', rowInfo);
  //   setRowData(rowInfo);
  //   setShowApproveDialog(true);
  //   e.stopPropagation();
  // };

  /**
   * Called upon clicking view details button
   */
  const onViewDetails = (e, rowInfo) => {
    // e.preventDefault();
    console.log('view details', rowInfo);
    setRowData(rowData);
    setOpenModal(true);
    e.stopPropagation();
  };

  const onRejectButtonClick = () => {
    console.log('onRejectmain');
    setOpenModal(false);
    setShowRejectDialog(true);
  };

  const onApproveButtonClick = () => {
    console.log('onApprovemain');
    setOpenModal(false);
    setShowApproveDialog(true);
  };

  const onCancelButtonClick = () => {
    console.log('Cancel plant user');
    setOpenModal(false);
    setShowCancelDialog(true);
  };

  const onCloneButtonClick = () => {
    console.log('onClone');
    setOpenModal(false);
    setShowCloneDialog(true);
  };

  /**
   * Tooltips are rendered based on fields, for now we will be adding tool tips to
   * headerMaterial and type
   * headerMaterial: material number and description
   * type: Original text, value, description
   *       Replacement text, value, description
   * For other fields we will just return normal MTableCell without tooltip
   */

  /**
   * Used to close the modal by setting openModal to be false.
   * This will get called when the modal is closed from it's own component.
   * So we will have to pass this reference to the parent modal
   */

  const closeModal = () => {
    setOpenModal(false);
  };

  const handleRowClick = (rowData) => {
    /**
     * Let's open the modal by setting openModal to true and before that let's just
     * set the rowData to what we have just selected
     */
    setRowData(rowData);
    setOpenModal(true);
  };

  useEffect(() => {
    updateCSVFileData(deviationsHistory);
  }, [deviationsHistory]);

  useEffect(() => {
    updateCSVFileData(filteredData);
  }, [filteredData]);

  const beginEndDate = (beginDate, endDate) => {
    let beginDate_ = convertGMTToCSTDate(beginDate);
    let endDate_ = convertGMTToCSTDate(endDate);
    return beginDate_ + ' - ' + endDate_;
  };

  const updateCSVFileData = (deviationsInfo) => {
    let csvData = deviationsInfo.map((deviation) => {
      return {
        'Dev ID': deviation.deviationId,
        'Header Material': '#' + deviation.materialNumber,
        Status: deviation.status,
        'Request Date': deviation.createdAt,
        'Begin-End': beginEndDate(deviation.beginDate, deviation.endDate),
        Description: deviation.description,
        'Original Material':
          deviation.devType === 'component' || deviation.devType === 'relabel'
            ? deviation.sapMaterialNumber
            : deviation.recipeStepName,
        'Deviation Material':
          deviation.devType === 'component' || deviation.devType === 'relabel'
            ? deviation.replacementSapMaterialNumber
            : deviation.deviationValue,
        'Plant Code': deviation.componentPlantCode
          ? deviation.componentPlantCode
          : deviation.processPlantCode,
        'Plant Description': deviation.componentPlantName
          ? deviation.componentPlantName
          : deviation.processPlantName,
        Type: deviation.devType,
      };
    });
    //setCsvFileData(csvData);
  };

  const filterChange = () => {
    //if (!isMobile) setFilteredData(tableRef.current.state.data)
  };

  const tableOptions = !isMobile
    ? {
        search: true,
        actionsColumnIndex: -1,
        filtering: true,
      }
    : {
        search: true,
        actionsColumnIndex: -1,
        headerStyle: {
          margin: '0px',
          padding: '0px',
          textAlign: 'center',
          fontSize: '12px',
        },
        rowStyle: {
          fontSize: '12px',
          margin: '0px 5px',
          padding: '0px',
        },
        tableLayout: 'auto',
      };

  return (
    <Container fluid>
      <Row>
        <Col>
          <MaterialTable
            tableRef={tableRef}
            title=""
            columns={
              !isMobile
                ? tableColumns(deviationsCustom)
                : tableColumnsMobile(deviationsCustom)
            }
            /*
              CHANGES HERE -  
              onChangePage and onChangeRowsPerPage commented both functions were deleting the filters when they were used.
              Material Table is working properly without these functions.
            */
            //onChangePage={page => pageChange(page)}
            //onChangeRowsPerPage={pageSize => rowsPerPageChange(pageSize)}
            data={deviationsCustom}
            onSearchChange={filterChange}
            icons={{
              Search: SearchIcon,
              ResetSearch: DeleteIcon,
              PreviousPage: ChevronLeft,
              NextPage: ChevronRight,
              FirstPage: FirstPage,
              LastPage: LastPage,
              SortArrow: ArrowDownward,
              AddBox: AddBox,
              Clear: Clear,
              Edit: Edit,
              Filter: FilterList,
              Check: Check,
              Remove: Remove,
              SaveAlt: SaveAlt,
              ViewColumn: ViewColumn,
            }}
            options={tableOptions}
            localization={{
              body: {
                emptyDataSourceMessage: 'No deviations to display',
              },
            }}
            onRowClick={(event, rowData) => {
              handleRowClick(rowData);
              //handleRowClick(event);
              //console.log("plant ", getFilter())
              setPlantFilter(getFilter(2));
              setHeaderFilter(getFilter(3));
            }}
            components={{
              Container: (props) => <Paper {...props} elevation={0} />,
              Body: (props) => (
                <MTableBody
                  {...props}
                  onFilterChanged={(columnId, value) => {
                    props.onFilterChanged(columnId, value);
                    changeFilter(columnId, value);
                  }}
                />
              ),
            }}
          ></MaterialTable>
        </Col>
      </Row>
      {openModal ? (
        <DeviationModal
          onGetDeviationDetails={onGetDeviationDetails}
          onGetDistributionLists={onGetDistributionLists}
          userInfo={userInfo}
          open={openModal}
          onClose={closeModal}
          deviationId={rowData.deviationId}
          onReject={onRejectButtonClick}
          onApprove={onApproveButtonClick}
          onCancel={onCancelButtonClick}
          onClone={onCloneButtonClick}
          onUpdateDeviation={onUpdateDeviation}
          onValidateDeviationMaterial={onValidateDeviationMaterial}
          refreshData={refreshData}
          onSaveDeviation={onSaveDeviation}
          onStatusChange={onStatusChange}
          setLoading={setLoading}
        />
      ) : null}

      {!isMobile ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            fontFamily: 'proxima-nova',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginTop: '20px',
          }}
        >
          <RoundButton
            text="Download Table in Excel"
            style={{ padding: '5px', borderRadius: '3%', cursor: 'pointer' }}
            onClick={() => {
              setShowLink(true);
              setTimeout(() => document.getElementById('csvLink').click(), 100);
              setTimeout(() => setShowLink(false), 100);
            }}
          />
          {showLink ? (
            <CSVLink
              id={'csvLink'}
              data={tableRef.current.state.data}
              filename={'deviations-information.csv'}
            >
              <RoundButton
                text="Download Table in Excel"
                style={{
                  padding: '5px',
                  borderRadius: '3%',
                  opacity: '0',
                  position: 'absolute',
                }}
              />
            </CSVLink>
          ) : undefined}
        </div>
      ) : undefined}
    </Container>
  );
};

export default HistoryDeviationsTable;
