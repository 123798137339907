import React, { useEffect, useState } from "react";
import { Col, Row, Container, FormGroup } from "react-bootstrap";
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormLabel, Checkbox } from '@mui/material';
import TextField from '@mui/material/TextField';
import TextInputAreaAutosize from "../../textInputAreaAutosize/TextInputAreaAutosize";
import BoldText from "../../boldText/BoldText";
import TextInputField from "../../textInputField/TextInputField";
import DateInputField from "../../dateInputField/DateInputField";
import OutLineButton from '../../outLineButton/OutLineButton';
import ValidateReplacementModal from '../../validateReplacementModal/validateReplacementModal'
import moment from 'moment-timezone';
import swal from "sweetalert2";
import "./DevDetails.css";

const ValidateButton = (props) => {

  return (
    <Row noGutters>
      <Col xs={6} className="right">
        <span style={{ paddingRight: "5px" }}>{""}</span>
      </Col>
      <Col xs={6}>
        <OutLineButton
          text="Validate Replacement(s)"
          backgroundColor="#65686B"
          onClick={props.onClickHandler}
          isClickable={true}
        />
      </Col>
    </Row>
  );
};

const DevDetails = (props) => {
  const {
    deviationDetails,
    onValidateDeviationMaterial,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    replacementValue,
    setReplacementValue,
    reasonOrDescription,
    setReasonOrDescription,
    approverNotes,
    setApproverNotes,
    userInfo,
    setCanUpdate,
    specChgReq,
    handleSpecChgChange,
    npdNumber,
    handleNpdNumberChange,
    setLoading
  } = props;
  const [editDeviationDetails, setEditDeviationDetails] = useState(undefined);
  const [showValidationModal, setShowValidationModal] = useState(false);
  const [replacementsValidated, setReplacementsValidated] = useState(false);
  const [replacementsValid, setReplacementsValid] = useState(false);
  const [replacementsValidationResults, setReplacementsValidationResults] = useState(undefined);
  const [editableButton, setEditableButton] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isAdminEdit, setIsAdminEdit] = useState(false);

  let isAdmin = userInfo?.role === "ADMIN" || userInfo?.role === "CORP"
  let editableStatus = ["EDIT", "REJECTED", "ACCEPT", "EXPIRED"]
  let isNotAdminAndCanEdit = (deviationDetails?.status === "EDIT" || deviationDetails?.status === "PENDING" || deviationDetails?.status === "EXPIRED") && !isAdmin && userInfo?.plantCodes.includes(deviationDetails?.plantCode)
  let today = new Date();
  let days = deviationDetails?.devType === 'relabel' ? 365 : 90
  const maxDate = today.setDate(today.getDate() + days)

  const handleValidateReplacements = () => {
    /**
     * if the describe type is 2 then multiple replacements validation otherwise
     * single replacement validation. this needs to be taken care later.
     * But for now clicking on validate button I am changing replacementsValidated to true
     */
    // Add the getOfficialProduct call and getZcwpDetails call to get allergens for original
    if (!deviationDetails) return;
    setLoading(true);
    let original = deviationDetails?.devType === "component" || deviationDetails?.devType === "relabel" ? deviationDetails?.sapMaterialNumber : deviationDetails?.recipeStepName
    onValidateDeviationMaterial([deviationDetails.plantCode], [replacementValue], [original]).then(result => {
      console.log("result.data: ", result.data)
      setLoading(false)
      setReplacementsValidated(false)
      setReplacementsValid(false)
      if (typeof result === "string") {
        console.log("validate deviation material error: ", result)
        swal.fire({
          icon: "warning",
          title: "No data returned from service!",
        })
        return
      }

      if (!result) {
        setLoading(false)
        setReplacementsValidated(false)
        setReplacementsValid(false)
        setCanUpdate(false)
        console.log("validate deviation material error: no result")
        swal.fire({
          icon: "warning",
          title: "No data returned from service!",
        })
        return
      }


      if (result?.data && result?.data?.isSuccess === false) {
        setLoading(false)
        setReplacementsValidated(false)
        setReplacementsValid(false)
        setCanUpdate(false)
        console.log("validate deviation material error: ", result.data)
        swal.fire({
          icon: "warning",
          title: "No data returned from service!",
        })
        return
      }
      if (!result.data?.data[0]?.validMaterial || !result?.data?.data[0]?.validPlant || !result.data?.data[0]?.validAllergens || !result.data?.data[0]?.compatibleMaterialTypes) {
        setShowValidationModal(true)
        setLoading(false)
        setReplacementsValidated(true)
        setReplacementsValid(false)
        setCanUpdate(false)
        setReplacementsValidationResults({
          materialNumber: replacementValue,
          valid: false,
          validPlant: result.data?.data[0]?.validPlant,
          validMaterial: result.data?.data[0]?.validMaterial,
          validAllergen: result.data?.data[0]?.validAllergens,
          compatibleMaterialTypes: result.data?.data[0]?.compatibleMaterialTypes,
          description: result.data?.data[0]?.description
        })
        return
      }

      setReplacementsValidationResults({
        materialNumber: replacementValue,
        valid: true,
        validPlant: true,
        validMaterial: true,
        validAllergen: true,
        compatibleMaterialTypes: true,
        description: result.data?.data[0]?.description
      })
      setShowValidationModal(true)
      setLoading(false)
      setReplacementsValidated(true);
      setReplacementsValid(true)
      setCanUpdate(true)
    })
  };

  const onValidationClose = (editValid) => {
    setShowValidationModal(false)
    if (!replacementsValidationResults) return
    let tempValidationResults = { ...replacementsValidationResults }
    if (tempValidationResults.valid) {
      // setEditDeviationDetails({ ...editDeviationDetails, replacementSapMaterialNumber: editDeviationDetails.replacementSapMaterialNumber });
      setReplacementValue(replacementValue);
    } else {
      // setEditDeviationDetails({ ...editDeviationDetails, replacementSapMaterialNumber : ""});
      setReplacementValue(deviationDetails.replacementSapMaterialNumber);
    }
    if (editValid) {
      setReplacementsValidated(false)
      setReplacementsValid(false)
    }
  }

  const onHandleBeginDate = (date) => {
    if (date === "" || date === null) {
      swal.fire({
        icon: "warning",
        title: "Start date could not be empty",
      })
      setStartDate(deviationDetails.startDate);
      setCanUpdate(false)
    } else {
      setStartDate(date);
      if (endDate < date) setEndDate(date);
      setCanUpdate(true);
    }
    //setEditDeviationDetails({ ...editDeviationDetails, beginDate: date });
    //setEditDeviationDetails({ ...editDeviationDetails, endDate: date });
  }

  const onHandleEndDate = (date) => {
    if (date === "" || date === null) {
      swal.fire({
        icon: "warning",
        title: "End Date could not be empty",
      })
      setCanUpdate(false)
      setEndDate(deviationDetails.endDate);
    } else {
      setEndDate(date);
      setCanUpdate(true);
    }
    // setEditDeviationDetails({ ...editDeviationDetails, endDate: date });
  }

  const onChangeReplacement = (e) => {
    //console.log("type ", deviationDetails.materialType);
    if (deviationDetails.devType === "component" || deviationDetails.devType === "relabel") {
      const re = /^[0-9\b]+$/;
      console.log("value component: ", re.test(e.target.value));
      if (e.target.value !== '' && re.test(e.target.value)) {
        setReplacementValue(e.target.value);
        setEditableButton(true);
        setCanUpdate(true)
      } else {
        setCanUpdate(false)
      }
    }
    if (deviationDetails.devType === "process") {
      if (e.target.value !== "") {
        setReplacementsValid(true);
        setReplacementsValidated(true);
        setReplacementValue(e.target.value);
        setCanUpdate(true)
        console.log("value: ", e.target.value);
      } else {
        setReplacementsValid(false)
        setReplacementsValidated(false)
        setCanUpdate(false)
        swal.fire({
          icon: "warning",
          title: "Required field could not be empty",
        })
        return
      }
      //setReplacementValue(e.target.value);
      //setEditDeviationDetails({ ...editDeviationDetails, replacementSapMaterialNumber: e.target.value });
    }
    setCanUpdate(true);
  }

  const onHandleReason = (e) => {
    e.target.value === "" ? setCanUpdate(false) : setCanUpdate(true)
    setReasonOrDescription(e.target.value);
    //}
    // setEditDeviationDetails({ ...editDeviationDetails, description: e.target.value });
  }

  const onHandleNotes = (e) => {
    setApproverNotes(e.target.value);
    //setEditDeviationDetails({ ...editDeviationDetails, approverNotes: e.target.value });
  }

  const DateToString = date => {
    let tIndex = date.indexOf("T")
    return date.substring(0, tIndex)
  }

  useEffect(() => {
    console.log("Deviation details:", deviationDetails);
    if (!deviationDetails) return
    //console.log("dev details: ", deviationDetails);
    setEditDeviationDetails({ ...deviationDetails });
    deviationDetails.devType === "component" || deviationDetails.devType === "relabel" ? setReplacementValue(deviationDetails.replacementSapMaterialNumber) : setReplacementValue(deviationDetails.deviationValue)
    if (editableStatus.includes(deviationDetails.status) && (userInfo?.role === "CORP" || userInfo?.role === "ADMIN")) { setIsAdminEdit(true); }
    else { setIsAdminEdit(false); }
    console.log("deviationDetails.beginDate: ", deviationDetails.beginDate)
    let beginDate = moment(deviationDetails.beginDate, 'YYYY-MM-DD').toDate()
    let endDate = moment(deviationDetails.endDate, 'YYYY-MM-DD').toDate()
    console.log("beginDate: ", beginDate)
    console.log("endDate: ", endDate)
    setStartDate(beginDate);
    setEndDate(endDate);
    setReasonOrDescription(deviationDetails.description);
    setApproverNotes(approverNotes);
    setCanUpdate(false);

  }, [deviationDetails]);

  return (
    <Container fluid>
      <hr />
      <BoldText text="1. Dev Details" />
      <TextInputField
        title="Header Material:"
        value={deviationDetails?.materialNumber ?? ""}
        type="tel"
        isDisabled={true}
        isMultiline={false}
      />
      <TextInputField
        title="Plant(s):"
        value={deviationDetails?.plantName ?? ""}
        type="text"
        isDisabled={true}
        isMultiline={false}
      />
      <TextInputField
        title="Original(s):"
        value={deviationDetails?.devType === "component" || deviationDetails?.devType === "relabel" ? deviationDetails?.sapMaterialNumber : deviationDetails?.recipeStepName}
        isDisabled={true}
        type={deviationDetails?.devType === "component" || deviationDetails?.devType === "relabel" ? "tel" : "text"}
        isMultiline={false}
      />

      <TextInputField
        title="Replacement:"
        value={replacementValue ?? ""}
        required={deviationDetails?.status === "EDIT" || deviationDetails?.status === "REJECTED" || deviationDetails?.status === "PENDING"}
        // isDisabled={deviationDetails ? (deviationDetails.status === "PENDING" ? isAdminEdit ? false : true) : isEditable}
        isDisabled={deviationDetails?.devType === "component" || deviationDetails?.devType === "relabel"}
        type={deviationDetails?.devType === "component" || deviationDetails?.devType === "relabel" ? "tel" : "text"}
        onChangeValue={onChangeReplacement}
        isMultiline={false}
      />

      {editableButton ? <ValidateButton onClickHandler={handleValidateReplacements} /> : null}
      {/* <ValidateButton
        onClickHandler={handleValidateReplacements} /> */}
      <DateInputField
        title="Dev Begin:"
        required={true}
        dateValue={startDate ?? ""}
        isDisabled={isAdminEdit ? false : isNotAdminAndCanEdit ? false : true}
        onChangeValue={onHandleBeginDate}
        isEndDate={false}
        maxDate={maxDate}
      // minDate={startDate}
      />

      <DateInputField
        title="Dev End:"
        required={true}
        dateValue={endDate ?? ""}
        isDisabled={isAdminEdit ? false : isNotAdminAndCanEdit ? false : true}
        onChangeValue={onHandleEndDate}
        isEndDate={true}
        maxDate={maxDate}
        minDate={startDate}
      />

      <TextInputAreaAutosize
        title="Reason/Description:"
        required={true}
        value={reasonOrDescription ?? ""}
        isDisabled={isAdminEdit ? false : isNotAdminAndCanEdit ? false : true}
        onChangeValue={onHandleReason}
        rows={3}
        maxLength={1000}
      />
      {(userInfo?.role === 'CORP' || userInfo?.role === "ADMIN") ? (
        <FormGroup style={{ marginBottom: '0.5rem' }}>
          <FormControlLabel
            component="legend"
            control={
              <Checkbox
                onChange={editableStatus.includes(deviationDetails?.status) ? (e) => handleSpecChgChange(e) : undefined}
                color="default"
              />
            }
            checked={specChgReq}
            label="Spec change required"
          />
        </FormGroup>
      ) : undefined}
      {specChgReq === true ? (
        <Row>
          <Col className="text-right pr-1">
            <span style={{ color: 'red' }}>*</span>
            <FormLabel component="span" style={{ color: "rgba(0,0,0,0.87)", fontSize: "14px" }}>
              Npd number:
            </FormLabel>
          </Col>
          <Col className="pl-0 pb-1">
            <TextField
              size="small"
              variant="outlined"
              className="inputStyle"
              value={npdNumber}
              onChange={(e) => handleNpdNumberChange(e)}
            />
          </Col>
        </Row>
      ) : undefined}

      {deviationDetails?.status !== "EDIT" ? <TextInputField
        title={deviationDetails?.status === "REJECTED" ? "Rejected notes:" : "Approver notes:"}
        value={deviationDetails?.status === "PENDING" ? approverNotes ?? "" : deviationDetails?.notes}
        isDisabled={deviationDetails?.status === "PENDING" && isAdmin ? false : true}
        type="text"
        onChangeValue={onHandleNotes}
        isMultiline={true}
      /> : undefined}

      {showValidationModal ? <ValidateReplacementModal
        open={showValidationModal}
        onClose={onValidationClose}
        replacements={replacementsValidationResults ? [replacementsValidationResults] : undefined}
        replacementsValid={replacementsValid}
      /> : undefined}
    </Container>
  );
};

export default DevDetails;
