// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}
.right {
  text-align: right;
  /* background-color: red; */
  padding-right: 20px;
}
.left {
  text-align: left;
  /* background-color: orange; */
}
`, "",{"version":3,"sources":["webpack://./src/components/deviationModal/devDetails/DevDetails.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,oBAAoB;AACtB;AACA;EACE,iBAAiB;EACjB,2BAA2B;EAC3B,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,8BAA8B;AAChC","sourcesContent":[".nopadding {\n  padding: 0 !important;\n  margin: 0 !important;\n}\n.right {\n  text-align: right;\n  /* background-color: red; */\n  padding-right: 20px;\n}\n.left {\n  text-align: left;\n  /* background-color: orange; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
