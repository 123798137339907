import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import swal from "sweetalert2";
import { Form } from 'react-bootstrap';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Container from '@mui/material/Container';
import OutLineButton from '../outLineButton/OutLineButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ChooseDeviationsModal from '../createNewRequestMobile/chooseDeviationsModal/ChooseDeviationsModal';
import './CreateNewRequest.css';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    //marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    //marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


const CreateNewRequest = (props) => {
  const {
    setShowCreateDevPage,
    headerMaterialPlants,
    onGetAllPlantsForHeaderMaterial,
    onGetOfficialProduct,
    onGetDeviationDetails,
    handlePlantSelection,
    setDeviationDetails,
    selectedPlants,
    onValidateZcwpMaterial,
    materialNumber,
    setMaterialNumber,
    setDescribeType,
    describeType,
    deviationType,
    setDeviationType,
    deviationsHistory,
    deviationsPending,
    IWantTo,
    setIWantTo,
    clearCreateRequest,
    validMaterial,
    setValidMaterial,
    submitDisabled,
    setSubmitDisabled,
    plantType,
    setPlantType,
    handleStartOver,
    onSetOfficialProduct,
    onSetZcwpDetails,
    onSetLastSavedDeviations,
    userInfo,
    setLoading,
  } = props;

  //iWantTo is going to be used in Plant user role, either you want to start from scrath or clone an existing one


  useEffect(() => {
    console.log('selectedPlants: ', selectedPlants);
    if (selectedPlants.length === 0) {
      setSubmitDisabled(true);
    }

    if (selectedPlants.length > 0) {
      setSubmitDisabled(false);
    }
  }, [selectedPlants]);

  useEffect(() => {
    if (clearCreateRequest) {
      handleStartOver();
    }
  }, [clearCreateRequest])

  useEffect(() => {
    onSetOfficialProduct(undefined)
    onSetZcwpDetails(undefined)
    onSetLastSavedDeviations([])
  }, [])

  const onFilterDeviation = () => {
    if (!deviationsHistory) return;
    let auxArray = deviationsHistory.filter(deviation => deviation.devType == deviationType);
    return auxArray;
  }

  const handleTDChange = (event) => {
    console.log('event', event.target.value)
    setIWantTo('brandNew')
    setDeviationType(event.target.value);
    setMaterialNumber('')
    setDescribeType('')
    setPlantType('')
    handlePlantSelection(false, '', '', true);
    setValidMaterial(false)
    setSubmitDisabled(true)
  };

  const handleAllocationChange = (event) => {
    console.log("handleAllocationChange: ", event.target.value)
    setDescribeType(event.target.value);
    handlePlantSelection(false, '', '', true);
    setPlantType('');
  };

  const handlePlantSelect = (e, multiple) => {
    if (!multiple) {
      setPlantType(e.target.value);
    }
    handlePlantSelection(multiple, e.target.value, e.target.checked, false);
  };

  const handleIWantTo = (e) => {
    setIWantTo(e.target.value);
    setDeviationDetails('')
    setMaterialNumber('')
    setDescribeType('')
    setPlantType('')
    handlePlantSelection(false, '', '', true);
    setValidMaterial(false)
    setSubmitDisabled(true)
  };

  const onDeviationsTableClose = () => {
    setIWantTo('brandNew');
  };

  const materialNumberHandler = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      setShowCreateDevPage(false);
      setValidMaterial(false);
      setMaterialNumber(event.target.value);
    }
  };

  const validateMaterial = () => {
    /**
     * We may call api here and before getting the response we will show the loader
     */
    setPlantType('')
    handlePlantSelection(false, '', '', true);
    setLoading(true);
    onGetAllPlantsForHeaderMaterial(materialNumber).then((res) => {
      console.log('res: ', res);
      console.log('typeof res: ', typeof res);
      if (typeof res === 'string') {
        setLoading(false);
        swal.fire({
          icon: 'warning',
          title: 'No data returned from service',
          timer: 2000,
        });
        return;
      }
      if (res.data && res.data.isSuccess === false) {
        setLoading(false);
        swal.fire({
          icon: 'warning',
          title: 'No plants found for material',
          timer: 2000,
        });
        return;
      }
      onGetOfficialProduct(materialNumber).then((res) => {
        console.log('res: ', res);
        console.log('typeof res: ', typeof res);
        if (typeof res === 'string') {
          setLoading(false);
          swal.fire({
            icon: 'warning',
            title: 'No data returned from service',
            timer: 3000,
          });
          return;
        }
        if (typeof res.data.data === 'string') {
          console.log('material number not found on GSM: ', res.data.data);
          console.log('looking for material on SAP...');
          onValidateZcwpMaterial(materialNumber).then((res) => {
            console.log('res zcwp: ', res);
            if (!res.data.data || res.data.data.length === 0) {
              setLoading(false);
              swal.fire({
                icon: 'warning',
                title: `No data found for material number ${materialNumber}`,
              });
              return;
            }
            setLoading(false);
            setValidMaterial(true)
          });
        } else {
          setLoading(false);
          setValidMaterial(true);
        }
      });
    });
  };

  const plants = headerMaterialPlants.map((plant) => {
    if (describeType === '1' && deviationType !== 'relabel') {
      return (
        <FormControlLabel
          key={plant.Plant + ' ' + plant.Descr}
          control={<Checkbox color="default" size="small" />}
          value={plant.Plant + ' ' + plant.Descr}
          label={plant.Plant + ' ' + plant.Descr}
          onChange={(e) => handlePlantSelect(e, true)}
        />
      );
    } if (describeType !== '1' || (describeType === '1' && deviationType === 'relabel')) {
      return (
        <FormControlLabel
          key={plant.Plant + ' ' + plant.Descr}
          control={<Radio color="default" size="small" />}
          value={plant.Plant + ' ' + plant.Descr}
          label={plant.Plant + ' ' + plant.Descr}
        />
      );
    }
  });



  const onClonedItemSelected = (deviation) => {
    setMaterialNumber(deviation?.materialNumber)
    console.log("deviation to clone: ", deviation)
    onGetDeviationDetails(deviation.deviationId).then((res) => {
      setLoading(true);
      if (!res?.data?.isSuccess) {
        swal.fire({
          icon: 'warning',
          title: 'No data returned from service',
          timer: 3000,
        });
        setLoading(false);
        return;
      }
      setDeviationDetails(res.data.data);
    });

    onGetOfficialProduct(deviation.materialNumber).then((res) => {
      console.log('res: ', res);
      console.log('typeof res: ', typeof res);
      if (typeof res === 'string') {
        swal.fire({
          icon: 'warning',
          title: 'No data returned from service',
          timer: 3000,
        });
        setLoading(false);
        return;
      }
      console.log("res ", res);
      if (typeof res.data.data !== 'string') {
        setShowCreateDevPage(true);
        setLoading(false);
        return
      }

      console.log('material number not found on GSM: ', res.data.data);
      console.log('looking for material on SAP...');
      onValidateZcwpMaterial(deviation.materialNumber).then((result) => {
        if (!result.data.data || result.data.data.length === 0) {
          swal.fire({
            icon: 'warning',
            title: `No data found for material number ${deviation.materialNumber}`,
          });
          setLoading(false);
          return;
        }
        setShowCreateDevPage(true);
        setLoading(false);
      });
    });

  };
  const onClickContinue = () => {
    setShowCreateDevPage(true);
    setDeviationDetails('');
  }

  return (
    <Container component="main" maxWidth="lg" className="m-0 p-0">
      <CssBaseline />
      <div>
        <>
          <Grid
            container
            spacing={2}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={12}>
              {deviationType ? (
                <div
                  style={{
                    fontSize: '16px',
                    color: '#65686B',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  className="float-right"
                  onClick={() => handleStartOver()}
                >
                  {'Start Over'}
                </div>
              ) : null}
            </Grid>
            <Grid
              style={{ textAlign: "left" }}
              item
              xs={12}
              sm={6}
            >
              <FormControl component="fieldset">
                <div
                  className="sub-title"
                  style={{
                    marginLeft: '0px',
                    marginTop: '0px',
                    display: 'block',
                  }}
                >
                  1. I am creating a
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                <RadioGroup
                  aria-label="deviationType"
                  name="deviationType"
                  value={deviationType}
                  onChange={handleTDChange}
                >
                  <FormControlLabel
                    value="component"
                    control={<Radio color="default" size="small" />}
                    label="Component"
                    color="default"
                  />
                  <FormControlLabel
                    value="process"
                    control={<Radio color="default" size="small" />}
                    label="Process"
                    style={{ margin: '0px', padding: '0px' }}
                  />
                  <FormControlLabel
                    value="relabel"
                    control={<Radio color="default" size="small" />}
                    label="Relabel"
                    style={{ margin: '0px', padding: '0px' }}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              {validMaterial && IWantTo === 'brandNew' ? (
                <FormControl component="fieldset">
                  <span className="sub-title">
                    3. Choose one that best describes this dev:
                  </span>
                  <RadioGroup
                    aria-label="deviationAllocation"
                    name="deviationAllocation"
                    value={describeType}
                    onChange={handleAllocationChange}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio color="default" size="small" />}
                      label="One original - One replacement"
                    />
                    {deviationType !== 'relabel' ? <><FormControlLabel
                      value="2"
                      control={<Radio color="default" size="small" />}
                      label="One original - Multiple replacements"
                    />
                      <FormControlLabel
                        value="3"
                        control={<Radio color="default" size="small" />}
                        label="Multiple originals - One replacement"
                      /> </> : undefined}
                  </RadioGroup>
                </FormControl>
              ) : null}
            </Grid>
            {deviationType.length > 0 ? (
              <Grid
                item
                xs={12}
                sm={6}
                style={{ textAlign: "left" }}
              >
                <FormControl component="fieldset">
                  <span
                    className="sub-title"
                  >
                    I want to
                  </span>
                  <RadioGroup
                    aria-label="iWantTo"
                    name="iWantTo"
                    value={IWantTo}
                    onChange={handleIWantTo}
                  >
                    <FormControlLabel
                      value="brandNew"
                      control={<Radio color="default" size="small" />}
                      label="Start a brand new request"
                    />
                    <FormControlLabel
                      value="cloneExisting"
                      control={<Radio color="default" size="small" />}
                      label="Clone from an existing deviation"
                    />
                  </RadioGroup>
                </FormControl>
                {deviationType && IWantTo === 'brandNew' ? (
                  <Grid item xs={12} sm={12}>
                    <FormControl
                      style={{
                        marginTop: '10px',
                        justifyContent: 'center',
                      }}
                    >
                      <span className="sub-title">
                        2. I have the header material number, which is:
                      </span>
                      <div style={{ textAlign: "center" }}>
                        <input
                          type="tel"
                          value={materialNumber}
                          maxLength="11"
                          style={{ marginBottom: '10px', marginTop: '10px' }}
                          onChange={(e) => {
                            materialNumberHandler(e);
                          }}
                        />
                        <br />
                        <OutLineButton
                          onClick={() => validateMaterial()}
                          text="Validate"
                          isClickable={
                            materialNumber.length === 11 ? true : false
                          }
                          backgroundColor="#65686B"
                        />
                      </div>
                    </FormControl>
                  </Grid>
                ) : null}
              </Grid>
            ) : null}

            <Grid item xs={12} sm={6} style={{ textAlign: "left" }}>
              {describeType && validMaterial && IWantTo === 'brandNew' ? (
                <FormControl component="fieldset">
                  <span className="sub-title">4. This is for plant(s):</span>
                  {describeType !== '1' ? <><RadioGroup
                    name="plantType"
                    value={plantType}
                    onChange={(e) => handlePlantSelect(e, describeType === "1" && deviationType !== 'relabel' ? true : false)}
                  >
                    {headerMaterialPlants ? plants : undefined}
                  </RadioGroup>
                    <FormHelperText>
                      You can choose one plant
                    </FormHelperText></>
                    : describeType === '1' && deviationType !== 'relabel' ?
                      <>
                        <Form.Group controlId="devType">
                          {headerMaterialPlants ? plants : undefined}
                        </Form.Group>
                        <FormHelperText>
                          You can choose multiple plants
                        </FormHelperText>
                      </> : <><RadioGroup
                        name="plantType"
                        value={plantType}
                        onChange={(e) => handlePlantSelect(e, describeType === "1" && deviationType !== 'relabel' ? true : false)}
                      >
                        {headerMaterialPlants ? plants : undefined}
                      </RadioGroup>
                        <FormHelperText>
                          You can choose one plant
                        </FormHelperText></>}
                </FormControl>
              ) : undefined}
            </Grid>
          </Grid>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
              alignItems: 'end',
            }}
          >
            {!submitDisabled ? (
              <button
                style={{
                  backgroundColor: '#65686B',
                  color: 'white',
                  borderRadius: '10px',
                  border: 'none',
                  padding: '5px',
                  cursor: 'pointer',
                }}
                onClick={onClickContinue}
              >
                Continue{' '}
                <span style={{ width: '10px', height: '10px' }}>
                  <ArrowForwardIosIcon fontSize="small" />
                </span>
              </button>
            ) : (
              <button
                style={{
                  backgroundColor: 'white',
                  color: '#a0a0a0',
                  borderRadius: '10px',
                  border: '1px solid #A0A0A0',
                  padding: '5px',
                }}
                disabled={true}
              >
                Continue{' '}
                <span style={{ width: '10px', height: '10px' }}>
                  <ArrowForwardIosIcon fontSize="small" />
                </span>
              </button>
            )}
          </div>
        </>
      </div>
      {IWantTo === 'cloneExisting' ? (
        <ChooseDeviationsModal
          deviations={onFilterDeviation()}
          onCloseModal={onDeviationsTableClose}
          onDeviationSelected={onClonedItemSelected}
        />
      ) : null}
    </Container>
  );
}

export default CreateNewRequest
