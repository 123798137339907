import React from 'react';
import './DisabledText.css';

const DisabledText = (props) => {
    const {text,style}=props;
    return (
        <input
            className="disabled-text"
            style={style}
            width="auto"
            type="text"
            disabled
            value={text}
        ></input>
    );
};

export default DisabledText;