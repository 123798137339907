import React from 'react';
import { Container } from 'react-bootstrap';
import AccordionDesktop from '../../components/accordionPC/accordion'

const DesktopHomePage = (props) => {

    const { deviationsPending,
        myDeviationsHistory,
        deviationsHistory,
        headerMaterialPlants,
        onGetAllPlantsForHeaderMaterial,
        onGetOfficialProduct,
        onGetDeviationDetails,
        onValidateZcwpMaterial,
        onValidateDeviationMaterial,
        officialProductDetails,
        onGetDistributionLists,
        zwcpDetails,
        userInfo,
        onSaveDeviation,
        lastSavedDeviations,
        onStatusChange,
        onSetOfficialProduct,
        onSetZcwpDetails,
        onSetLastSavedDeviations,
        onMarkUnmarkFavoritePlants,
        onUpdateDeviation,
        refreshData,
        getAllDeviations,
        paginationParametersPending,
        paginationParametersHistory,
        onSetPaginationParametersPending,
        onSetPaginationParametersHistory,
        setView,
        toggleOnlyFavorites,
        showOnlyFavorites,
        additionalComponents,
        onGetAdditionalComponents,
        onSetAdditionalComponents,
        filteredDeviationsByStatus,
        filteredMyDeviationsByStatus,
        tabIndex,
        onTabChange,
        loading,
        setLoading,
        arrowClick,
        currentMonthFiscalYear
        } = props

    return (
      <Container fluid>
        <AccordionDesktop
          showOnlyFavorites={showOnlyFavorites}
          toggleOnlyFavorites={toggleOnlyFavorites}
          deviationsPending={deviationsPending}
          myDeviationsHistory={myDeviationsHistory}
          deviationsHistory={deviationsHistory}
          headerMaterialPlants={headerMaterialPlants}
          onGetAllPlantsForHeaderMaterial={onGetAllPlantsForHeaderMaterial}
          onGetOfficialProduct={onGetOfficialProduct}
          onGetDeviationDetails={onGetDeviationDetails}
          onValidateZcwpMaterial={onValidateZcwpMaterial}
          onValidateDeviationMaterial={onValidateDeviationMaterial}
          officialProductDetails={officialProductDetails}
          onGetDistributionLists={onGetDistributionLists}
          zwcpDetails={zwcpDetails}
          userInfo={userInfo}
          onSaveDeviation={onSaveDeviation}
          lastSavedDeviations={lastSavedDeviations}
          onStatusChange={onStatusChange}
          onSetOfficialProduct={onSetOfficialProduct}
          onSetZcwpDetails={onSetZcwpDetails}
          onSetLastSavedDeviations={onSetLastSavedDeviations}
          onUpdateDeviation={onUpdateDeviation}
          onMarkUnmarkFavoritePlants={onMarkUnmarkFavoritePlants}
          getAllDeviations={getAllDeviations}
          refreshData={refreshData}
          paginationParametersPending={paginationParametersPending}
          onSetPaginationParametersPending={onSetPaginationParametersPending}
          paginationParametersHistory={paginationParametersHistory}
          onSetPaginationParametersHistory={onSetPaginationParametersHistory}
          setView={setView}
          additionalComponents={additionalComponents}
          onGetAdditionalComponents={onGetAdditionalComponents}
          onSetAdditionalComponents={onSetAdditionalComponents}
          filteredDeviationsByStatus={filteredDeviationsByStatus}
          filteredMyDeviationsByStatus={filteredMyDeviationsByStatus}
          tabIndex={tabIndex}
          onTabChange={onTabChange}
          loading={loading}
          setLoading={setLoading}
          arrowClick={arrowClick}
          currentMonthFiscalYear={currentMonthFiscalYear}
        />
      </Container>
    );
};

export default DesktopHomePage;
