// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-root{
}
.header-title{
    font-size: 16px;
    color: #002554;
    font-weight: bold;
    text-align: center;
    opacity: 1;
}
.sub-title{
    color: #65686B;
    text-align: center;
    font-size: 14px;

}
.sub-title span{
    color: red;
}`, "",{"version":3,"sources":["webpack://./src/components/deviationModal/deviationModalHeader/DeviationModalHeader.css"],"names":[],"mappings":"AAAA;AACA;AACA;IACI,eAAe;IACf,cAAc;IACd,iBAAiB;IACjB,kBAAkB;IAClB,UAAU;AACd;AACA;IACI,cAAc;IACd,kBAAkB;IAClB,eAAe;;AAEnB;AACA;IACI,UAAU;AACd","sourcesContent":[".header-root{\n}\n.header-title{\n    font-size: 16px;\n    color: #002554;\n    font-weight: bold;\n    text-align: center;\n    opacity: 1;\n}\n.sub-title{\n    color: #65686B;\n    text-align: center;\n    font-size: 14px;\n\n}\n.sub-title span{\n    color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
