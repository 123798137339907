import React, { useEffect, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import moment from 'moment-timezone';
import swal from 'sweetalert2';
import DeviationModalHeader from './deviationModalHeader/DeviationModalHeader';
import DevDetails from './devDetails/DevDetails';
import ContactInfo from './contactInfo/ContactInfo';
import ButtonRow from './buttonRow/buttonRow';
import UpdateDialog from './updateDialog/UpdateDialog';
import CloneDialog from './cloneDialog/CloneDialog';
import SubmitDialog from './submitDialog/SubmitDialog';
import ApproveDialog from './approveDialog/ApproveDialog';
import RejectDialog from './rejectDialog/RejectDialog';
import CancelDialog from './cancelDialog/CancelDialog';
import './DeviationModal.css';

const DeviationModal = (props) => {
  // console.log("ComponentDevModal", props);
  // Hardcoding roles until we have Okta Roles in place
  // role can be "CORP", "ADMIN" or "PLANT"
  /**
   * Not sure if we get the beginEnd as one value or two different values,
   * Not sure if we get the status value from server, but for now I am just assuming
   * that we get the status from api and the default staus is Pending.
   */

  const {
    open,
    onClose,
    onGetDeviationDetails,
    onGetDistributionLists,
    onValidateDeviationMaterial,
    onUpdateDeviation,
    userInfo,
    refreshData,
    onSaveDeviation,
    onStatusChange,
    deviationId,
    setLoading,
  } = props;

  const [distributionLists, setDistributionLists] = useState(undefined);
  const [deviationDetails, setDeviationDetails] = useState(undefined);
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);
  const [showCloneDialog, setShowCloneDialog] = useState(false);
  const [showSubmitDialog, setShowSubmitDialog] = useState(false);
  const [showApproveDialog, setShowApproveDialog] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showRejectDialog, setShowRejectDialog] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [replacementValue, setReplacementValue] = useState('');
  const [reasonOrDescription, setReasonOrDescription] = useState('');
  const [approverNotes, setApproverNotes] = useState('');
  const [specChgReq, setSpecChgReq] = useState(false);
  const [npdNumber, setNpdNumber] = useState('');

  const [approversSelected, setApproversSelected] = useState([]);
  const [individualEmailsList, setIndividualEmailsList] = useState('');
  const [ccIndividualEmailsList, setccIndividualEmailsList] = useState('');
  const [ccSelected, setCCSelected] = useState([]);
  const [contactInfoVerified, setContactInfoVerified] = useState(false);
  const [rejectionComments, setRejectionComments] = useState('');

  const validateEnteredEmailsList = (enteredEmails) => {
    var emails = enteredEmails.split(',');
    var valid = true;
    var regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    for (var i = 0; i < emails.length; i++) {
      if (!regex.test(emails[i].replace(/\s/g, ''))) {
        valid = false;
      }
    }
    return valid;
  };

  const isIndividualEmailsListValid = (individualsInfo) => {
    if (individualsInfo.trim().length == 0) {
      return true;
    }
    return validateEnteredEmailsList(individualsInfo);
  };

  const atLeastOneEmailSelected = (emailsList) => {
    return emailsList.find((email) => email.selected === true);
  };

  const handleSpecChgChange = (e) => {
    if (e.target.checked === false) {
      setNpdNumber('');
    }
    setSpecChgReq(e.target.checked);
  };

  const handleNpdNumberChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setNpdNumber(e.target.value);
    }
  };

  const validateFields = () => {
    if (
      !startDate ||
      !endDate ||
      !replacementValue ||
      replacementValue === '' ||
      !reasonOrDescription ||
      reasonOrDescription === ''
    ) {
      swal.fire({
        icon: 'warning',
        title: 'Please fill mandatory fields',
        timer: 3000,
      });
      return false;
    }

    console.log('specChgReq: ', specChgReq);
    if (specChgReq) {
      console.log('npdNumber: ', npdNumber);
      if (!npdNumber || npdNumber === '') {
        swal.fire({
          icon: 'warning',
          title: 'Please fill mandatory fields',
          timer: 3000,
        });
        return false;
      }
    }
    return true;
  };

  const onSubmit = () => {
    if (validateFields() === false) return;
    if (!contactInfoVerified) {
      swal.fire({
        icon: 'warning',
        title: 'Please fill mandatory contact info fields',
        timer: 3000,
      });
      return;
    }
    let cc = ccSelected
      .filter((cc) => cc.selected)
      .map((cc) => cc.name)
      .join(',');
    let emailTo = approversSelected
      .filter((approver) => approver.selected)
      .map((app) => app.name)
      .join(',');
    let deviation = [
      {
        deviationId: deviationDetails.deviationId,
        statusChange: 'PENDING',
        editUser: userInfo?.name,
        editReason: 'Deviation submitted',
        emailTo,
        cc,
        customEmailTo: individualEmailsList,
        customCC: ccIndividualEmailsList,
      },
    ];
    console.log('deviation onSubmit: ', deviation);
    // setLoading(true)
    onStatusChange(deviation).then((result) => {
      if (typeof result === 'string' || !result) {
        setLoading(false);
        swal.fire({
          icon: 'error',
          title: 'Error returned from service',
          text: result ?? '',
        });
        return;
      }
      if (!result?.data?.isSuccess) {
        setLoading(false);
        swal.fire({
          icon: 'error',
          title: 'Could not submit deviation(s)',
          text: result.data.data.error?.message,
        });
        return;
      }
      setLoading(false);
      setShowSubmitDialog(true);
    });
  };

  const onApprove = () => {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    if (validateFields() === false) return;
    // if (startDate < today) {
    //   swal.fire({
    //     icon: "warning",
    //     title: "You cannot approve a deviation if its start date already passed",
    //     timer: 5000,
    //   })
    //   return
    // }
    if (!contactInfoVerified) {
      swal.fire({
        icon: 'warning',
        title: 'Please fill mandatory contact info fields',
        timer: 3000,
      });
      return;
    }
    let cc = ccSelected
      .filter((cc) => cc.selected)
      .map((cc) => cc.name)
      .join(',');
    let emailTo = approversSelected.map((app) => app.name).join(',');
    let deviation = [
      {
        deviationId: deviationDetails.deviationId,
        statusChange: 'ACCEPT',
        editUser: userInfo?.name,
        editReason: approverNotes,
        emailTo,
        cc,
        customEmailTo: individualEmailsList,
        customCC: ccIndividualEmailsList,
      },
    ];
    console.log('deviation onApprove: ', deviation);
    onStatusChange(deviation).then((result) => {
      if (typeof result === 'string' || !result) {
        setLoading(false);
        swal.fire({
          icon: 'error',
          title: 'Error returned from service',
          text: result ?? '',
        });
        return;
      }
      if (!result?.data?.isSuccess) {
        setLoading(false);
        swal.fire({
          icon: 'error',
          title: 'Could not submit deviation(s)',
          text: result.data.data.error?.message,
        });
        return;
      }
      setLoading(false);
      setShowApproveDialog(true);
    });
  };

  const onReject = () => {
    if (validateFields() === false) return;
    if (!contactInfoVerified) {
      swal.fire({
        icon: 'warning',
        title: 'Please fill mandatory contact info fields',
        timer: 3000,
      });
      return;
    }
    let cc = ccSelected
      .filter((cc) => cc.selected)
      .map((cc) => cc.name)
      .join(',');
    let emailTo = approversSelected.map((app) => app.name).join(',');
    emailTo += `,${deviationDetails.userEmail}`;
    console.log(emailTo);
    let deviation = [
      {
        deviationId: deviationDetails.deviationId,
        statusChange: 'REJECTED',
        editUser: userInfo?.name,
        editReason: rejectionComments,
        emailTo,
        cc,
        customEmailTo: individualEmailsList,
        customCC: ccIndividualEmailsList,
      },
    ];
    console.log('deviation onReject: ', deviation);
    onStatusChange(deviation).then((result) => {
      if (typeof result === 'string' || !result) {
        setLoading(false);
        swal.fire({
          icon: 'error',
          title: 'Error returned from service',
          text: result ?? '',
        });
        return;
      }
      if (!result?.data?.isSuccess) {
        setLoading(false);
        swal.fire({
          icon: 'error',
          title: 'Could not reject deviation(s)',
          text: result.data.data.error?.message,
        });
        return;
      }
      setLoading(false);
      setShowRejectDialog(false);
      onClose();
      refreshData();
    });
  };

  const onCancel = () => {
    if (validateFields() === false) return;
    if (!contactInfoVerified) {
      swal.fire({
        icon: 'warning',
        title: 'Please fill mandatory contact info fields',
        timer: 3000,
      });
      return;
    }
    let cc = ccSelected
      .filter((cc) => cc.selected)
      .map((cc) => cc.name)
      .join(',');
    let emailTo = approversSelected.map((app) => app.name).join(',');
    let deviation = [
      {
        deviationId: deviationDetails.deviationId,
        statusChange: 'CANCEL',
        editUser: userInfo?.name,
        editReason: approverNotes,
        emailTo,
        cc,
        customEmailTo: individualEmailsList,
        customCC: ccIndividualEmailsList,
      },
    ];
    console.log('deviation onCancel: ', deviation);
    onStatusChange(deviation).then((result) => {
      if (typeof result === 'string' || !result) {
        setLoading(false);
        swal.fire({
          icon: 'error',
          title: 'Error returned from service',
          text: result ?? '',
        });
        return;
      }
      if (!result?.data?.isSuccess) {
        setLoading(false);
        swal.fire({
          icon: 'error',
          title: 'Could not cancel deviation(s)',
          text: result.data.data.error?.message,
        });
        return;
      }
      setLoading(false);
      setShowCancelDialog(true);
    });
  };

  const onClone = () => {
    if (validateFields() === false) return;

    let correctedDescribeType = 0;

    console.log('correctedDescribeType: ', correctedDescribeType);

    let deviationData = {
      materialNumber: deviationDetails.materialNumber,
      originals:
        deviationDetails.devType === 'component' ||
        deviationDetails.devType === 'relabel'
          ? deviationDetails.sapMaterialNumber
          : '',
      replacements:
        deviationDetails.devType === 'component' ||
        deviationDetails.devType === 'relabel'
          ? replacementValue
          : '',
      createUser: userInfo?.name,
      beginDate: startDate.toISOString().split('T')[0],
      endDate: endDate.toISOString().split('T')[0],
      description: reasonOrDescription,
      type: deviationDetails.devType,
      specChange: specChgReq ? 1 : 0,
      npdNumber,
      ecmNumber: '',
      userEmail: userInfo?.email,
      plantCode: deviationDetails.plantCode,
      status: 'EDIT',
      multipleType: correctedDescribeType,
      recipeStepName:
        deviationDetails.devType === 'component' ||
        deviationDetails.devType === 'relabel'
          ? ''
          : deviationDetails.recipeStepName,
      deviationValue:
        deviationDetails.devType === 'component' ||
        deviationDetails.devType === 'relabel'
          ? ''
          : replacementValue,
      deletedAt: null,
    };
    console.log('deviationData: ', deviationData);
    // setLoading(true)
    onSaveDeviation(deviationData, false).then((result) => {
      console.log('result: ', result);
      if (typeof result === 'string' || !result) {
        setLoading(false);
        swal.fire({
          icon: 'error',
          title: 'Error returned from service',
          text: result ?? '',
        });
        return;
      }
      console.log('result.data.isSuccess: ', result?.data?.isSuccess);
      if (!result?.data?.isSuccess) {
        console.log('result.data.data.error', result.data.data.error);
        setLoading(false);
        swal.fire({
          icon: 'error',
          title: 'Could not clone deviation(s)',
          text: result.data.data.error?.message,
        });
        return;
      }
      setLoading(false);
      setShowCloneDialog(true);
      // swal.fire({
      //   icon: "success",
      //   title: "Deviation(s) successfully clone!"
      // })
    });
  };

  const onUpdateData = () => {
    if (validateFields() === false) return;
    let devData = {};
    if (canUpdate && deviationDetails) {
      let cc = ccSelected
        .filter((cc) => cc.selected)
        .map((cc) => cc.name)
        .join(',');
      let emailTo = approversSelected.map((app) => app.name).join(',');
      devData = {
        deviationId: deviationId,
        beginDate: startDate.toISOString().split('T')[0],
        endDate: endDate.toISOString().split('T')[0],
        description: reasonOrDescription,
        specChange: specChgReq ? 1 : 0,
        npdNumber: npdNumber,
        ecmNumber: deviationDetails.ecmNumber,
        editUser: userInfo?.name,
        editReason: reasonOrDescription,
        status:
          deviationDetails.status === 'REJECTED'
            ? 'EDIT'
            : deviationDetails.status,
        original:
          deviationDetails.devType === 'component' ||
          deviationDetails.devType === 'relabel'
            ? deviationDetails.sapMaterialNumber
            : '',
        replacement:
          deviationDetails.devType === 'component' ||
          deviationDetails.devType === 'relabel'
            ? replacementValue
            : '',
        recipeStepName:
          deviationDetails.devType === 'process'
            ? deviationDetails.recipeStepName
            : '',
        deviationValue:
          deviationDetails.devType === 'process' ? replacementValue : '',
        materialNumber: deviationDetails.materialNumber,
        plantCode: deviationDetails.plantCode,
        emailTo,
        cc,
        customEmailTo: individualEmailsList,
        customCC: ccIndividualEmailsList,
      };
      console.log('deviationData: ', devData);
      // setLoading(true);
      onUpdateDeviation(devData).then((result) => {
        console.log('result: ', result);
        if (typeof result === 'string' || !result) {
          setLoading(false);
          swal.fire({
            icon: 'error',
            title: 'Error returned from service',
            text: result ?? '',
          });
          return;
        }
        console.log('result.data.isSuccess: ', result?.data?.isSuccess);
        if (!result?.data?.isSuccess) {
          console.log('result.data.data.error', result.data.data.error);
          setLoading(false);
          swal.fire({
            icon: 'error',
            title: 'Could not update deviation(s)',
            text: result.data.data.error?.message,
          });
          return;
        }
        setLoading(false);
        setShowUpdateDialog(true);
      });
    } else {
      swal.fire({
        icon: 'error',
        title: 'Error updating',
        text: 'You should modify first the deviation',
      });
    }
  };

  const getDeviationDetails = (deviationId) => {
    console.log('Deviation ID: ' + deviationId);
    onGetDeviationDetails(deviationId).then((result) => {
      console.log('result: ', result);
      if (!result?.data?.isSuccess) {
        swal.fire({
          icon: 'warning',
          title: 'No data returned from service',
          timer: 3000,
        });
        setDeviationDetails(undefined);
        onClose();
        return;
      }
      setDeviationDetails(result.data.data);
    });
  };

  useEffect(() => {
    if (!deviationDetails) return;
    if (
      !userInfo?.plantCodes.includes(deviationDetails.plantCode) &&
      userInfo?.role !== 'ADMIN' &&
      userInfo?.role !== 'CORP'
    ) {
      swal.fire({
        icon: 'warning',
        title: 'This deviation is not assigned to your plant!',
        timer: 3000,
      });
      setDeviationDetails(undefined);
      onClose();
      return;
    }
    if (deviationDetails.npdNumber !== '' && deviationDetails.npdNumber) {
      setSpecChgReq(true);
      setNpdNumber(deviationDetails.npdNumber);
    }
    if (
      deviationDetails.status === 'REJECT' ||
      deviationDetails.status === 'EDIT'
    ) {
      console.log('deviation details ', deviationDetails);
      let plantCodes_ = deviationDetails ? [deviationDetails.plantCode] : '';
      console.log('plant code ', plantCodes_);
      if (!plantCodes_ || plantCodes_.length === 0) return;
      onGetDistributionLists(plantCodes_).then((result) => {
        if (!result?.data?.isSuccess) {
          swal.fire({
            icon: 'warning',
            title: 'No distribution lists found for plants!',
            timer: 3000,
          });
          return;
        }
        if (!result.data.data.length === 0) {
          swal.fire({
            icon: 'warning',
            title: 'No distribution lists found for plants!',
            timer: 3000,
          });
          return;
        }
        let distributionLists = result.data.data;
        setDistributionLists(distributionLists);
        let individualEmails = '';
        let individualEmailsCC = '';

        deviationDetails.customEmails?.split(',').forEach((email) => {
          let name = email.substring(0, email.indexOf('-'));
          let isCC = email.substring(email.indexOf('-') + 1, email.length);
          if (isCC === '0') {
            individualEmails += individualEmails === '' ? name : ', ' + name;
          }
          if (isCC === '1') {
            individualEmailsCC +=
              individualEmailsCC === '' ? name : ', ' + name;
          }
        });
        setIndividualEmailsList(individualEmails);
        setccIndividualEmailsList(individualEmailsCC);
      });
    } else {
      let distributionLists = deviationDetails.distributionLists
        ?.split(',')
        .map((dl) => {
          let name = dl.substring(0, dl.indexOf('-'));
          let isCC = dl.substring(dl.indexOf('-') + 1, dl.length);
          return { name, isCC, selected: false };
        });
      let individualEmails = '';
      let individualEmailsCC = '';

      deviationDetails.customEmails?.split(',').forEach((email) => {
        console.log('email____: ', email);
        let name = email.substring(0, email.indexOf('-'));
        console.log('name___: ', name);
        let isCC = email.substring(email.indexOf('-') + 1, email.length);
        console.log('isCC___: ', isCC);
        if (isCC === '0') {
          individualEmails += individualEmails === '' ? name : ', ' + name;
        }
        if (isCC === '1') {
          individualEmailsCC += individualEmailsCC === '' ? name : ', ' + name;
        }
        console.log('individualEmails___: ', individualEmails);
      });
      setIndividualEmailsList(individualEmails);
      setccIndividualEmailsList(individualEmailsCC);
      setDistributionLists(distributionLists);
    }
  }, [deviationDetails]);

  useEffect(() => {
    console.log('deviationId: ', deviationId);
    if (deviationId && open) {
      getDeviationDetails(deviationId);
    }
  }, [deviationId]);

  useEffect(() => {
    if (!distributionLists) return;
    console.log('distributionLists: ', distributionLists);
    let approvers = [];
    let cc = [];
    if (deviationDetails.status === 'EDIT') {
      if (approversSelected.length === 0) {
        distributionLists.forEach((dl) => {
          approvers.push({ ...dl, selected: true });
        });
        setApproversSelected(approvers);
      }

      if (ccSelected.length === 0) {
        distributionLists.forEach((dl) => {
          cc.push({ ...dl, selected: false });
        });
        setCCSelected(cc);
      }
    } else {
      distributionLists.forEach((dl) => {
        dl.isCC === '1'
          ? cc.push({ ...dl, selected: true })
          : approvers.push({ ...dl, selected: true });
      });
      setApproversSelected(approvers);
      setCCSelected(cc);
    }
  }, [distributionLists]);

  useEffect(() => {
    const emailSelected = atLeastOneEmailSelected(approversSelected);
    const isIndividualsValid =
      isIndividualEmailsListValid(individualEmailsList);
    //Since cc is not mandatory we just validate it if it is filled
    const isCCIndividualsValid =
      ccIndividualEmailsList.length > 0
        ? isIndividualEmailsListValid(ccIndividualEmailsList)
        : true;

    let emailsVerified = false;
    if (emailSelected !== undefined) {
      emailsVerified =
        emailSelected && isIndividualsValid && isCCIndividualsValid;
    } else {
      emailsVerified =
        individualEmailsList.length > 0 &&
        isIndividualsValid &&
        isCCIndividualsValid;
    }

    if (emailsVerified) {
      setContactInfoVerified(true);
    } else {
      setContactInfoVerified(false);
    }
  }, [
    individualEmailsList,
    ccIndividualEmailsList,
    approversSelected,
    ccIndividualEmailsList,
  ]);

  return (
    <Container xs={12} sm={12}>
      <Modal
        show={open}
        onHide={onClose}
        size="lg"
        centered
        keyboard="true" //Close the modal when escape key is pressed
        scrollable
      >
        <div>
          <button
            type="button"
            className="close"
            onClick={() => {
              onClose();
            }}
            style={{ margin: '10px' }}
            data-dismiss="modal"
          >
            ×
          </button>
        </div>
        <DeviationModalHeader deviationDetails={deviationDetails} />
        <Modal.Body>
          <Row style={{ fontFamily: 'proxima-nova' }}>
            <Col xs={12} sm={12} md={6}>
              <DevDetails
                deviationDetails={deviationDetails}
                onValidateDeviationMaterial={onValidateDeviationMaterial}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                replacementValue={replacementValue}
                setReplacementValue={setReplacementValue}
                reasonOrDescription={reasonOrDescription}
                setReasonOrDescription={setReasonOrDescription}
                approverNotes={approverNotes}
                setApproverNotes={setApproverNotes}
                onUpdateDeviation={onUpdateDeviation}
                canUpdate={canUpdate}
                setCanUpdate={setCanUpdate}
                userInfo={userInfo}
                specChgReq={specChgReq}
                handleSpecChgChange={handleSpecChgChange}
                npdNumber={npdNumber}
                handleNpdNumberChange={handleNpdNumberChange}
                setLoading={setLoading}
              />
            </Col>
            <Col xs={12} sm={12} md={6}>
              <ContactInfo
                deviationDetails={deviationDetails}
                validateEnteredEmailsList={validateEnteredEmailsList}
                individualEmailsList={individualEmailsList}
                ccIndividualEmailsList={ccIndividualEmailsList}
                setccIndividualEmailsList={setccIndividualEmailsList}
                setIndividualEmailsList={setIndividualEmailsList}
                setApproversSelected={setApproversSelected}
                approversSelected={approversSelected}
                setCCSelected={setCCSelected}
                ccSelected={ccSelected}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer
          style={{ alignItems: 'center', justifyContent: 'center' }}
        >
          <ButtonRow
            onCancel={onCancel}
            onClone={onClone}
            onUpdate={onUpdateData}
            onApprove={onApprove}
            onReject={() => setShowRejectDialog(true)}
            onSubmit={onSubmit}
            userInfo={userInfo}
            devStatus={deviationDetails ? deviationDetails.status : ''}
          />
        </Modal.Footer>
      </Modal>

      {showUpdateDialog ? (
        <UpdateDialog
          show={showUpdateDialog}
          deviationId={deviationId}
          setShowUpdateDialog={(value) => setShowUpdateDialog(value)}
          refreshData={refreshData}
          onCloseModal={onClose}
        />
      ) : undefined}
      {showCloneDialog ? (
        <CloneDialog
          show={showCloneDialog}
          deviationId={deviationId}
          setShowCloneDialog={(value) => setShowCloneDialog(value)}
          refreshData={refreshData}
          onCloseModal={onClose}
        />
      ) : undefined}
      {showSubmitDialog ? (
        <SubmitDialog
          show={showSubmitDialog}
          deviationId={deviationId}
          setShowSubmitDialog={(value) => setShowSubmitDialog(value)}
          refreshData={refreshData}
          onCloseModal={onClose}
        />
      ) : undefined}

      {showApproveDialog ? (
        <ApproveDialog
          show={showApproveDialog}
          deviationId={deviationId}
          setShowApproveDialog={(value) => setShowApproveDialog(value)}
          refreshData={refreshData}
          onCloseModal={onClose}
        />
      ) : undefined}

      {showRejectDialog ? (
        <RejectDialog
          show={showRejectDialog}
          deviationId={deviationId}
          setShowRejectDialog={(value) => setShowRejectDialog(value)}
          rejectDeviation={onReject}
          comments={rejectionComments}
          handleComments={setRejectionComments}
        />
      ) : undefined}

      {showCancelDialog ? (
        <CancelDialog
          show={showCancelDialog}
          deviationId={deviationId}
          setShowCancelDialog={(value) => setShowCancelDialog(value)}
          refreshData={refreshData}
          onCloseModal={onClose}
        />
      ) : undefined}
    </Container>
  );
};

export default DeviationModal;
