import React from "react";
import {Col, Row, Modal} from 'react-bootstrap';
import RoundButton from "../../roundButton/RoundButton";

const CancelDialog = (props) => {

    const { show, setShowCancelDialog, refreshData, deviationId, onCloseModal } = props;

    const closeModal = () => {
        setShowCancelDialog(false)
        onCloseModal()
        refreshData()
    }

    return (
        <Modal show={show} centered onHide={closeModal}>
            <Col
                style={{
                    alignItems: "center",
                    justifyContent: "center",
                    color: "65686B",
                    // padding: "0px 20px",
                    margin: "20px 0px",
                }}
            >
                <div style={{ textAlign: "center" }}>
                    <span>
                        {`You successfully`}
                        <span
                            style={{ color: "rgb(0, 122, 255)", fontWeight: "bold" }}
                        >{` canceled `}</span>
                        {`the`}
                    </span>
                    <span
                        style={{ color: "#65686B", fontWeight: "bold" }}
                    >{` Component Dev (ID: ${deviationId})`}</span>
                </div>
                <Row style={{ alignItems: "center", justifyContent: "center" }}>
                    <RoundButton
                        bgColor="#65686B"
                        text="Got it!"
                        onClick={closeModal}
                    />
                </Row>
            </Col>
        </Modal>
    );
};

export default CancelDialog;
