const today = new Date()
const deviationsByWeek = deviations => {
    console.log("deviationsByWeek")
    console.log("today: ", today)
    let firstDay = new Date(today.setDate(today.getDate() - today.getDay()));
    let lastDay = new Date(today.setDate((today.getDate() - today.getDay()) + 6));

    console.log("firstDay: ", firstDay)
    console.log("lastDay: ", lastDay)

    let filteredDeviations = deviations.filter(dev => {
        let createdAt = new Date(dev.createdAt)
        return firstDay <= createdAt && lastDay >= createdAt
    })
    console.log("filteredDeviations: ", filteredDeviations)
    return [filteredDeviations, firstDay, lastDay]
}

const deviationsByMonth = deviations => {
    console.log("deviationsByMonth")
    console.log("today: ", today)
    let firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    console.log("firstDay: ", firstDay)
    console.log("lastDay: ", lastDay)

    let filteredDeviations = deviations.filter(dev => {
        let createdAt = new Date(dev.createdAt)
        return firstDay <= createdAt && lastDay >= createdAt
    })
    console.log("filteredDeviations: ", filteredDeviations)
    return [filteredDeviations, firstDay, lastDay]
}

const deviationsByYear = deviations => {
    console.log("deviationsByYear")
    console.log("today: ", today)
    let firstDay = new Date(today.getFullYear(), 0, 1);
    let lastDay = new Date(today.getFullYear(), 12, 0);

    console.log("firstDay: ", firstDay)
    console.log("lastDay: ", lastDay)

    let filteredDeviations = deviations.filter(dev => {
        let createdAt = new Date(dev.createdAt)
        return firstDay <= createdAt && lastDay >= createdAt
    })
    console.log("filteredDeviations: ", filteredDeviations)
    return [filteredDeviations, firstDay, lastDay]
}

const deviationsByYearMonth = (deviations, year, month) => {
    console.log("deviationsByYearMonth")
    console.log("today: ", today)
    let firstDay = new Date(year, month, 1);
    let lastDay = new Date(year, month + 1, 0);

    console.log("firstDay: ", firstDay)
    console.log("lastDay: ", lastDay)

    let filteredDeviations = deviations.filter(dev => {
        let createdAt = new Date(dev.createdAt)
        return firstDay <= createdAt && lastDay >= createdAt
    })
    console.log("filteredDeviations: ", filteredDeviations)
    return [filteredDeviations, firstDay, lastDay]
}

const deviationsByQuarter = deviations => {
    console.log("deviationsByQuarter")
    console.log("today: ", today)
    let currentYear = today.getFullYear()
    let currentMonth = today.getMonth()
    let firstDay = undefined
    let lastDay = undefined
    console.log("currentMonth: ", currentMonth)
    // If current date is between October and December we are on the first year of the quarter
    // dates start on 0, 0 = January, 8 = September
    let quarterFirstYear = currentMonth > 8

    let firstDayQ1 = quarterFirstYear ? new Date(currentYear, 9, 1) : new Date(currentYear - 1, 9, 1);
    let lastDayQ1 = quarterFirstYear ? new Date(currentYear, 12, 0) : new Date(currentYear - 1, 12, 0);

    console.log("firstDayQ1: ", firstDayQ1)
    console.log("lastDayQ1: ", lastDayQ1)

    let firstDayQ2 = quarterFirstYear ? new Date(currentYear + 1, 0, 1) : new Date(currentYear, 0, 1);
    let lastDayQ2 = quarterFirstYear ? new Date(currentYear + 1, 3, 0) : new Date(currentYear, 3, 0);

    console.log("firstDayQ2: ", firstDayQ2)
    console.log("lastDayQ2: ", lastDayQ2)

    let firstDayQ3 = quarterFirstYear ? new Date(currentYear + 1, 3, 1) : new Date(currentYear, 3, 1);
    let lastDayQ3 = quarterFirstYear ? new Date(currentYear + 1, 6, 0) : new Date(currentYear, 6, 0);

    console.log("firstDayQ3: ", firstDayQ3)
    console.log("lastDayQ3: ", lastDayQ3)

    let firstDayQ4 = quarterFirstYear ? new Date(currentYear + 1, 6, 1) : new Date(currentYear, 6, 1);
    let lastDayQ4 = quarterFirstYear ? new Date(currentYear + 1, 9, 0) : new Date(currentYear, 9, 0);

    console.log("firstDayQ4: ", firstDayQ4)
    console.log("lastDayQ4: ", lastDayQ4)

    let filteredDeviations;
    switch (currentMonth) {
        case 9:
        case 10:
        case 11:
            firstDay = firstDayQ1
            lastDay = lastDayQ1
            filteredDeviations = deviations.filter(dev => {
                let createdAt = new Date(dev.createdAt)
                return firstDayQ1 <= createdAt && lastDayQ1 >= createdAt
            })
            break;
        case 0:
        case 1:
        case 2:
            firstDay = firstDayQ2
            lastDay = lastDayQ2
            filteredDeviations = deviations.filter(dev => {
                let createdAt = new Date(dev.createdAt)
                return firstDayQ2 <= createdAt && lastDayQ2 >= createdAt
            })
            break;
        case 3:
        case 4:
        case 5:
            firstDay = firstDayQ3
            lastDay = lastDayQ3
            filteredDeviations = deviations.filter(dev => {
                let createdAt = new Date(dev.createdAt)
                return firstDayQ3 <= createdAt && lastDayQ3 >= createdAt
            })
            break;
        case 6:
        case 7:
        case 8:
            firstDay = firstDayQ4
            lastDay = lastDayQ4
            filteredDeviations = deviations.filter(dev => {
                let createdAt = new Date(dev.createdAt)
                return firstDayQ4 <= createdAt && lastDayQ4 >= createdAt
            })
            break;
        default:
            firstDay = firstDayQ1
            lastDay = lastDayQ1
            filteredDeviations = deviations.filter(dev => {
                let createdAt = new Date(dev.createdAt)
                return firstDayQ1 <= createdAt && lastDayQ1 >= createdAt
            })
            break;
    }
    console.log("filteredDeviationsByQuarter: ", filteredDeviations)
    return [filteredDeviations, firstDay, lastDay]
}

const getCurrentFiscalYear = () => {
    return today.getFullYear()
}

const getCurrentMonth = () => {
    return today.getMonth()
}

const convertFiscalYearToString = fiscalYear => {
    return fiscalYear.toString().substring(2)
}

const convertMonthToString = month => {
    let monthString = ""
    switch (month) {
        case 0:
            monthString = "Jan"
            break;
        case 1:
            monthString = "Feb"
            break;
        case 2:
            monthString = "Mar"
            break;
        case 3:
            monthString = "Apr"
            break;
        case 4:
            monthString = "May"
            break;
        case 5:
            monthString = "Jun"
            break;
        case 6:
            monthString = "Jul"
            break;
        case 7:
            monthString = "Aug"
            break;
        case 8:
            monthString = "Sep"
            break;
        case 9:
            monthString = "Oct"
            break;
        case 10:
            monthString = "Nov"
            break;
        case 11:
            monthString = "Dec"
            break;
        default:
            break;
    }
    return monthString
}

const nextPrevFiscalYear = (add, currFiscalYear) => {
    let newFiscalYear = add ? currFiscalYear + 1 : currFiscalYear - 1
    return newFiscalYear
}

const nextPrevMonth = (add, currMonth) => {
    let newMonth = currMonth
    if (add) {
        newMonth = currMonth === 12 ? 1 : currMonth + 1
    } else {
        newMonth = currMonth === 1 ? 12 : currMonth - 1
    }
    return newMonth
}

const createDateFiscalYearAndMonthString = (fiscalYear, month) => {
    return convertMonthToString(month) + " FY" + convertFiscalYearToString(fiscalYear)
}

const dateCalculations = {
    deviationsByWeek,
    deviationsByMonth,
    deviationsByYear,
    deviationsByQuarter,
    deviationsByYearMonth,
    getCurrentFiscalYear,
    getCurrentMonth,
    nextPrevFiscalYear,
    nextPrevMonth,
    createDateFiscalYearAndMonthString
}

module.exports = dateCalculations