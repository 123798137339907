import { OktaAuth }from '@okta/okta-auth-js';
import config from '../config'
export let authClient;

authClient = new OktaAuth({
    tokenManager: {
        storage: 'sessionStorage',
        secure: true,
    },
    issuer: config.okta.issuer,
    clientId: config.okta.clientId,
    pkce: true,
    url: config.okta.url,
    redirectUri: config.okta.redirectUri,
});

export const scopes = {
    scopes: ['openid', 'profile', 'email', 'groups', 'SpecDevSPA'],
};

export const loginWithRedirect = () => {
    window.sessionStorage.removeItem('user');
    authClient.token.getWithRedirect(scopes);
};
