import { combineReducers } from "redux";
import { NewDeviationReducer as newDeviation } from "./newDeviationReducer"
import { DeviationGridReducer as deviationGrid } from "./deviationGridReducer"
import { UserInfoReducer as userInfo } from "./userInfoReducer"
import { GeneralDeviationReducer as general } from "./generalDeviationReducer"

const appReducers = combineReducers({
    newDeviation,
    deviationGrid,
    userInfo,
    general,
})

const rootReducer = (state, action) => {
    return appReducers(state, action)
}

export default rootReducer
