import React, { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import DeviationRequest from '../deviationRequest/DeviationRequest';
import HistoryDeviationsTable from '../historyDeviationsTable/HistoryDeviationsTable';
import CreateNewRequest from '../createNewRequest/CreateNewRequest'
import DeviationsTable from '../deviationsTable/DeviationsTable'
import FavoriteToggle from '../favoriteToggle/favoriteToggle';
import HistoryReportAccordion from './HistoryReportAccordion'
import styles from './accordion.module.css'
import { Container, Row, Col } from 'react-bootstrap';
import { styled } from '@mui/material/styles';





const CustomAccordion = styled(Accordion)(({ theme }) => ({
  border: '1px solid rgba(0, 0, 0, .125)',
  boxShadow: '0',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: 'auto',
  },
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: '#002554',
  borderBottom: '1px solid rgba(0, 0, 0, .125)',
  color: '#FFF',
  fontWeight: 'bold',
  marginBottom: -1,
  minHeight: 56,
  '&.Mui-expanded': {
    minHeight: 56,
  },
}));





// const useStyles = (theme) => ({
//   root: {
//     width: '100%',
//   },
//   heading: {
//     fontFamily: 'proxima-nova',
//     fontSize: '16px',
//     fontWeight: 'bold',
//   },
//   secondaryHeading: {
//     fontSize: theme.typography.pxToRem(15),
//     color: theme.palette.text.secondary,
//   },
//   icon: {
//     verticalAlign: 'bottom',
//     height: 20,
//     width: 20,
//   },
//   details: {
//     alignItems: 'center',
//   },
//   helper: {
//     borderLeft: `2px solid ${theme.palette.divider}`,
//     padding: theme.spacing(1, 2),
//   },
//   link: {
//     color: theme.palette.primary.main,
//     textDecoration: 'none',
//     '&:hover': {
//       textDecoration: 'underline',
//     },
//   },
// });











const CustomizedAccordions = (props) => {



  const { deviationsPending,
    myDeviationsHistory,
    deviationsHistory,
    headerMaterialPlants,
    onGetAllPlantsForHeaderMaterial,
    onGetOfficialProduct,
    onGetDeviationDetails,
    onValidateZcwpMaterial,
    onValidateDeviationMaterial,
    onGetDistributionLists,
    officialProductDetails,
    zwcpDetails,
    userInfo,
    onSaveDeviation,
    lastSavedDeviations,
    onStatusChange,
    onSetOfficialProduct,
    onSetZcwpDetails,
    onSetLastSavedDeviations,
    onUpdateDeviation,
    onMarkUnmarkFavoritePlants,
    refreshData,
    getAllDeviations,
    paginationParametersPending,
    paginationParametersHistory,
    onSetPaginationParametersPending,
    onSetPaginationParametersHistory,
    setView,
    toggleOnlyFavorites,
    showOnlyFavorites,
    additionalComponents,
    onGetAdditionalComponents,
    onSetAdditionalComponents,
    filteredDeviationsByStatus,
    filteredMyDeviationsByStatus,
    tabIndex,
    onTabChange,
    loading,
    setLoading,
    arrowClick,
    currentMonthFiscalYear
  } = props
  /**
   * We will land into the this page on clicking desktop icon
   * This is going to be our home page and by default we will land into
   * Pending items screen with an expanded status.
   */
  let isAdmin = userInfo?.role === "CORP" || userInfo?.role === "ADMIN"

  const [expanded, setExpanded] = useState("");
  const [displayDevTable, showTable] = useState(true);
  const [deviationType, setDeviationType] = useState("");
  const [describeType, setDescribeType] = useState("")
  const [materialNumber, setMaterialNumber] = useState("")
  const [showCreateDevPage, setShowCreateDevPage] = useState(false)
  const [plants, setPlants] = useState([])
  const [plantCodes, setPlantCodes] = useState([])
  const [deviationDetails, setDeviationDetails] = useState('')
  const [IWantTo, setIWantTo] = useState('brandNew');
  const [currentStep, setCurrentStep] = useState("");
  const [clearCreateRequest, setClearCreateRequest] = useState(false)
  const [validMaterial, setValidMaterial] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [plantType, setPlantType] = useState('');
  const [isMyActionsChecked, setIsMyActionsChecked] = useState(false)
  const [isApproverChecked, setIsApproverChecked] = useState(true)
  const [draftClicked, setDraftClicked] = useState(false)
  const [draftDeviations, setDraftDeviations] = useState([])
  const [draftDeviationsByStatus, setDraftDeviationsByStatus] = useState([])
  const [approverHistory, setApproverHistory] = useState([])
  const [approverHistoryByStatus, setApproverHistoryByStatus] = useState([])
  const [requesterHistory, setRequesterHistory] = useState([])
  const [requesterHistoryByStatus, setRequesterHistoryByStatus] = useState([])
  let plantFilter = ""
  let headerFilter = ""




  const onChangeIsApprover = e => {
    if (!isAdmin) return
    setIsApproverChecked(!e.target.checked)
  }

  const changeFilter = (columnId, value) => {
    columnId === 2 ? plantFilter = value : headerFilter = value;
  }
  const getFilter = (columnId) => {
    return columnId === 2 ? plantFilter : headerFilter;
  }

  const onChangeMyActions = e => {
    setIsMyActionsChecked(!e.target.checked)
  }

  const onDraftClick = () => {
    setDraftClicked(!draftClicked)
  }

  const showTableAndCreateDevPage = () => {
    showTable(true);
    setShowCreateDevPage(false);
  }

  useEffect(() => {
    setShowCreateDevPage(false)
    setPlants([])
  }, [])

  useEffect(() => {
    setShowCreateDevPage(false)
  }, [describeType, deviationType])

  useEffect(() => {
    if (IWantTo !== "cloneExisting") {
      setShowCreateDevPage(false)
    }
  }, [IWantTo])

  useEffect(() => {
    if (!userInfo) return
    if (isAdmin) {
      setExpanded("pendingItems")
      setCurrentStep("pendingItems")
    }
    if (!isAdmin) setIsApproverChecked(false)
    if (isAdmin) setIsApproverChecked(true)
  }, [userInfo])

  useEffect(() => {
    console.log("currentStep:___", currentStep)
  }, [currentStep])

  useEffect(() => {
    console.log("isApproverChecked: ", isApproverChecked)
    console.log("isMyActionsChecked: ", isMyActionsChecked)
    console.log("draftClicked: ", draftClicked)
    // console.log("approverHistoryByStatus: ", approverHistoryByStatus)
    // console.log("filteredDeviationsByStatus: ", filteredDeviationsByStatus)
    // console.log("draftDeviationsByStatus: ", draftDeviationsByStatus)
    // console.log("requesterHistoryByStatus: ", requesterHistoryByStatus)
    { isApproverChecked ? isMyActionsChecked ? console.log("approverHistoryByStatus: ", approverHistoryByStatus) : console.log("filteredDeviationsByStatus: ", filteredDeviationsByStatus) : draftClicked ? console.log("draftDeviationsByStatus: ", draftDeviationsByStatus) : console.log("requesterHistoryByStatus: ", requesterHistoryByStatus) }
    if (deviationsHistory) {
      // Requester
      if (!isAdmin) {
        setDraftDeviations(deviationsHistory.filter(dev => dev.status === "EDIT" || dev.status === "CANCEL"))
        setRequesterHistory(deviationsHistory.filter(dev => (dev.status !== "CANCEL" && dev.status !== "EDIT")))
      }
      if (isAdmin) {
        setDraftDeviations(myDeviationsHistory.filter(dev => dev.status === "EDIT" || dev.status === "CANCEL"))
        setRequesterHistory(myDeviationsHistory.filter(dev => (dev.status !== "CANCEL" && dev.status !== "EDIT")))
      }
      //Approver
      console.log("deviationsHistoryyyy: ", deviationsHistory)
      setApproverHistory(deviationsHistory.filter(dev => (dev.status !== "CANCEL" && dev.status !== "EDIT") && dev.editUser?.toLowerCase() === userInfo?.name?.toLowerCase()))
    }
    if (filteredDeviationsByStatus) {
      // Requester
      if (!isAdmin) {
        setDraftDeviationsByStatus(filteredDeviationsByStatus.filter(status => status.name === "Edit" || status.name === "Cancel"))
        setRequesterHistoryByStatus(filteredDeviationsByStatus.filter(status => status.name !== "Edit" && status.name !== "Cancel"))
      }
      if (isAdmin) {
        setDraftDeviationsByStatus(filteredMyDeviationsByStatus.filter(status => status.name === "Edit" || status.name === "Cancel"))
        setRequesterHistoryByStatus(filteredMyDeviationsByStatus.filter(status => status.name !== "Edit" && status.name !== "Cancel"))
      }

      // Approver
      console.log("filteredDeviationsByStatusssss: ", filteredDeviationsByStatus)
      let approverHistoryStatus = filteredDeviationsByStatus.filter(status => status.name !== "Cancel" && status.name !== "Edit")

      approverHistoryStatus = approverHistoryStatus.map((status, index) => {
        let stat = { ...status }
        console.log("status: ", status)
        stat.data = stat.data.filter(dev => dev.editUser?.toLowerCase() === userInfo?.name?.toLowerCase())
        console.log("stat.data.length: ", stat.data.length)
        stat[stat.name] = stat.data.length
        if (stat.data.length === 0) return { data: [] }
        return stat
      })
      approverHistoryStatus.forEach((el, index) => { if (el.data.length === 0) approverHistoryStatus.splice(index, 1) })
      setApproverHistoryByStatus(approverHistoryStatus)
    }
  }, [deviationsHistory, filteredDeviationsByStatus])

  useEffect(() => {
    console.log("useEffect filteredDeviationsByStatus: ", filteredDeviationsByStatus)
  }, [filteredDeviationsByStatus])

  const handleChange = (panel) => (event, newExpanded) => {
    showTable(false)
    setExpanded(newExpanded ? panel : false);
    switch (panel) {
      case 'pendingItems':
        showTableAndCreateDevPage();
        setCurrentStep('pendingItems');
        setView('pending')
        setClearCreateRequest(true);
        break;
      case 'historyReport':
        showTableAndCreateDevPage();
        setCurrentStep('historyReport');
        setView('history')
        setClearCreateRequest(true);
        break;
      case 'newRequest':
        //we can enable below code if we want to clear(start over functionality) create request
        //even when clicking on new request button
        setClearCreateRequest(true);
        break;
      default:
        break;
    }
  };

  const displayDeviationTable = (event) => {

  };

  const handleStartOver = () => {
    setDeviationType('')
    setIWantTo('brandNew')
    setMaterialNumber('')
    setDescribeType('')
    setPlantType('')
    setValidMaterial(false)
    setSubmitDisabled(true)
  }

  const handlePlantSelection = (multiple, value, checked, clearAll) => {
    setShowCreateDevPage(false)
    if (clearAll) {
      setPlants([]);
      setPlantCodes([])
      return
    }
    const newPlants = [...plants];
    const newPlantCodes = [...plantCodes]
    let plantCode = ""
    let spaceIndex = 0
    spaceIndex = value.indexOf(" ")
    plantCode = value.substring(0, spaceIndex)
    if (multiple) {
      if (checked) {
        newPlants.push(value);
        newPlantCodes.push(plantCode)
        console.log('newPlants: ', newPlants);
      } else {
        const index = newPlants.indexOf(value);
        const index2 = newPlantCodes.indexOf(plantCode)
        newPlants.splice(index, 1);
        newPlantCodes.splice(index2, 1)
      }
      setPlants(newPlants);
      setPlantCodes(newPlantCodes)
    } else {
      if (checked) {
        setPlants([value]);
        setPlantCodes([plantCode])
      } else {
        setPlants([]);
        setPlantCodes([])
      }
    }
  };



  return (
    <Container fluid>
      <Row style={{ padding: '0px', marginLeft: '0px' }}  >
        <Col md="4"  >
          {isAdmin ?
            <CustomAccordion
              square
              expanded={expanded === 'pendingItems'}
              onChange={handleChange('pendingItems')}

            >
              <CustomAccordionSummary
                aria-controls="pendingItemsd-content"
                id="pendingItemsd-header"
                className={styles.header}
              >

                <Typography
                  className={styles.heading}
                >
                  PENDING DEVIATIONS
                </Typography>


                <Col md="4"></Col>
              </CustomAccordionSummary>
              <AccordionDetails
                sx={{ display: 'flex', flexDirection: 'row-reverse' }}
              >
                <Col md="4.5"
                >
                  <FavoriteToggle
                    checked={!showOnlyFavorites} onChange={toggleOnlyFavorites} />
                </Col>
              </AccordionDetails>
              <Divider className={styles.foot} />
              <AccordionActions
                className={styles.foot}
              >
                <Button
                  size="small"
                  onClick={displayDeviationTable}
                >
                  Show me
                </Button>
              </AccordionActions>
            </CustomAccordion> : undefined}
          <CustomAccordion
            square
            expanded={expanded === 'newRequest'}
            onChange={handleChange('newRequest')}
          >
            <CustomAccordionSummary
              aria-controls="newRequestd-content"
              id="newRequestd-header"
              className={styles.header}
            >
              <Typography>
                CREATE A NEW REQUEST
              </Typography>
            </CustomAccordionSummary>
            <AccordionDetails>
              <CreateNewRequest
                deviationType={deviationType}
                setDeviationType={setDeviationType}
                materialNumber={materialNumber}
                onGetDeviationDetails={onGetDeviationDetails}
                setMaterialNumber={setMaterialNumber}
                setDescribeType={setDescribeType}
                describeType={describeType}
                setShowCreateDevPage={setShowCreateDevPage}
                headerMaterialPlants={headerMaterialPlants}
                onGetAllPlantsForHeaderMaterial={
                  onGetAllPlantsForHeaderMaterial
                }
                onGetOfficialProduct={onGetOfficialProduct}
                onValidateZcwpMaterial={onValidateZcwpMaterial}
                handlePlantSelection={handlePlantSelection}
                selectedPlants={plants}
                deviationsPending={deviationsPending}
                deviationsHistory={deviationsHistory}
                setDeviationDetails={setDeviationDetails}
                IWantTo={IWantTo}
                setIWantTo={setIWantTo}
                clearCreateRequest={clearCreateRequest}
                validMaterial={validMaterial}
                setValidMaterial={setValidMaterial}
                submitDisabled={submitDisabled}
                setSubmitDisabled={setSubmitDisabled}
                plantType={plantType}
                setPlantType={setPlantType}
                handleStartOver={handleStartOver}
                onStatusChange={onStatusChange}
                onSetOfficialProduct={onSetOfficialProduct}
                onSetZcwpDetails={onSetZcwpDetails}
                onSetLastSavedDeviations={onSetLastSavedDeviations}
                userInfo={userInfo}
                loading={loading}
                setLoading={setLoading}
              ></CreateNewRequest>
            </AccordionDetails>
          </CustomAccordion>
          <CustomAccordion
            square
            expanded={expanded === 'historyReport'}
            onChange={handleChange('historyReport')}
          >
            <CustomAccordionSummary
              aria-controls="historyReportd-content"
              id="historyReportd-header"
              className={styles.header}
            >

              <Typography>
                HISTORY REPORT
              </Typography>

            </CustomAccordionSummary>
            <AccordionDetails>
              <HistoryReportAccordion
                deviations={isApproverChecked ? isMyActionsChecked ? approverHistoryByStatus : filteredDeviationsByStatus : draftClicked ? draftDeviationsByStatus : requesterHistoryByStatus}
                draftClicked={draftClicked}
                tabIndex={tabIndex}
                onTabChange={onTabChange}
                isMyActionsChecked={isMyActionsChecked}
                isApproverChecked={isApproverChecked}
                onChangeIsApprover={onChangeIsApprover}
                onChangeMyActions={onChangeMyActions}
                onDraftClick={onDraftClick}
                arrowClick={arrowClick}
                currentMonthFiscalYear={currentMonthFiscalYear}
              />
            </AccordionDetails>
          </CustomAccordion>
        </Col>

        {displayDevTable ? (

          <Col md="8">
            <Row>
              <Col md={2}>
                {currentStep === 'pendingItems' || currentStep === 'historyReport' ?
                  <Button
                    disableElevation
                    className={styles.refreshButton}
                    onClick={refreshData}
                  >
                    {' '}
                    Refresh{' '}
                  </Button> : undefined}
              </Col>
              <Col md={2}>
                {currentStep === 'pendingItems' || currentStep === 'historyReport' ? <Button
                  variant="contained"
                  fontSize="large"
                  disableElevation
                  size="large"
                  className={styles.refreshButton}
                  onClick={getAllDeviations}
                >
                  {' '}
                  Show all{' '}
                </Button> : undefined}
              </Col>

            </Row>
            <Row>
              {currentStep === 'pendingItems' ? (
                <DeviationsTable
                  currentStep={currentStep}
                  deviationsPending={deviationsPending}
                  onGetDeviationDetails={onGetDeviationDetails}
                  onGetDistributionLists={onGetDistributionLists}
                  onValidateDeviationMaterial={onValidateDeviationMaterial}
                  onUpdateDeviation={onUpdateDeviation}
                  userInfo={userInfo}
                  refreshData={refreshData}
                  onMarkUnmarkFavoritePlants={onMarkUnmarkFavoritePlants}
                  onSaveDeviation={onSaveDeviation}
                  onStatusChange={onStatusChange}
                  paginationParametersPending={paginationParametersPending}
                  onSetPaginationParametersPending={onSetPaginationParametersPending}
                  setLoading={setLoading}
                  loading={loading}
                />
              ) : null}
              {currentStep === 'historyReport' ? (
                <HistoryDeviationsTable
                  deviationsHistory={isApproverChecked ? isMyActionsChecked ? approverHistory : deviationsHistory : draftClicked ? draftDeviations : requesterHistory}
                  onGetDeviationDetails={onGetDeviationDetails}
                  onGetDistributionLists={onGetDistributionLists}
                  onValidateDeviationMaterial={onValidateDeviationMaterial}
                  onUpdateDeviation={onUpdateDeviation}
                  userInfo={userInfo}
                  refreshData={refreshData}
                  onSaveDeviation={onSaveDeviation}
                  onStatusChange={onStatusChange}
                  paginationParametersHistory={paginationParametersHistory}
                  onSetPaginationParametersHistory={onSetPaginationParametersHistory}
                  isMobile={false}
                  setLoading={setLoading}
                  getFilter={getFilter}
                  changeFilter={changeFilter}
                />
              ) : null}
            </Row>
          </Col>
        ) : null}
        {showCreateDevPage ? (
          <Col md="8">
            <DeviationRequest
              deviationType={deviationType}
              describeType={describeType}
              plants={plants}
              deviationDetails={deviationDetails}
              plantCodes={plantCodes}
              materialNumber={materialNumber}
              onValidateDeviationMaterial={onValidateDeviationMaterial}
              onGetDistributionLists={onGetDistributionLists}
              officialProductDetails={officialProductDetails}
              zwcpDetails={zwcpDetails}
              userInfo={userInfo}
              onSaveDeviation={onSaveDeviation}
              lastSavedDeviations={lastSavedDeviations}
              handleStartOver={handleStartOver}
              onStatusChange={onStatusChange}
              onUpdateDeviation={onUpdateDeviation}
              additionalComponents={additionalComponents}
              onGetAdditionalComponents={onGetAdditionalComponents}
              onSetAdditionalComponents={onSetAdditionalComponents}
              loading={loading}
              setLoading={setLoading}
            />
          </Col>
        ) : null}
      </Row>
    </Container >
  );
}

export default CustomizedAccordions
