import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { Container, Row } from 'react-bootstrap';
import './App.css';
import Home from "./screens/home/Home";
import { initGA } from "./components/analyticsTracking/AnalyticsTracking";

initGA();

function App() {
  return (
    <div className="App">
      <Router>
        <Container fluid>
          <Row>
            <Switch>
              <Route path="/:deviationId" component={Home} />
              <Route path="/" component={Home} />
            </Switch>
          </Row>
        </Container>
      </Router>
    </div>
  );
}

export default App;
