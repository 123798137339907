// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  font-size: 16px;
  text-align: right;
  color: #002554;
  font-weight: bold;
  text-transform: uppercase;
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/deviationRequest/DeviationRequest.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,iBAAiB;EACjB,yBAAyB;EACzB,UAAU;AACZ","sourcesContent":[".title {\n  font-size: 16px;\n  text-align: right;\n  color: #002554;\n  font-weight: bold;\n  text-transform: uppercase;\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
