// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-header{
    justify-content: center;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/deviationModal/DeviationModal.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,kBAAkB;AACtB","sourcesContent":[".modal-header{\n    justify-content: center;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
