
import { Col, Row } from "react-bootstrap";
import React from "react";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';

const DateInputField = (props) => {
  const { title, dateValue, isDisabled, onChangeValue, required, maxDate, minDate } = props;

  return (
    <Row noGutters className="mb-2">
      <Col xs={6} className="right">
        {required ? <span style={{ color: 'red' }}>*</span> : undefined}
        <span style={{ paddingRight: "5px" }}>{title}</span>
      </Col>
      <Col xs={6} className="left">
        <Col>
          <Row>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disablePast
                disableToolbar
                className="inputStyle"
                inputFormat="MM/DD/YYYY"
                value={dateValue ? dayjs(dateValue) : null}
                onChange={onChangeValue}
                disabled={isDisabled}
                maxDate={maxDate ? dayjs(maxDate) : null}
                renderInput={(params) => <TextField {...params} variant="outlined" InputAdornmentProps={{ position: 'end' }} />}
              />
            </LocalizationProvider>
          </Row>
        </Col>
      </Col>
    </Row>
  );
};

export default DateInputField;
