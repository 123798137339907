// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HistoryReportToggles_toggleLabels__xh3KG {
    padding-top: 8px;
    font-size: 14px;
    color: #65686B;
}

.HistoryReportToggles_grayedToggleLabel__k1avQ {
    padding-top: 8px;
    font-size: 14px;
    color: #EAEAEA;
}

.HistoryReportToggles_drafts__ADJU0 {
    padding-top: 8px;
    padding-left: 75%;
    text-decoration: underline;
    cursor: pointer;
}

.HistoryReportToggles_goBack__WPShS {
    padding-top: 8px;
    padding-left: 65%;
    text-decoration: underline;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/accordionPC/historyReportToggles/HistoryReportToggles.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,0BAA0B;IAC1B,eAAe;AACnB","sourcesContent":[".toggleLabels {\n    padding-top: 8px;\n    font-size: 14px;\n    color: #65686B;\n}\n\n.grayedToggleLabel {\n    padding-top: 8px;\n    font-size: 14px;\n    color: #EAEAEA;\n}\n\n.drafts {\n    padding-top: 8px;\n    padding-left: 75%;\n    text-decoration: underline;\n    cursor: pointer;\n}\n\n.goBack {\n    padding-top: 8px;\n    padding-left: 65%;\n    text-decoration: underline;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggleLabels": `HistoryReportToggles_toggleLabels__xh3KG`,
	"grayedToggleLabel": `HistoryReportToggles_grayedToggleLabel__k1avQ`,
	"drafts": `HistoryReportToggles_drafts__ADJU0`,
	"goBack": `HistoryReportToggles_goBack__WPShS`
};
export default ___CSS_LOADER_EXPORT___;
