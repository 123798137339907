import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./store/reducers";
import App from './App'
import {
  authClient,
  loginWithRedirect,
  scopes,
} from './services/okta-auth';
import 'bootstrap/dist/css/bootstrap.css';

const store = rootReducer && createStore(rootReducer, applyMiddleware(thunk));
const enableAuth = true

if (enableAuth) {
  authClient.session.exists().then(authStatus => {
    console.log('authStatus');
    console.log(authStatus);
    console.log("authClient: ", authClient)
    // authClient.restore
    // if (deviationId !== NaN) authClient.options.state = deviationId
    // Session will exist from another application session or on new session from redirect below in else statement
    if (authStatus) {
      // Remove query string params from url
      window.history.replaceState(null, null, authClient.getOriginalUri());
      // If session exists, get tokens from session so it's usuable within app
      // Session could exist from another application
      const session = authClient.session.get() // Get current session
      authClient.token
        .getWithoutPrompt({
          scopes: scopes.scopes,
          sessionToken: session
        })
        .then(response => {
          // Replace any potentially lingering tokens and then add tokens from session
          console.log("response: ", response)
          authClient.removeOriginalUri()
          authClient.tokenManager.clear();
          authClient.tokenManager.add("accessToken", response.tokens.accessToken);
          authClient.tokenManager.add("idToken", response.tokens.idToken);
          authClient.token
            .getUserInfo(
              JSON.parse(window.sessionStorage.getItem("okta-token-storage"))
                .accessToken
            )
            .then(user => {
              window.sessionStorage.setItem("user", JSON.stringify(user));
            }).then(() => {
              ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById("root"));
            })
            .catch(err => {
              console.log(err);
            });;
        })

    } else {
      console.log('REDIRECTING...')
      authClient.setOriginalUri(window.location.href)
      // initiate session by redirecting to IDP (Identity Provider)
      loginWithRedirect();
    }
  })
    .catch(err => {
      console.log("ERROR:")
      console.log(err);
    });
} else {
  ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById("root"));
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
