// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dev-title{
    color: #65686B;
    font-weight: bold;
    font-size: 16px;
    align-items: flex-start;
}`, "",{"version":3,"sources":["webpack://./src/components/createNewRequest/CreateNewRequest.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,iBAAiB;IACjB,eAAe;IACf,uBAAuB;AAC3B","sourcesContent":[".dev-title{\n    color: #65686B;\n    font-weight: bold;\n    font-size: 16px;\n    align-items: flex-start;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
