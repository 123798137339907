import React from 'react';
import './BoldText.css';

const BoldText = ({text="Title"}) => {
    return (
        <div className="bold-text">
            {text}
        </div>
    );
};

export default BoldText;